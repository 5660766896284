import { memo } from 'react';
import { Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Remark } from 'react-remark';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
import { Image } from './Image.js';
import PreviewLink from './PreviewLink.js';

const Markdown = styled('div', {})(
    ({theme, inline}) => ({
        display: 'inline',
        '& > *:first-child  /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */': {
            marginTop: 0
        },
        ...(inline && {
            '& > *:first-child  /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */': {
                display: 'inline',
                marginTop: 0
            }
        }),
        '& p:last-of-type': {
            marginBlockEnd: inline ? 0 : 1
        }
    })
);

const MarkdownViewer = memo(({text, inline, tokens, variant}) => {
    let components = {
        img: (props) => <Box component="span" sx={{display: 'flex', width: '100%', justifyContent: 'center', mt: 1, mb: 1}}><Image sx={{maxWidth: '95%', objectFit: 'cover'}} {...props} /></Box>,
        p: (props) => <p><Typography variant={variant ?? "body1"} component="span" {...props} /></p>,
        a: (props) => <PreviewLink {...props} />,
        li: (props) => <li><Typography variant={variant ?? 'body1'} component="span" {...props} /></li>,
        h1: (props) => <Typography variant="h2" {...props} />,
        h2: (props) => <Typography variant="h3" {...props} />,
        h3: (props) => <Typography variant="h4" {...props} />,
        h4: (props) => <Typography variant="h5" {...props} />,
        h5: (props) => <Typography variant="h6" {...props} />,
        h6: (props) => <Typography variant="h7" {...props} />,
        table: (props) => <TableContainer><Table size="size" {...props} /></TableContainer>,
        thead: (props) => <TableHead {...props} />,
        tbody: (props) => <TableBody {...props} />,
        tr: (props) => <TableRow {...props} />,
        td: (props) => <TableCell {...props} />,
        th: (props) => <TableCell {...props} />
    };

    let finalText = text;
    if (tokens) {
        for (let [token, replacement] of Object.entries(tokens)) {
            finalText = finalText.replaceAll(token, replacement);
        }
    }
    
    return (
        <Markdown inline={inline}>
            <Remark remarkPlugins={[remarkParse, remarkGfm]} rehypeReactOptions={{components: components}}>
                {finalText}
            </Remark>
        </Markdown>
    );
});

export { MarkdownViewer };
