import { createContext, useContext, useCallback, useMemo } from 'react';
import { useStateBlock } from '../../utils.js';

const SetDocumentContext = createContext();
const GetDocumentContext = createContext();

export function useSetDocumentContext() {
    return useContext(SetDocumentContext);
}

export function useDocumentContext() {
    return useContext(GetDocumentContext);
}

export default function DocumentContext(props) {
    const [data, update] = useStateBlock({});

    const setCategory = useCallback((category) => update({category: category}), [update]);
    const setRepository = useCallback((repository) => update({repository: repository}), [update]);
    const setters = useMemo(() => ({ 
        setCategory: setCategory, 
        setRepository: setRepository
    }), [setCategory, setRepository]);

    return (
        <GetDocumentContext.Provider
            value={data}
        >
            <SetDocumentContext.Provider 
                value={setters}
            >
                {props.children}
            </SetDocumentContext.Provider>
        </GetDocumentContext.Provider>
    )
}
