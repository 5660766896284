import { createContext, useCallback, useState, useContext, useEffect } from 'react';
import SimpleImageViewer from 'react-simple-image-viewer';

const ImageViewerContext= createContext();

function useImageViewer() {
    return useContext(ImageViewerContext);
}

function ImageViewer(props) {
    const [prevLocation, setPrevLocation] = useState();
    const [image, setImage] = useState();
    
    const open = useCallback((image) => {
        setImage(image);
        setPrevLocation(window.location.href);
    }, []);

    const close = useCallback(() => {
        setImage();
    }, []);

    // fix a bug where after navigating back or forward the image would persist
    useEffect(() => {
        if (image && (window.location.href !== prevLocation)) {
            setImage();
        }
    });

    return (
        <ImageViewerContext.Provider value={{
            open: open
        }}>
            { image && 
                <SimpleImageViewer 
                    src={Array.isArray(image) ? image : [image]} 
                    currentIndex={0} 
                    onClose={close} 
                    closeOnClickOutside={true}
                    backgroundStyle={{zIndex: 2000}}
                />
            }
            {props.children}
        </ImageViewerContext.Provider>
    );   
};

export { ImageViewer, useImageViewer };
