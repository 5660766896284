import { Stack, TextField, Box, Paper, FormControlLabel, Switch } from '@mui/material';
import ComboBox from '../common/ComboBox.js';
import ImagePicker from '../common/ImagePicker';
import SelectBox from '../common/SelectBox.js';
import MarkdownField from '../common/MarkdownField.js';
import { itemRarityList, getItemRarityLabel } from '../../dnd5e.js';
import { itemTypeSuggestions, itemSubtypeSuggestions } from '../../suggestions.js';
import DiceFormulaField from '../common/DiceFormulaField.js';

function ItemEditor({data, update}) {
    const subtypeOptions = itemSubtypeSuggestions[data.type] ?? [];

    return (
        <Stack spacing={2} sx={{display: 'flex', flexGrow: 1}}>
            <TextField label="Name" autoComplete="off" fullWidth required
                value={data.name ?? ''} 
                onChange={(e) => update({name: e.target.value})} 
                sx={{marginTop: 1}}
            />
            <ImagePicker size="small" data={data} update={update} />
            <Stack direction="row" spacing={1}>
                <ComboBox label="Type" fullWidth 
                    options={itemTypeSuggestions}
                    value={data.type}
                    onChange={(e, v) => update({type: v})}
                />
                <ComboBox label="Subtype" fullWidth 
                    options={subtypeOptions}
                    value={data.subtype}
                    onChange={(e, v) => update({subtype: v})}
                />
                <FormControlLabel label="Requires Attunement"
                    sx={{ whiteSpace: 'nowrap' }}
                    control={
                        <Switch checked={data.attunement} onChange={(e) => update({attunement: e.target.checked})} />
                    }
                />
            </Stack>
            { data.attunement && 
                <TextField label="Attunement Rules" fullWidth autoComplete="off"
                    value={data.attunementRules ?? ''}
                    onChange={(e) => update({attunementRules: e.target.value})}
                />
            }
            <Stack direction="row" spacing={1}>
                <DiceFormulaField label="Charges" fullWidth noMetrics
                    value={data.charges}
                    onChange={(e, v) => update({charges: v})}
                />
                <TextField label="Recharge" fullWidth
                    value={data.recharge ?? ''}
                    onChange={(e) => update({recharge: e.target.value})}
                />
            </Stack>
            <Stack direction="row" spacing={1}>
                <SelectBox label="Rarity" fullWidth
                    options={itemRarityList}
                    getOptionLabel={getItemRarityLabel}
                    value={data.rarity}
                    onChange={(e, v) => update({rarity: v})}
                />
                <TextField label="Cost" fullWidth autoComplete="off"
                    value={data.cost ?? ''}
                    onChange={(e) => update({cost: e.target.value})}
                />
            </Stack>
            <MarkdownField label="Description" rows={12} 
                value={data.description}
                onChange={(v) => update({description: v})}
            />
        </Stack>
    );
}

export default ItemEditor;
