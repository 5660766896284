import { createContext, useContext, useReducer, useCallback, useEffect } from 'react';
import { useAuth } from '../../globals.js';
import { getUser } from '../../api.js';

const UserCacheContext = createContext();

function useUserCache() {
    return useContext(UserCacheContext);
}

function repositoryCacheReducer(cache, action) {
    return { ...cache, [action.id]: action.data };
}

function UserCache(props) {
    const [cache, updateCache] = useReducer(repositoryCacheReducer, {});
    const { userId: localUserId } = useAuth();

    const fetchUserData = useCallback(async (id) => {
        if (id) {
            const res = await getUser(id);
            if (res.result === 'success') {
                const { result, ...userData } = res;
                updateCache({id: id, data: {id: id, ...userData}});
            }
        }
    }, []);

    const getUserData = useCallback((repoId) => {
        if (cache[repoId]) {
            return cache[repoId];
        }
        fetchUserData(repoId);
        return null;
    }, [cache, fetchUserData]);

    useEffect(() => {
        if (localUserId && !cache[localUserId]) {
            fetchUserData(localUserId);
        }
    }, [localUserId, cache, fetchUserData]);

    return (
        <UserCacheContext.Provider 
            value={{
                localUser: localUserId && cache[localUserId],
                getUser: getUserData,
                refreshUser: fetchUserData
            }}
        >
            {props.children}
        </UserCacheContext.Provider>
    );
}

export { UserCache, useUserCache };
