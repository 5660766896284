import { forwardRef, useState, useCallback } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { CheckBox as CheckedBoxIcon, CheckBoxOutlineBlank as UncheckedBoxIcon } from '@mui/icons-material';

const checkedIcon = <CheckedBoxIcon fontSize="small" />;
const uncheckedIcon = <UncheckedBoxIcon fontSize="small" />;

function getOptionLabelDefault(o) {
    if (typeof o === 'string') {
        return o;
    }
    else if (o?.label) {
        return o.label;
    }
    else {
        return '[invalid]';
    }
}

const MultiSelectBox = forwardRef(({label, options, value, onChange, fetchOptions, noOptionsText, loadingText, getOptionLabel, InputProps, renderOption, ...props}, ref) => {
    const [asyncOptions, setAsyncOptions] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const handleOpen = useCallback(async () => {
        setIsOpen(true);
        if (fetchOptions && !asyncOptions && !isLoading) {
            setIsLoading(true);
            
            try {
                const res = await fetchOptions();
                if ((res?.length ?? 0) === 0) {
                    setError(noOptionsText ?? 'No results found.');
                }
                else {
                    setAsyncOptions(res);
                }
            }
            catch (error) {
                setError(error?.message ?? 'Failed to fetch options');
            }

            setIsLoading(false);
        }
    }, [isLoading, asyncOptions, fetchOptions, noOptionsText]);

    const handleClose = useCallback(async () => {
        setIsOpen(false);
    }, []);

    const handleChange = useCallback((e, v) => {
        onChange?.(e, v);
    }, [onChange]);

    const getOptionLabelImpl = getOptionLabel ?? getOptionLabelDefault;

    if (!renderOption) {
        renderOption = (props, option, { selected }) => (
            <li {...props} key={option} style={{margin: 0, padding: 0}}>
                <Checkbox icon={uncheckedIcon} checkedIcon={checkedIcon} checked={selected} />
                {getOptionLabelImpl(option)}
            </li>
        );
    }

    return (
        <Autocomplete
            multiple
            ref={ref}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value ?? []}
            onChange={handleChange}
            loading={isLoading || Boolean(error)}
            loadingText={error ?? loadingText}
            options={asyncOptions ?? (options ?? [])} 
            getOptionLabel={getOptionLabelImpl}
            disableCloseOnSelect
            {...props}
            renderOption={renderOption}
            renderInput = {(params)=> (
                <TextField {...params} label={label} InputProps={{...params.InputProps, ...(InputProps ?? [])}} />
            )}
        />
    );
});

export default MultiSelectBox;
export { MultiSelectBox };
