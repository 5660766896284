import { useEffect, useRef, useState, useReducer, useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, Box, Stack, Typography, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Chip, IconButton, Paper } from '@mui/material';
import { Link as LinkIcon, ControlCamera as RecenterIcon, Article as PropertiesIcon } from '@mui/icons-material';
import { MarkdownViewer } from '../common/MarkdownViewer.js';
import PropertiesPanel from '../common/PropertiesPanel.js';
import { landmarkIcons } from './MapCommon.js';

function cameraReducer(state, action) {
    if (action.type === 'zoom') {
        let newZoom = Math.min(5, Math.max(0.2, state.zoom + action.value.zoom));

        const targetX = (action.value.x - state.x) / state.zoom;
        const targetY = (action.value.y - state.y) / state.zoom;
        const newX = action.value.x - targetX * newZoom;
        const newY = action.value.y - targetY * newZoom;

        return { 
            x: newX,
            y: newY,
            zoom: newZoom,
            animate: false
        };
    }
    else if (action.type === 'pan') {
        return {
            ...state,
            x: state.x + action.value.x,
            y: state.y + action.value.y,
            animate: false
        };
    }
    else if (action.type === 'snap') {
        return {
            ...state,
            x: action.value.x,
            y: action.value.y,
            animate: true
        };
    }
    else if (action.type === 'restore') {
        return {
            ...action.value,
            animate: false
        };
    }
    else if (action.type === 'recenter') {
        return {
            ...state,
            x: 0,
            y: 0,
            zoom: 1.0,
            animate: true
        };
    }
    return state;
}

function stopContextMenu(e) {
    e.preventDefault();
    return false;
}

function LandmarkProperties({data}) {
    return (
        <MarkdownViewer  text={data.description} />
    );
}

function LandmarkList({landmarks, select, tags, repository}) {
    return (
        <Stack spacing={5}>
            <List disablePadding>
                { landmarks?.map((landmark, index) => 
                    <ListItem key={index} disablePadding dense disableGutters>
                        <ListItemButton onClick={() => select(index)} disableGutters>
                            <ListItemIcon sx={{minWidth: 0, marginRight: '0.5em'}}>
                                {landmarkIcons[landmark.icon]?.icon ?? landmarkIcons.poi.icon}
                            </ListItemIcon>
                            <ListItemText>{landmark.name}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
            <Stack spacing={1} mt={4} alignItems="center">
                { (tags?.length > 0 ?? 0) && 
                    <Stack direction="row" justifyContent="center" spacing={1}>
                        { tags.map((tag) => <Chip label={tag} key={tag} />)}
                    </Stack>
                }
                { repository && 
                    <Typography variant="caption">{repository.title}{repository.license && ` • ${repository.license}`}</Typography> 
                }
                <br />
                <br />
            </Stack>
        </Stack>
    );
}

function Landmark({data, index, selected, scale, pointerDown}) {
    const navigate = useNavigate();
    const theme = useTheme();

    let href;
    if (data.link?.id) {
        href = `/document/${data.link.id}`;
    }
    else if (typeof data.link === 'string') {
        href = data.link;
    }

    const validatedSize = Math.max(25, Math.min(300, Number(data.size ?? 100)));
    const scaledSize = validatedSize * ((scale ?? 100) / 100);
    const size = `${scaledSize}%`;

    const forwardPointerDown = useCallback((e) => {
        pointerDown?.(e, index);
    }, [index, pointerDown]);

    const doubleClicked = useCallback((e) => {
        if (href) {
            navigate(href);
        }
    }, [href, navigate]);

    return (
        <Box 
            sx={{position: 'absolute', top: data.y, left: data.x, zIndex: 1200, touchAction: 'none'}}
            onContextMenu={stopContextMenu}
            onPointerDown={forwardPointerDown}
            onDoubleClick={doubleClicked}
            tabIndex="0"
        >
            <Box 
                sx={{
                    transform: `translate(-50%, -50%) scale(${size})`,
                    filter: 'drop-shadow(6px 6px 5px black)',
                    backgroundColor: selected ? theme.palette.secondary.dark : theme.palette.primary.dark,
                    borderRadius: '8px',
                    padding: 1,
                    '&:hover': {
                        backgroundColor: selected ? theme.palette.secondary.dark : theme.palette.primary.main
                    }
                }}
            >
                <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                    { data.icon && landmarkIcons[data.icon]?.icon }
                    { data.label && <Typography sx={{whiteSpace: 'nowrap'}}>{data.label}</Typography> }
                    { (!data.icon && !data.label) && <Box sx={{width: '8px', height: '8px'}} />}
                    { href && <LinkIcon fontSize="small" /> }
                </Stack>
            </Box>
        </Box>
    );
}

const Landmarks = memo(({items, selectedIndex, scale, pointerDown}) => {
    return (<>
        { items.map((item, index) =>
            <Landmark 
                key={index}
                index={index}
                data={item} 
                selected={selectedIndex === index} 
                scale={scale}
                pointerDown={pointerDown} />
        ) }
    </>);
});

function MapToolbar({recenter, openLandmarkList}) {
    return (
        <Box sx={{position: 'fixed', top: '4em', left: 0, right: 0, display: 'flex', justifyContent: 'center'}}>
            <Stack spacing={2} direction="row" component={Paper} elevation={3} sx={{zIndex: 1020}}>
                <IconButton onClick={recenter}>
                    <RecenterIcon fontSize="large" />
                </IconButton>
                <IconButton onClick={openLandmarkList}>
                    <PropertiesIcon fontSize="large" />
                </IconButton>
            </Stack>
        </Box>
    );
}

const panelWidth = '600px';

function MapViewer({data, documentId, repository}) {
    const landmarks = useMemo(() => data.landmarks?.sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '')) ?? [], [data.landmarks]);
    const storageKey = `map-${documentId}`;
    const [camera, updateCamera] = useReducer(cameraReducer, { zoom: 1.0, x: 0, y: 0 });
    const [pointer, setPointer] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isDragging, setDragging] = useState(false);
    const [panelOpen, setPanelOpen] = useState(true);
    const ref = useRef();
    const theme = useTheme();

    const editorRef = useRef();
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, []);

    useEffect(() => {
        try {
            const storedCamera = window.sessionStorage.getItem(storageKey);
            if (storedCamera) {
                updateCamera({type: 'restore', value: JSON.parse(storedCamera)});
            }
        }
        catch (Error) {
        }
    }, [])

    useEffect(() => {
        window.sessionStorage.setItem(storageKey, JSON.stringify(camera));
    });

    const wheel = useCallback((e) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        updateCamera({
            type: 'zoom', 
            value: {
                zoom: e.deltaY * -0.001,
                x: e.clientX - rect.left,
                y: e.clientY - rect.top
            }
        });
    }, []);

    const pointerDown = useCallback((e) => {
        if (!pointer && (e.button === 0 || e.pointerType === "touch")) {
            e.stopPropagation();
            e.preventDefault();
            ref.current.setPointerCapture(e.pointerId);
            setPointer({id: e.pointerId, x: e.clientX, y: e.clientY});
            ref.current.focus();
        }
        else if (e.button === 2) {
            e.stopPropagation();
            e.preventDefault();
            setSelectedIndex(null);
        }
    }, [pointer]);

    const pointerUp = useCallback((e) => {
        if (e.pointerId === pointer?.id && (e.button === 0 || e.pointerType === "touch")) {
            if (!isDragging) {
                setSelectedIndex(null);
            }
            setPointer(null);
            setDragging(false);
            ref.current.releasePointerCapture(e.pointerId);
        }
    }, [pointer, isDragging]);

    const pointerMove = useCallback((e) => {
        if (e.pointerId === pointer?.id) {
            setDragging(true);
            let movementX = e.movementX;
            let movementY = e.movementY;
            // workaround for safari ios which does not support movementX/movementY
            if (!movementX && !movementY)
            {
                movementX = e.clientX - pointer.x;
                movementY = e.clientY - pointer.y;
            }
            updateCamera({type: 'pan', value: {x: movementX, y: movementY}});
            setPointer({id: e.pointerId, x: e.clientX, y: e.clientY});
        }
    }, [pointer]);

    const landmarkPointerDown = useCallback((e, index) => {
        if (e.button === 0) {
            setPointer({id: e.pointerId, x: e.clientX, y: e.clientY});
            setDragging(true);
            e.stopPropagation();
            setSelectedIndex(index);
            setPanelOpen(true);
        }
    }, []);

    const selectLandmark = useCallback((index) => {
        setSelectedIndex(index);
        setPanelOpen(true);
        const rect = ref.current.getBoundingClientRect();
        const x = ((-landmarks[index].x * camera.zoom) + (rect.right - rect.left) * 0.5);
        const y = ((-landmarks[index].y * camera.zoom) + (rect.bottom - rect.top) * 0.5);
        updateCamera({type: 'snap', value: { x: x, y: y }});
    }, [landmarks, camera]);

    const recenter = useCallback(() => {
        updateCamera({
            type: 'recenter'
        });
    }, []);

    const openLandmarkList = useCallback(() => {
        setPanelOpen(true);
        setSelectedIndex(null);
    }, []);

    const scale = `${Math.trunc(camera.zoom * 100)}%`;
    const transform = `translate(${camera.x}px, ${camera.y}px) scale(${scale}, ${scale}) `;
    const selectedLandmark = landmarks[selectedIndex];

    return (<>
        <Box
            ref={ref} 
            sx={{
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: panelOpen ? `calc(100% - ${panelWidth})` : '100%', 
                transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                }),
                height: '100%', 
                overflow: 'hidden', 
                userSelect: 'none',
                touchAction: 'none'
            }}
            onWheel={wheel}
            onPointerDown={pointerDown}
            onPointerUp={pointerUp}
            onPointerMove={pointerMove}   
            onContextMenu={stopContextMenu}
        >
            <Box sx={{
                transform: transform, 
                transformOrigin: '0px 0px',
                touchAction: 'none',
                ...(camera.animate && { transition: theme.transitions.create('transform', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                })}),
            }}>
                { data.image && <img src={data.image} alt={data.altText} sx={{position: 'absolute', top: 0, left: 0, touchAction: 'none' }} /> }
                <Landmarks items={landmarks} selectedIndex={selectedIndex} scale={data.scale} pointerDown={landmarkPointerDown} />
            </Box>
        </Box>
        <MapToolbar recenter={recenter} openLandmarkList={openLandmarkList} />
        <PropertiesPanel label={selectedLandmark ? selectedLandmark.name : "Landmarks"} 
            width={panelWidth}
            open={panelOpen}
            onChange={(v) => setPanelOpen(v)}
        >
            { selectedLandmark ?
                <LandmarkProperties 
                    key={selectedIndex} 
                    data={selectedLandmark}     
                /> 
                :
                <LandmarkList landmarks={landmarks} select={selectLandmark} repository={repository} tags={data.tags} />
            }
        </PropertiesPanel>
    </>);
};

export default MapViewer;
