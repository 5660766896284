import { useNavigate } from 'react-router-dom';
import { ImageList, ImageListItem, Tooltip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MultiColumnList from '../common/MultiColumnList';

export default function ImageAssetBrowser({images}) {
    const navigate = useNavigate();

    return (
        <MultiColumnList 
            spacing={1}
            itemWidth={160} 
            items={images} 
            render={(image, props) =>
                <img 
                    src={image.thumbnail ? image.thumbnail : image.description} 
                    alt={image.title}
                    style={{cursor: 'pointer', borderRadius: '4px'}}
                    onClick={() => navigate(`/document/${image.id}`)}
                    {...props}
                />
            }
        />
    );
}
