import { useEffect, useRef, useState } from 'react';
import { Stack, Tabs, Tab, Box, TextField, Paper, Typography } from '@mui/material';
import { MarkdownEditor } from '../common/MarkdownEditor.js';
import ImagePicker from '../common/ImagePicker.js';
import TagPicker from '../common/TagPicker.js';

function ArticleEditor({data, update, documentId}) {
    const [tab, setTab] = useState(Boolean(documentId) ? 0 : 1);

    const editorRef = useRef();
    useEffect(() => {
        if (tab === 1 && editorRef.current) {
            editorRef.current.focus();
        }
    }, []);

    return (
        <>
            <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                <Tab label="Content" />
                <Tab label="Properties" />
            </Tabs>
            <Box sx={{width: '100%', minHeight: '100%', pl: 1, pr: 1, mt: 1, display: 'flex', flexDirection: 'column'}}>
                { tab === 0 && 
                    <MarkdownEditor value={data.text} onChange={(v) => update({text: v})} placeholder="Write some text here!" autoFocus ref={editorRef} />
                }
                { tab === 1 &&
                    <Stack spacing={2} mt={2} width="100%">
                        <TextField autoFocus required label="Title" size="small" fullWidth autoComplete="off" value={data.title ?? ''} onChange={(e) => update({title: e.target.value})} />
                        <TextField label="Description" size="small" fullWidth autoComplete="off" value={data.description ?? ''} onChange={(e) => update({description: e.target.value})} />
                        <TagPicker label="Tags" fullWidth size="small" category={document.category} value={data.tags} onChange={(e, v) => update({tags: v})} />
                        <ImagePicker size="small" data={data} update={update} noImage />
                    </Stack>
                }
            </Box>
        </>
    );
};

export default ArticleEditor;
