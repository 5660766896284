import { Stack, FormControlLabel, Checkbox } from '@mui/material';
import { MultiComboBox } from '../common/MultiComboBox.js';
import { SelectBox } from '../common/SelectBox.js'
import { MultiSelectBox } from '../common/MultiSelectBox.js';
import { classSuggestions, spellSchoolSuggestions } from '../../suggestions.js';
import { getSpellLevelLabel, spellLevelList } from '../../dnd5e.js';

const spellFeatureOptions = ['Ritual', 'Concentration'];

function SpellFeatures({data, update}) {
    const features = [ data.ritual && 'Ritual', data.concentration && 'Concentration' ]
        .filter((v) => Boolean(v));
    
    function handleChange(e, v) {
        update({
            ritual: v.includes('Ritual') ? true : null,
            concentration: v.includes('Concentration') ? true : null
        });
    }

    return (
        <MultiSelectBox label="Features" fullWidth options={spellFeatureOptions} value={features} onChange={handleChange} />
    );
}

function SpellCastTime({data, update}) {
    const options = ['1 action', '1 reaction', '1 bonus action'];

    return (
        <SelectBox label="Cast Time" fullWidth options={options} value={data.castTime ?? null} onChange={(e, v) => update({castTime: v})} />
    );
}

function SpellFilters({data, update}) {
    return (
        <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
                <MultiComboBox label="School" fullWidth
                    value={data?.['school'] ?? []}
                    options={spellSchoolSuggestions}
                    onChange={(e, v) => update({'school': v})} />
                <SpellFeatures data={data} update={update} />
                <SpellCastTime data={data} update={update} />
            </Stack>
            <Stack direction="row" spacing={1}>
                <MultiComboBox label="Classes" fullWidth
                    value={data?.['classes'] ?? []}
                    options={classSuggestions}
                    onChange={(e, v) => update({'classes': v})} />
                <SelectBox label="Min Level" sx={{width: '30%'}}
                    options={spellLevelList}
                    getOptionLabel={getSpellLevelLabel}
                    value={data?.['level:min'] ?? null}
                    onChange={(e, v) => update({'level:min': v})}
                />
                <SelectBox label="Max Level" sx={{width: '30%'}}
                    options={spellLevelList}
                    getOptionLabel={getSpellLevelLabel}
                    value={data?.['level:max'] ?? null}
                    onChange={(e, v) => update({'level:max': v})}
                />
            </Stack>
        </Stack>
    );
}

export { SpellFilters }

