import { Box, Button, CircularProgress } from '@mui/material';
import { green } from '@mui/material/colors';

function SubmitButton({loading, success, disabled, children, onClick}) {
    const buttonSx = {
        bgColor: green[500],
        '&:hover': {
            bgColor: green[700]
        }
    }

    return (
        <Box sx={{ position: 'relative'}}>
            <Button type="submit" sx={{buttonSx}} disabled={loading || disabled} onClick={onClick}>
                {children}
            </Button>
            { loading &&
                <CircularProgress size={24} 
                    sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                    }}
                />
            }
        </Box>
    )
}

export default SubmitButton;
