import { Typography, Stack } from '@mui/material';
import { MarkdownViewer } from '../common/MarkdownViewer.js'; 
import { getSessionTitleAndSubtitle } from '../../models/session.js';

function SessionViewer({data}) {
    const [title, subtitle] = getSessionTitleAndSubtitle(data);

    return (
        <Stack spacing={2}>
            <Typography variant="subtitle1">{subtitle}</Typography>
            <Stack spacing={1}>
                <Typography variant="h2">Recap</Typography>
                <MarkdownViewer text={data.recap} />
            </Stack>
            <Stack spacing={1}>
                <Typography variant="h2">Planning</Typography>
                <MarkdownViewer text={data.planning} />
            </Stack>            
            <Stack spacing={1}>
                <Typography variant="h2">Notes</Typography>
                <MarkdownViewer text={data.notes} />
            </Stack>
        </Stack>
    );
}

export default SessionViewer;
