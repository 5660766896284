import { formatSentence } from "../text.js";
import { getSpellLevelLabel } from "../dnd5e.js";

function getSpellDescription(spell) {
    let desc;   
    if (spell.level === 0) {
        desc = `${spell.school ?? ''} cantrip`;
    }
    else {
        desc = `${getSpellLevelLabel(spell.level)} ${spell.school ?? ''}`;
    }
    if (spell.ritual) {
        desc += ' (ritual)';
    }
    return formatSentence(desc);
}

const Spell = {
    name: 'Spell (5E)',
    plural: 'Spells',

    init: () => {
        return {
            ...(Spell.deserialize({})),
            castTime: '1 action',
            duration: 'instant'
        }
    },
    
    validate: (data) => {
        let errors = [];
        if (!data.name) {
            errors.push('Name is required.');
        }
        if (!data.level && data.level !== 0) {
            errors.push('Level is required.');
        }
        return errors;
    },

    deserialize: (document) => ({
        name: document.title ?? null,
        tags: document.tags ?? [],
        text: document.data?.text ?? null,
        thumbnail: document.thumbnail ?? null,
        altText: document.altText ?? null,
        image: document.data?.image ?? null,
        level: document.data?.level ?? null,
        castTime: document.data?.castTime ?? null,
        activation: document.data?.activation ?? null,
        ritual: document.data?.ritual ?? false,
        concentration: document.data?.concentration ?? false,
        verbal: document.data?.verbal ?? false,
        somatic: document.data?.somatic ?? false,
        material: document.data?.material ?? false,
        components: document.data?.components ?? null,
        consumeComponents: document.data?.consumeComponents ?? false,
        classes: document.data?.classes ?? [],
        school: document.data?.school ?? null,
        upcast: document.data?.upcast ?? null,
        range: document.data?.range ?? null,
        duration: document.data?.duration ?? null       
    }),

    serialize: (data) => ({
        title: data.name,
        tags: data.tags,
        thumbnail: data.thumbnail,
        altText: data.altText,
        description: getSpellDescription(data),
        data: {
            level: data.level,
            text: data.text,
            image: data.image,
            castTime: data.castTime,
            activation: data.activation,
            verbal: data.verbal,
            somatic: data.somatic,
            material: data.material,
            components: data.components,
            consumeComponents: data.consumeComponents,
            ritual: data.ritual,
            concentration: data.concentration,
            classes: data.classes,
            school: data.school,
            upcast: data.upcast,
            range: data.range,
            duration: data.duration
        }
    })
}

export default Spell;
export { getSpellDescription };

