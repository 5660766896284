import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { useTitleBar } from '../../globals.js';
import { documentTypes, documentCategories } from '../../documents.js';

function CreateDocumentMenu({anchor, close}) {
    let options = [];
    for (let categoryKey in documentCategories) {
        const category = documentCategories[categoryKey];
        if (!category.disableCreate) {
            const showType = category.types.length > 1;
            options = [...options, ...(category.types.map((typeKey) => ({ 
                label: showType ? `${category.name} (${documentTypes[typeKey].name})` : category.name, 
                category: categoryKey, 
                type: typeKey,
                icon: category.icon,
                target: {
                    pathname: `/document/draft/${categoryKey}/${typeKey}`,
                    key: Math.random()
                }
            })))];
        }
    }

    return (
        <Menu open={true} anchorEl={anchor} onClose={close}>
            { options.map((option, index) => 
                <RouterLink key={index} to={option.target} state={'new'} style={{textDecoration: 'inherit', color: 'inherit'}}>
                    <MenuItem 
                        key={index}
                        onClick={close}
                    >
                        {option.icon &&
                            <ListItemIcon>
                                {option.icon}
                            </ListItemIcon>
                        }
                        <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                </RouterLink>
            )}
        </Menu>
    );
}

export default function CreateDocument(props) {
    const { addWidget, removeWidget } = useTitleBar();
    const [anchor, setAnchor] = useState(null);

    const open = useCallback((e) => {
        setAnchor(e.currentTarget);
    }, []);

    const close = useCallback(() => {
        setAnchor(null);
    }, []);

    // add the button to the title bar
    useEffect(() => {
        const widget = (
            <Tooltip title="New Document" key="create-document">
                <IconButton onClick={open}>
                    <AddCircleIcon />
                </IconButton>
            </Tooltip>
        );

        addWidget(widget);
        return () => {
            removeWidget('create-document');
        }
    }, [open, addWidget, removeWidget]);

    return (
        <>
            { anchor && <CreateDocumentMenu anchor={anchor} close={close} /> }
            {props.children}
        </>
    )
};


