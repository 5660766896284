import { createContext, useContext, useReducer, useCallback } from 'react';
import { getRepository } from '../../api.js';

const RepositoryCacheContext = createContext();

function useRepositoryCache() {
    return useContext(RepositoryCacheContext);
}

function repositoryCacheReducer(cache, action) {
    return { ...cache, [action.id]: action.data };
}

function RepositoryCache(props) {
    const [cache, updateCache] = useReducer(repositoryCacheReducer, {});

    const fetchRepositoryData = useCallback(async (repoId) => {
        const res = await getRepository(repoId);
        if (res.result === 'success') {
            updateCache({id: repoId, data: { title: res.title, description: res.description, license: res.license }});
        }
    }, []);

    const getRepositoryData = useCallback((repoId) => {
        if (cache[repoId]) {
            return cache[repoId];
        }
        fetchRepositoryData(repoId);
        return null;
    }, [cache, fetchRepositoryData]);

    return (
        <RepositoryCacheContext.Provider 
            value={{
                getRepository: getRepositoryData,
                refreshRepository: fetchRepositoryData
            }}
        >
            {props.children}
        </RepositoryCacheContext.Provider>
    );
}

export { RepositoryCache, useRepositoryCache };
