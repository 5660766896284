import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, unstable_useBlocker as useBlocker, useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery, CircularProgress, Alert, Box, Fab, Stack, DialogContent, DialogContentText, DialogActions, Button, Snackbar } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import SubmitButton from '../common/SubmitButton.js';
import RepositoryPicker from '../common/RepositoryPicker.js';
import CommonDialog from '../common/CommonDialog.js';
import { getDocumentType, getDocumentCategory } from '../../documents.js';
import { useTitleBar, useDocumentContext, useRequireAuth } from '../../globals.js'
import { useStateBlock } from '../../utils.js';
import { createDocument, getDocument } from '../../api.js';
import { cleanTags } from '../common/TagPicker.js';

function ConfirmNavigateDialog({confirm, cancel, save}) {
    return (
        <CommonDialog close={cancel} title="Leave this page?">
            <DialogContent>
                <DialogContentText>You have unsaved changes. Would you like to save now?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} autoFocus>Cancel</Button>
                <Button onClick={confirm}>Continue Without Saving</Button>
                <Button onClick={save}>Save and Continue</Button>
            </DialogActions>
        </CommonDialog>
    );
}

function SaveDialog({save, close, error}) {
    const { repository: defaultRepo } = useDocumentContext();
    const [repo, setRepo] = useState(defaultRepo);
    const [isLoading, setIsLoading] = useState(false);
    const canSubmit = Boolean(repo);

    const submit = useCallback(async () => {
        setIsLoading(true);
        await save(repo);
        setIsLoading(false);
    }, [save, repo]);

    return (
        <CommonDialog close={close} title="Save Document">
            <DialogContent>
                <Stack spacing={2} mt={2}>
                    <Stack direction="row" spacing={1}>
                        <RepositoryPicker fullWidth minRole="editor" useDefault
                            value={repo}
                            onChange={(v) => setRepo(v)}
                        />
                    </Stack>
                    { error && <Alert severity="error">{error}</Alert> }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <SubmitButton onClick={submit} disabled={!canSubmit} loading={isLoading}>Save</SubmitButton>
            </DialogActions>
        </CommonDialog>
    )
}

function RestoreDialog({close, reset}) {
    const navigate = useNavigate();

    function submit() {
        navigate('.', { replace: true });
        reset();
        close();
    }

    function handleClose() {
        navigate('.', { replace: true });
    }
    
    return (
        <CommonDialog close={handleClose} title="Restore previous draft?">
            <DialogContent>
                <DialogContentText>A previous draft has been saved. Do you want to restore it, or start with an empty document? (This will permanently delete your saved draft.)</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Use Saved Draft</Button>
                <Button onClick={submit}>Start New Document</Button>
            </DialogActions>
        </CommonDialog>
    )
}

function handleBeforeUnload(e) {
    e.preventDefault();
}

function DocumentDraftPage() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [isRestoreDialogOpen, setIsRestoreDialogOpen] = useState(false);
    const [typeName, setTypeName] = useState();
    const [categoryName, setCategoryName] = useState();
    const [documentData, updateDocumentData] = useStateBlock();
    const [hasChanges, setHasChanges] = useState(false);
    const [editorKey, setEditorKey] = useState(0);
    const { type: typeParam, category: categoryParam, id: sourceDocumentId } = useParams();
    const { setTitle } = useTitleBar();
    const navigate = useNavigate();
    const storageKey = location.pathname;
    const requireAuth = useRequireAuth();

    const documentType = getDocumentType(typeName);

    const theme = useTheme();
    const useFab = true; //useMediaQuery(theme.breakpoints.up('xl'));

    const shouldBlock = useCallback(({currentLocation, nextLocation}) => 
        (currentLocation.pathname !== nextLocation.pathname) && hasChanges, 
    [hasChanges]);
    const blocker = useBlocker(shouldBlock);

    const fetchDocument = useCallback(async (documentId) => {
        setIsLoading(true);
        const response = await getDocument(documentId, true);
        if (response.result === 'success') {
            const typeInfo = getDocumentType(response.type);
            if (typeInfo) {
                setTitle(`Copy of ${response.title}`);
                setTypeName(response.type);
                setCategoryName(response.category);
                updateDocumentData(() => typeInfo.deserialize(response));
                setHasChanges(true);
                setError();
            }
            else {
                setError(`Unable to open document: unknown type ${response.type}.`);
            }
        }
        else {
            setError('Failed to retrieve document. Please try again.');
        }
        setIsLoading(false);
    }, [updateDocumentData, setTitle]);

    // save draft to local storage when it changes
    useEffect(() => {
        if (hasChanges && documentData) {
            window.localStorage.setItem(storageKey, JSON.stringify(documentData));
        }
    }, [documentData, categoryName, typeName, hasChanges, storageKey]);

    // register navigation events
    useEffect(() => {
        if (hasChanges) {
            window.addEventListener("beforeunload", handleBeforeUnload, null);
            return () => { 
                window.removeEventListener("beforeunload", handleBeforeUnload);
            }
        }
        else {
            if (blocker.state === "blocked") {
                blocker.proceed?.();
            }
            else {
                blocker.reset?.();
            }
        }
    }, [hasChanges, blocker]);

    // initialization
    useEffect(() => {
        let hadStoredData = false;
        if (!documentData && !isLoading) {
            if (sourceDocumentId) {
                setTitle(`Loading...`);
                fetchDocument(sourceDocumentId);
            }
            else {
                let storageData;
                try {
                    storageData = JSON.parse(window.localStorage.getItem(storageKey));
                    hadStoredData = Boolean(storageData);
                }
                catch (_) { }
    
                const documentType = getDocumentType(typeParam);
                const documentCategory = getDocumentCategory(categoryParam);
                if (documentType && documentCategory) {
                    setTypeName(typeParam);
                    setCategoryName(categoryParam);
                    setTitle(`New ${documentType.name}`);
                    if (storageData) {
                        updateDocumentData(() => storageData);          
                        setHasChanges(true);         
                    }
                    else {
                        let emptyDocument = documentType.init?.() ?? {};
                        updateDocumentData(() => emptyDocument);
                    }
                }
                else {
                    // 404?
                    setTitle('Error');
                    setError('Invalid document type');
                }
            }
        }

        // this is true when we come directly from clicking on a menu item but not if we navigate back later
        if (location.state === 'new') {
            // only show the restore dialog if we either loaded some stored data on initialization, or we ran into this post-initialization
            // (e.g. someone choosing New Document from the menu when already on this page)
            if (hadStoredData || hasChanges) {
                setIsRestoreDialogOpen(true);
            }
            else {
                navigate(".", { replace: true });
            }
        }
    });

    const reset = useCallback(() => {
        let emptyDocument = documentType.init?.() ?? {};
        updateDocumentData(() => emptyDocument);
        setHasChanges(false);
        window.localStorage.setItem(storageKey, null);        
        setEditorKey((editorKey) => editorKey + 1);
    }, [documentType, updateDocumentData, storageKey]);

    const save = useCallback(async (repo) => {
        if (documentData && repo) {
            const errorList = documentType.validate?.(documentData) ?? [];
            if (errorList.length > 0) {
                setError(errorList.join('\n'));
            }
            else {
                setIsLoading(true);
                const document = documentType.serialize(documentData);
    
                // do any universal validation here
                document.tags = cleanTags(document.tags);
    
                const response = await createDocument({
                    ...document, 
                    repositoryId: repo, 
                    type: typeName, 
                    category: categoryName
                });
    
                if (response.result === 'success') {
                    setError();
                    setHasChanges(false);
                    window.localStorage.setItem(storageKey, null);     
                    if (blocker.state !== "blocked") {
                        navigate(`/document/${response.documentId}`, { replace: true });
                    }
                }
                else {
                    setError('Failed to save document. Please try again.');
                }
                setIsLoading(false);   
            }          
        }
    }, [documentData, documentType, typeName, categoryName, navigate, blocker, storageKey]);

    const saveClicked = useCallback(() => {
        const errorList = documentType.validate?.(documentData) ?? [];
        if (errorList.length > 0) {
            setError(errorList.join('\n'));
        }
        else {
            setError();
            if (requireAuth()) {
                setIsSaveDialogOpen(true);
            }
        }
    }, [requireAuth, documentData, documentType]);

    const updateWrapper = useCallback((params) => {
        updateDocumentData(params);
        setHasChanges(true);
    }, [updateDocumentData]);

    const cancelBlocker = useCallback(() => blocker.reset?.(), [blocker]);
    const confirmBlocker = useCallback(() => {
        blocker.proceed?.()
    }, [blocker]);
    const isBlocked = blocker.state === "blocked";

    return (<>
        { isSaveDialogOpen && <SaveDialog close={() => setIsSaveDialogOpen(false)} save={save} error={error} /> }
        { isRestoreDialogOpen && <RestoreDialog close={() => setIsRestoreDialogOpen(false)} reset={reset} /> }
        { error && 
            <Snackbar open={Boolean(error)}>
                <Alert severity="error"  onClose={() => setError()} sx={{width: '100%'}}>{error}</Alert>
            </Snackbar>
        }
        <Box id="document-draft-page" width="100%" height="100%">
            { isBlocked && <ConfirmNavigateDialog cancel={cancelBlocker} confirm={confirmBlocker} save={save} /> }
            { isLoading && <CircularProgress /> }
            { documentType?.editor?.({
                key: editorKey, 
                data: documentData, 
                update: updateWrapper,
            }) }
        </Box>
        { useFab &&
            <Box sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: '1100' }}>
                <Stack spacing={2}>
                    <Fab onClick={saveClicked} color="primary">
                        <SaveIcon />
                    </Fab>
                </Stack>
            </Box>
        }
    </>);
}

export default DocumentDraftPage;
