import { Link as RouterLink } from 'react-router-dom';
import { Card, CardActionArea, CardContent, Stack, Avatar, Typography, LinearProgress } from '@mui/material';
import { Article as ArticleIcon } from '@mui/icons-material';
import { useRepositoryCache } from "../../globals.js";
import { documentCategories } from "../../documents.js";

const defaultIcon = <ArticleIcon />;

function DocumentButton({doc}) {
    const { getRepository } = useRepositoryCache();
    const repo = getRepository(doc.repository);
    const icon = documentCategories[doc.category]?.icon ?? defaultIcon;

    return (
        <Card sx={{minWidth: '300px'}} elevation={2}>
            <CardActionArea component={RouterLink} to={'/document/' + doc.id}>
                <CardContent>
                    <Stack direction="row" spacing={2} sx={{display: 'flex', width: '100%', minWidth: 0}}>
                        <Avatar sx={{ width: 64, height: 64 }}>
                            { doc.thumbnail ?
                                <img src={doc.thumbnail} alt={doc.thumbnailAltText} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                :
                                icon
                            }
                        </Avatar>
                        <div style={{flex: '1 1 100%', minWidth: 0}}>
                            <Typography variant="h3_noborder" sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{doc.title}&nbsp;</Typography>
                            <Typography variant="subtitle2" sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: 1}}>{doc.description}&nbsp;</Typography>
                            { repo ?
                            <Typography variant="caption" sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{repo.title}</Typography>
                            :
                            <LinearProgress />     
                            }                           
                        </div>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export { DocumentButton };
