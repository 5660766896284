import { useState, useCallback, memo,  useMemo, useEffect } from 'react';
import { Stack, Button, TextField, InputAdornment, Box, Checkbox, Typography, Tooltip,  IconButton, Table, TableBody, TableRow, TableCell, TableHead,
    Stepper, Step, StepButton, Paper, FormControlLabel, Switch, DialogContent, DialogActions, Alert, Link, Divider } from '@mui/material';
import { 
    AddCircle as AddIcon, 
    AddLink as ImportIcon,
    Delete as DeleteIcon, 
    AllInclusive as InfinityIcon, 
    RotateLeft as ResetIcon,
    RadioButtonUnchecked as RadioUncheckedIcon,
    RadioButtonChecked as RadioCheckedIcon,
    Circle as RadioFilledIcon,
    ArrowUpward as MoveUpIcon,
    ArrowDownward as MoveDownIcon,
    Functions as CalculatorIcon
} from '@mui/icons-material';
import { ComboBox } from '../common/ComboBox.js';
import { SelectBox } from '../common/SelectBox.js';
import { OutlinedBox } from '../common/OutlinedBox.js';
import { NumberField } from '../common/NumberField.js';
import { DiceFormulaField } from '../common/DiceFormulaField.js';
import ImagePicker from '../common/ImagePicker.js';
import { MultiComboBox } from '../common/MultiComboBox.js';
import { MultiDocumentPicker } from '../common/MultiDocumentPicker.js';
import DocumentPicker from '../common/DocumentPicker.js';
import PropertiesPanel, { PropertiesLayout } from '../common/PropertiesPanel.js';
import MarkdownField from '../common/MarkdownField.js';
import { TagPicker } from '../common/TagPicker.js';
import { useStateBlockObject, useStateBlockArray } from '../../utils.js';
import { getDiceFormulaMetrics, makeDiceFormula } from '../../dice.js';
import { getProficiencyBonusByCR,
    getOffensiveCRFromDamage,
    getDefensiveCRFromHitPoints,
    challengeRatingToString,
    abilityScoreList,   
    getAbilityScoreLabel,
    getAbilityScoreModifier,
    skillList,
    getSkillLabel,
    getSkillAbilityScore,
    spellLevelList,
    getActionTypeLabel,
    actionTypeList,
    getAttackTypeLabel,
    attackTypeList,
    getSpellLevelLabel
} from '../../dnd5e.js';
import { sizeSuggestions, 
    creatureTypeSuggestions, 
    creatureSubtypeSuggestions,
    damageTypeSuggestions, 
    damageTypeResistanceSuggestions, 
    conditionSuggestions, 
    senseSuggestions, 
    languageSuggestions, 
    actionLimitSuggestions, 
    spellLimitSuggestions,
    creatureArmorSuggestions, 
    monsterAlignmentSuggestions,
    speedSuggestions,
    challengeRatingSuggestions,
    weaponRangeSuggestions
} from '../../suggestions.js';
import { getDocument } from '../../api.js';
import CommonDialog from '../common/CommonDialog.js';
import SubmitButton from '../common/SubmitButton.js';
import { MarkdownViewer } from '../common/MarkdownViewer.js';
import Section from '../common/Section.js';
import Monster from '../../models/monster.js';
import MonsterFeature from '../../models/monsterFeature.js';

const calculatorIcon = <CalculatorIcon />;

function SingleAbilityScoreEditor({label, value, setValue, noModifier, ...props}) {
    const [inputValue, setInputValue] = useState(value?.toString() ?? '');
    const [hasFocus, setFocus] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = useCallback((e) => {
        setInputValue(e.target.value);

        const num = Number(e.target.value);
        if (!isNaN(num) && num === Math.trunc(num)) {
            if (num >= 1 && num <= 80) {
                setValue(num);
                setError(false);
            }
            else {
                setError(true);
            }
        }
        else {
            setError(true);
        }
    }, [setValue]);

    const handleBlur = useCallback((e) => {
        setInputValue(value);
        setError(false);
        setFocus(false);
    }, [value]);

    const handleFocus = useCallback(() => {
        setFocus(true);
    })

    const modifier = Math.trunc((value - 10) / 2);
    const modifierStr = (modifier >= 0 ? '+' + modifier : modifier.toString());

    return (
        <TextField 
            {...props}
            label={label}
            autoComplete="off" 
            value={(hasFocus ? inputValue : value) ?? ''} 
            onChange={handleChange} 
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={error}
            fullWidth
            InputProps={{ 
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                endAdornment: !(error || noModifier) && <InputAdornment position="end" sx={{userSelect: 'none'}}>{modifierStr}</InputAdornment>
            }} 
        />
    );
}

const AbilityScoresEditor = memo(({abilityScores, update}) => {
    const setAttr = useCallback((a, v) => {
        update({...abilityScores, [a]: v});
    }, [abilityScores, update]);

    return (
        <Stack direction="row" spacing={1} sx={{marginTop: 1}}>
            <SingleAbilityScoreEditor variant="filled" size="small" label="STR" value={abilityScores.str} setValue={(v) => setAttr('str', v)} />
            <SingleAbilityScoreEditor variant="filled" size="small" label="DEX" value={abilityScores.dex} setValue={(v) => setAttr('dex', v)} />
            <SingleAbilityScoreEditor variant="filled" size="small" label="CON" value={abilityScores.con} setValue={(v) => setAttr('con', v)} />
            <SingleAbilityScoreEditor variant="filled" size="small" label="INT" value={abilityScores.int} setValue={(v) => setAttr('int', v)} />
            <SingleAbilityScoreEditor variant="filled" size="small" label="WIS" value={abilityScores.wis} setValue={(v) => setAttr('wis', v)} />
            <SingleAbilityScoreEditor variant="filled" size="small" label="CHA" value={abilityScores.cha} setValue={(v) => setAttr('cha', v)} />            
        </Stack>
    );
});

const spellDamageByLevel = [
    5.5,
    13.5,
    21.5,
    33.5,
    45.5,
    55.5,
    67.5,
    76.5,
    82.5,
    104
];

function getActionUseLimit(action) {
    if (!action.limit) {
        return 99;
    }
    else if (action.limit.startsWith('Recharge')) {
        return 1;
    }
    else {
        const uses = parseInt(action.limit);
        if (!isNaN(uses)) { 
            return uses;
        }
    }
    return 0;
}

function calculateAttackMetrics(actions, proficiencyBonus, getAbilityScore) {
    const attacksPerTurn = actions.attacksPerTurn ?? 1;
    let attacksRemaining = attacksPerTurn;
    let attacksUsed = {};
    let damagePerTurn = 0;
    let totalAttackBonus = 0;
    let attackSummary = [];
    while (attacksRemaining > 0) {
        attacksRemaining -= 1;

        let bestAttackDamage = 0;
        let bestAttackIndex;
        for (let actionIndex in (actions.actions ?? [])) {
            let action = actions.actions[actionIndex];
            if (action.type === 'attack') {
                let actionCount = attacksUsed[actionIndex] ?? 0;
                if (actionCount < (action.multiattackLimit ?? 999)) {
                    let abilityScoreModifier = 0;
                    if (action.abilityScore) {
                        abilityScoreModifier = getAbilityScoreModifier(getAbilityScore(action.abilityScore));
                    }
        
                    let attackBonus = (proficiencyBonus ?? 0) + abilityScoreModifier + (action.attackBonus ?? 0);
                    let attackDamage = 0;
                    for (let effect of (action.effects ?? [])) {
                        const effectDamage = getEffectTotalDamage(effect, abilityScoreModifier);
                        let avgDamage = getDiceFormulaMetrics(effectDamage).avg;
                        if (effect.saveType === 'half') {
                            avgDamage *= 0.75;
                        }
                        else if (effect.saveType === 'none') {
                            avgDamage *= 0.66;
                        }
                        attackDamage += avgDamage;
                    }
                    if (attackDamage > bestAttackDamage) {
                        bestAttackDamage = attackDamage;
                        bestAttackIndex = actionIndex;
                        totalAttackBonus += attackBonus;
                    }
                }
            }
        }

        if (bestAttackIndex) {
            damagePerTurn += bestAttackDamage;
            attacksUsed[bestAttackIndex] = (attacksUsed?.[bestAttackIndex] ?? 0) + 1;
            attackSummary = [...attackSummary, `${actions.actions[bestAttackIndex].name} for ${bestAttackDamage}`];
        }
    }

    const attackSummaryString = attackSummary.join(", ") + ` (Total ${damagePerTurn})`;

    return {
        damagePerTurn: Math.round(damagePerTurn),
        attackBonus: Math.round(totalAttackBonus / attacksPerTurn),
        summary: attackSummaryString
    };
}

function calculateOffensiveMetrics(actions, spellcasting, proficiencyBonus, getAbilityScore) {
    const { damagePerTurn: attackDamagePerTurn, attackBonus, summary: attackSummary } = calculateAttackMetrics(actions, proficiencyBonus, getAbilityScore);
    
    let totalDamage = 0;
    let turnsRemaining = 3;
    let turnSummaries = [];
    let spellSlotsUsed = {};
    let spellsUsed = 0;
    let actionsUsed = {};

    let spellcastingModifier = proficiencyBonus;
    if (spellcasting.spellcastingAbility) {
        spellcastingModifier += getAbilityScoreModifier(getAbilityScore(spellcasting.spellcastingAbility));
    }

    // for spells & abilities we must consider each round what is available since the most powerful ones will not have 3 turns of use
    while (turnsRemaining > 0) {
        turnsRemaining -= 1;

        let spellDamage = 0;
        let spellSlotLevel;
        for (let spellLevel = 9; spellLevel >= 0; spellLevel--) {
            const slotsUsed = spellSlotsUsed[spellLevel] ?? 0;
            const slotsAvailable = (spellLevel === 0 ? 999 : (spellcasting.spellLevels?.[spellLevel]?.slots ?? 0));
            const spellsAvailable = (spellcasting?.spellLevels[spellLevel]?.spells?.length ?? 0) > 0;
            if (spellsAvailable && slotsUsed < slotsAvailable) {
                spellSlotLevel = spellLevel;
                spellDamage = spellDamageByLevel[spellLevel];
                break;
            }
        }

        let bestActionDamage = 0;
        let bestActionIndex;
        for (let actionIndex in actions.actions) {
            const action = actions.actions[actionIndex];
            if (action.type !== 'attack' && action.damage) {
                const uses = actionsUsed[actionIndex] ?? 0;
                if (uses < getActionUseLimit(action)) {
                    const damageMetrics = getDiceFormulaMetrics(action.damage);
                    if (damageMetrics.avg > bestActionDamage) {
                        bestActionIndex = actionIndex;
                        bestActionDamage = damageMetrics.avg;
                    }
                }
            }
        }

        let turnString = `Turn ${3 - turnsRemaining}: `;
        let actionString;
        if (spellSlotLevel && (spellDamage > attackDamagePerTurn) && (spellDamage > bestActionDamage)) {
            totalDamage += spellDamage;
            spellSlotsUsed[spellSlotLevel] = (spellSlotsUsed[spellSlotLevel] ?? 0) + 1;   
            spellsUsed += 1;
            actionString = `Cast ${getSpellLevelLabel(spellSlotLevel)} spell for ${spellDamage}}`;         
        }
        else if (bestActionIndex && (bestActionDamage > attackDamagePerTurn) && (bestActionDamage > spellDamage)) {
            totalDamage += bestActionDamage;
            actionsUsed[bestActionIndex] = (actionsUsed[bestActionIndex] ?? 0) + 1;
            actionString = `${actions.actions[bestActionIndex].name} for ${bestActionDamage}`;
        }
        else {
            totalDamage += attackDamagePerTurn;
            actionString = attackSummary;
        }
        turnSummaries = [...turnSummaries, turnString + actionString];
    }

    let damagePerTurn = totalDamage / 3;
    let effectiveAttackBonus = ((spellcastingModifier * spellsUsed) + (attackBonus * (3 - spellsUsed))) / 3;
   
    return {
        damagePerTurn: Math.round(damagePerTurn),
        attackDamagePerTurn: Math.round(attackDamagePerTurn),
        attackBonus: Math.round(effectiveAttackBonus),
        damagePerTurnSummary: turnSummaries.join('\n')
    };
}

function calculateOffensiveCR(damagePerTurn, attackBonus) {
    let averageDamagePerTurn = damagePerTurn;
    if (typeof damagePerTurn !== 'number') {
        // it could be a dice formula instead
        averageDamagePerTurn = getDiceFormulaMetrics(damagePerTurn).avg;
    }

    const crData = getOffensiveCRFromDamage(averageDamagePerTurn);
   
    let adjustedCR = crData.cr;
    let attackBonusDelta = attackBonus - crData.attackBonus;
    adjustedCR = Math.max(0, adjustedCR + Math.trunc(attackBonusDelta / 2));

    return adjustedCR;
}

function calculateDefensiveMetrics(savingThrows, defenses, getAbilityScore) {
    let savingThrowBonuses = 0;
    for (let [_, savingThrow] of Object.entries(savingThrows.savingThrows)) {
        if (savingThrow.proficient || savingThrow.bonus > 0) {
            savingThrowBonuses += 1;
        }
    }

    let resistances = defenses.resistances?.length ?? 0;
    let immunities = defenses.damageImmunities?.length ?? 0;
    let vulnerabilities = defenses.vulnerabilities?.length ?? 0;

    const conModifier = getAbilityScoreModifier(getAbilityScore('con'));
    const hitPoints = getTotalHitPoints(defenses.hitPoints, conModifier);
    const avgHitPoints = getDiceFormulaMetrics(hitPoints).avg;
    let ehpSummary = [`Base Hit Points: ${avgHitPoints}`];
    let scalingFactor = 1.0;

    if (resistances > 0) {
        scalingFactor += (0.05 * resistances);
        ehpSummary = [...ehpSummary, `+${5 * resistances}% for Damage Type Resistance`];
    }
    if (immunities > 0) {
        scalingFactor += (0.1 * immunities);
        ehpSummary = [...ehpSummary, `+${10 * immunities}% for Damage Type Immunities`];
    }
    if (vulnerabilities > 0) {
        scalingFactor -= 0.5;
        ehpSummary = [...ehpSummary, `-50% for Damage Type Vulnerabilities`];
    }
    let effectiveHitPoints = avgHitPoints * scalingFactor;

    let effectiveArmorClass = (defenses.armorClass ?? 10);
    let eacSummary = [`Base Armor Class: ${effectiveArmorClass}`];
    if (savingThrowBonuses >= 5) {
        eacSummary = [...eacSummary, '+4 for Saving Throw Bonuses'];
        effectiveArmorClass += 4;
    }
    else if (savingThrowBonuses >= 2) {
        eacSummary = [...eacSummary, '+2 for Saving Throw Bonuses'];
        effectiveArmorClass += 2;
    }

    return {
        effectiveHitPoints: Math.round(effectiveHitPoints),
        effectiveHitPointSummary: ehpSummary.join('\n'),
        effectiveArmorClass: Math.round(effectiveArmorClass),
        effectiveArmorClassSummary: eacSummary.join('\n')
    };
}

function calculateDefensiveCR(effectiveHitPoints, effectiveArmorClass) {
    let defensiveCR = getDefensiveCRFromHitPoints(effectiveHitPoints);
    let adjustedCR = defensiveCR.cr;
    let armorClassDelta = effectiveArmorClass - defensiveCR.armorClass;
    adjustedCR = Math.max(0, adjustedCR + Math.trunc(armorClassDelta / 2));

    return adjustedCR;
}

function calculateCRAdjustment(traits, actions) {
    let result = 0;
    for (let trait of traits) {
        result += (trait.crAdjustment ?? 0);
    }
    for (let action of actions) {
        result += (action.crAdjustment ?? 0);
    }
    return result;
}

const ChallengeRatingCalculator = memo(({data, update, actions, spellcasting, savingThrows, defenses, traits, proficiencyBonus, getAbilityScore}) => {
    const offensiveMetrics = useMemo(() => calculateOffensiveMetrics(actions, spellcasting, proficiencyBonus, getAbilityScore),
        [actions, spellcasting, proficiencyBonus, getAbilityScore]);
    const defensiveMetrics = useMemo(() => calculateDefensiveMetrics(savingThrows, defenses, getAbilityScore),
        [savingThrows, defenses, getAbilityScore]);
    const crAdjustment = useMemo(() => calculateCRAdjustment(traits.traits, actions.actions),
        [traits, actions]);

    const offensiveCR = calculateOffensiveCR(data.overrideDamagePerTurn ?? offensiveMetrics.damagePerTurn, data.overrideAttackBonus ?? offensiveMetrics.attackBonus);
    const defensiveCR = calculateDefensiveCR(data.overrideEffectiveHP ?? defensiveMetrics.effectiveHitPoints, data.overrideEffectiveAC ?? defensiveMetrics.effectiveArmorClass);
    const finalCR = Math.round(((offensiveCR + defensiveCR) * 0.5) + (crAdjustment ?? 0));
    const finalCRString = challengeRatingToString(finalCR);

    return (
        <Stack spacing={2} mt={2}>
            <Tooltip title={
                <span style={{whiteSpace: 'pre-line' }}>
                    {(!data.overrideDamagePerTurn) && offensiveMetrics.damagePerTurnSummary}
                </span>
            }>
                <NumberField fullWidth integer size="small" 
                    label={data.overrideDamagePerTurn ? "Damage Per Turn (Custom)" : "Damage Per Turn"}
                    value={data.overrideDamagePerTurn ?? offensiveMetrics.damagePerTurn.toString()}
                    onChange={(e, v) => update({overrideDamagePerTurn: v || undefined})}
                    InputProps={{
                        endAdornment: data.overrideDamagePerTurn && 
                            <InputAdornment position="end">
                                <IconButton onClick={() => update({overrideDamagePerTurn: undefined})}>
                                    <ResetIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                    }} 
                />
            </Tooltip>
            <Tooltip title={(!data.overridePerTurn && "Average of attack bonus and spellcasting bonus based on contribution to damage output.")}>
                <NumberField fullWidth integer size="small" 
                    label={data.overrideAttackBonus ? "Attack Bonus (Custom)" : "Attack Bonus"}
                    value={data.overrideAttackBonus ?? offensiveMetrics.attackBonus.toString()}
                    onChange={(e, v) => update({overrideAttackBonus: v || undefined})}
                    InputProps={{
                        endAdornment: data.overrideAttackBonus && 
                            <InputAdornment position="end">
                                <IconButton onClick={() => update({overrideAttackBonus: undefined})}>
                                    <ResetIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                    }} 
                />
            </Tooltip>
            <Typography>Offensive CR <b>{challengeRatingToString(offensiveCR)}</b></Typography>
            <Divider />
            <Tooltip 
                title={<span style={{whiteSpace: 'pre-line' }}>{(!data.overrideEffectiveHP) && defensiveMetrics.effectiveHitPointSummary}</span>
                }
            >
                <NumberField fullWidth integer size="small" 
                    label={data.overrideEffectiveHP ? "Effective Hit Points (Custom)" : "Effective Hit Points"}
                    value={data.overrideEffectiveHP ?? defensiveMetrics.effectiveHitPoints.toString()}
                    onChange={(e, v) => update({overrideEffectiveHP: v || undefined})}
                    InputProps={{
                        endAdornment: data.overrideEffectiveHP && 
                            <InputAdornment position="end">
                                <IconButton onClick={() => update({overrideEffectiveHP: undefined})}>
                                    <ResetIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                    }} 
                />
            </Tooltip>
            <Tooltip 
                title={<span style={{whiteSpace: 'pre-line' }}>{(!data.overrideEffectiveAC) && defensiveMetrics.effectiveArmorClassSummary}</span>
                }
            >
            <NumberField fullWidth integer size="small"
                    label={data.overrideEffectiveAC ? "Effective Armor Class (Custom)" : "Effective Armor Class"}
                    value={data.overrideEffectiveAC ?? defensiveMetrics.effectiveArmorClass.toString()}
                    onChange={(e, v) => update({overrideEffectiveAC: v || undefined})}
                    InputProps={{
                        endAdornment: data.overrideEffectiveAC && 
                            <InputAdornment position="end">
                                <IconButton onClick={() => update({overrideEffectiveAC: undefined})}>
                                    <ResetIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                    }} 
                />
            </Tooltip>
            <Typography sx={{minWidth: '30%'}}>Defensive CR <b>{challengeRatingToString(defensiveCR)}</b></Typography>
            <Divider />
            <Tooltip title="Modifier to suggested CR that comes from traits and actions.">
                <TextField fullWidth disabled size="small" label="CR Adjustment"
                    value={(crAdjustment ?? 0).toString()} 
                />
            </Tooltip>
            <Typography sx={{minWidth: '30%'}}>Final CR <b>{finalCRString}</b></Typography>
        </Stack>    
    );
});

const tagSuggestions = [
    'boss',
    'minion',
    'npc'
];

const VitalsEditor = memo(({data, update}) => {
    const recommendedProficiencyBonus = getProficiencyBonusByCR(data.challengeRating ?? 0);

    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
                <TextField label="Name" fullWidth required autoComplete="off" value={data.name ?? ''} onChange={(e) => update({name: e.target.value})} />
                <TextField label="Noun" fullWidth autoComplete="off"
                        value={data.noun ?? ''}
                        onChange={(e) => update({noun: e.target.value || undefined})}
                    />
            </Stack>
            <Stack direction="row" spacing={2}>
                <ComboBox label="Type" fullWidth options={creatureTypeSuggestions} value={data.type} onChange={(e, v) => update({type: v})} />
                <ComboBox label="Subtype" fullWidth options={creatureSubtypeSuggestions} value={data.subtype} onChange={(e, v) => update({subtype: v})} />
                <ComboBox label="Alignment" fullWidth options={monsterAlignmentSuggestions} value={data.alignment} onChange={(e, v) => update({alignment: v})} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <ComboBox label="Size" fullWidth options={sizeSuggestions} value={data.size} onChange={(e, v) => update({size: v})} />
                <MultiComboBox fullWidth label="Movement" options={speedSuggestions} value={data.speed} onChange={(e, v) => update({speed: v})} />
            </Stack>
            <OutlinedBox label="Ability Scores">
                <AbilityScoresEditor abilityScores={data.abilityScores} update={(v) => update({abilityScores: v})} />
            </OutlinedBox>
            <Stack direction="row" spacing={2}>
                <MultiComboBox fullWidth label="Senses" options={senseSuggestions} value={data.senses} onChange={(e, v) => update({senses: v})} />
                <MultiComboBox fullWidth label="Languages" options={languageSuggestions} value={data.languages} onChange={(e, v) => update({languages: v})} />
            </Stack>
            <TagPicker label="Tags" fullWidth suggestions={tagSuggestions} category="monsters" value={data.tags} onChange={(e, v) => update({tags: v})} />
            <Stack direction="row" spacing={2}>
                <SelectBox label="Challenge Rating" fullWidth
                    options={challengeRatingSuggestions}
                    getOptionLabel={challengeRatingToString}
                    value={data.challengeRating}
                    onChange={(e, v) => update({challengeRating: v})}
                />
                <Tooltip title="Suggested Proficiency Bonus is based on the monster's Challenge Rating.">
                    <NumberField label="Proficiency Bonus" fullWidth integer
                        value={data.proficiencyBonus ?? null} 
                        onChange={(e, v) => update({proficiencyBonus: v})}
                        helperText={`Suggested: ${recommendedProficiencyBonus}`}
                    />
                </Tooltip>
            </Stack>
        </Stack>     
    );    
});

function getTotalHitPoints(hitPoints, conModifier) {
    const n = hitPoints?.n ?? 1;
    const m = hitPoints?.m ?? 0;
    const c = hitPoints?.c ?? 0;
    return makeDiceFormula(n, m, c + (m ? conModifier * n : 0));
}

const DefensesEditor = memo(({data, update, getAbilityScore}) => {
    const conModifier = getAbilityScoreModifier(getAbilityScore('con'));
    const totalHitPointsFormula = getTotalHitPoints(data.hitPoints, conModifier);

    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
                <Tooltip title="Hit points before CON bonus is applied.">
                    <DiceFormulaField label="Hit Points" noMetrics fullWidth
                        value={data.hitPoints ?? null}
                        onChange={(e, v) => update({hitPoints: v})}
                    />
                </Tooltip>
                <Tooltip title="A creature additionally gains hit points equal to its CON modifier for each of its hit dice.">
                    <DiceFormulaField label="Total Hit Points" value={totalHitPointsFormula} fullWidth disabled />
                </Tooltip>
            </Stack>
            <Stack direction="row" spacing={2}>
                <NumberField label="Armor Class" integer value={data.armorClass} onChange={(e, v) => update({armorClass: v})} fullWidth />
                <ComboBox label="Armor Type" options={creatureArmorSuggestions} value={data.armorType} onChange={(e, v) => update({armorType: v})} fullWidth />
            </Stack>
            <MultiComboBox label="Damage Resistances" options={damageTypeResistanceSuggestions} value={data.resistances} onChange={(e, v) => update({resistances: v})} />
            <MultiComboBox label="Damage Immunities" options={damageTypeResistanceSuggestions} value={data.damageImmunities} onChange={(e, v) => update({damageImmunities: v})} />
            <MultiComboBox label="Condition Immunities" options={conditionSuggestions} value={data.conditionImmunities} onChange={(e, v) => update({conditionImmunities: v})} />
            <MultiComboBox label="Damage Vulnerabilities" options={damageTypeSuggestions} value={data.vulnerabilities} onChange={(e, v) => update({vulnerabilities: v})} />
        </Stack>
    );
});

function SingleSavingThrowEditor({label, ability, abilityScore, proficiencyBonus, savingThrow, update}) {
    const total = Math.trunc((abilityScore - 10) / 2) + ((savingThrow.proficient ?? false) ? proficiencyBonus : 0) + (savingThrow.bonus ?? 0);
    const totalString = (total > 0 ? '+' + total : total.toString());

    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell><Checkbox checked={savingThrow.proficient ?? false} onChange={(e) => update({proficient: e.target.checked})} /></TableCell>
            <TableCell><NumberField size="small" integer value={savingThrow.bonus ?? 0} onChange={(e, v) => update({bonus: v})} /></TableCell>
            <TableCell>{totalString}</TableCell>
        </TableRow>
    );
}

const SavingThrowsEditor = memo(({data, update, getAbilityScore, proficiencyBonus}) => {
    const updateSavingThrow = useCallback((ability, fields) => {
        update({savingThrows: {...data.savingThrows, [ability]: {...data.savingThrows[ability], ...fields}}});
    }, [data.savingThrows, update]);

    const getSavingThrow = useCallback((ability) => {
        return data.savingThrows[ability] ?? {};
    }, [data.savingThrows]);

    return (
        <Stack spacing={2}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Ability</TableCell>
                        <TableCell>Proficient</TableCell>
                        <TableCell>Bonus</TableCell> 
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { abilityScoreList.map((a) => <SingleSavingThrowEditor 
                        key={a}
                        savingThrow={getSavingThrow(a)} 
                        proficiencyBonus={proficiencyBonus} 
                        ability={a} 
                        label={getAbilityScoreLabel(a)} 
                        abilityScore={getAbilityScore(a)}
                        update={(v) => updateSavingThrow(a, v)} 
                    />) }
                </TableBody>
            </Table>   
        </Stack>
    );
});

const proficiencyLabels = [
    '',
    '',
    'x2'
];

const unproficientIcon = <RadioUncheckedIcon />;
const proficientIcon = <RadioCheckedIcon />
const expertIcon = <RadioFilledIcon />;

function SkillProficiency({data, update}) {
    const handleChange = useCallback(() => {
        let newProficiency = (data.proficiency ?? 0) + 1;
        if (newProficiency > 2) {
            newProficiency = 0;
        }
        update({proficiency: newProficiency});
    }, [data.proficiency, update]);

    return (
        <FormControlLabel 
            label={proficiencyLabels[data.proficiency ?? 0]}
            control={
                <Checkbox 
                    icon={unproficientIcon}
                    indeterminateIcon={proficientIcon}
                    checkedIcon={expertIcon}
                    checked={data.proficiency === 2} 
                    indeterminate={data.proficiency === 1}
                    onChange={handleChange}
                />
            }
        />
    );
}

function SingleSkillEditor({label, abilityScore, proficiencyBonus, data, update}) {
    const total = getAbilityScoreModifier(abilityScore) + (proficiencyBonus * (data.proficiency ?? 0)) + (data.bonus ?? 0);
    const totalString = (total > 0 ? '+' + total : total.toString());

    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell><SkillProficiency data={data} update={update} /></TableCell>
            <TableCell><NumberField size="small" integer value={data.bonus ?? 0} onChange={(e, v) => update({bonus: v})} /></TableCell>
            <TableCell>{totalString}</TableCell>
        </TableRow>
    );
}

const SkillsEditor = memo(({data, update, getAbilityScore, proficiencyBonus}) => {   
    const updateSkill = useCallback((skill, fields) => {
        update({skills: {...data.skills, [skill]: {...data.skills[skill], ...fields}}});
    }, [update, data.skills]);

    const getSkill = useCallback((skill) => {
        return data.skills[skill] ?? {};
    }, [data.skills]);

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Skill</TableCell>
                    <TableCell sx={{minWidth: '150px'}}>Proficiency</TableCell>
                    <TableCell>Additional Bonus</TableCell> 
                    <TableCell>Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { skillList.map((s) => <SingleSkillEditor 
                    label={getSkillLabel(s)}
                    key={s}
                    skill={s}
                    data={getSkill(s)}
                    proficiencyBonus={proficiencyBonus} 
                    abilityScore={getAbilityScore(getSkillAbilityScore(s))}
                    update={(v) => updateSkill(s, v)} 
                />) }
            </TableBody>
        </Table>      
    );
});

function SpellLevelEditor({index, spellLevel, update}) {
    return (
        <TableRow>
            <TableCell>
                {index > 0 ? index : 'Cantrip'}
            </TableCell>
            <TableCell>
                { index > 0 ? 
                    <NumberField integer size="small" fullWidth value={spellLevel.slots} onChange={(e, v) => update({slots: v})}/>
                    :
                    <InfinityIcon />
                }
            </TableCell>
            <TableCell>
                <MultiDocumentPicker freeSolo query={{category: 'spells', level: index}} size="small" fullWidth value={spellLevel.spells ?? []} onChange={(e, v) => update({spells: v})} />
            </TableCell>
        </TableRow>       
    );
}

function AdditionalSpellEditor({index, count, data, update, remove, swap}) {
    return (
        <TableRow>
            <TableCell>
                <ComboBox size="small" fullWidth
                    options={spellLimitSuggestions}
                    value={data.limit}
                    onChange={(e, v) => update(index, {limit: v})}
                />
            </TableCell>
            <TableCell>
                <MultiDocumentPicker freeSolo query={{category: 'spells'}} size="small" 
                    fullWidth value={data.spells ?? []} 
                    onChange={(e, v) => update(index, {spells: v})} 
                />
            </TableCell>
            <TableCell>
                <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                </IconButton>
                { index > 0 && 
                        <IconButton onClick={() => swap(index, index - 1)}>
                            <MoveUpIcon />
                        </IconButton>
                }
                { (index < (count - 1)) &&
                    <IconButton onClick={() => swap(index, index + 1)}>
                        <MoveDownIcon />
                    </IconButton>
                }
            </TableCell>
        </TableRow>         
    );
}

const SpellcastingEditor = memo(({data, update, getAbilityScore, proficiencyBonus, offensiveMetrics}) => {
    const [additionalSpells, addAdditionalSpell, updateAdditionalSpell, removeAdditionalSpell, swapAdditionalSpell, getAdditionalSpellKey] = useStateBlockArray('additionalSpells', data, update);
    
    const updateSpellLevel = useCallback((level, fields) => {
        update({spellLevels: data.spellLevels.map((curValue, i) => (i === level) ? {...curValue, ...fields} : curValue)});
    }, [data.spellLevels, update]);

    const getSpellLevel = useCallback((level) => {
        return data.spellLevels[level] ?? { slots: 0, spells: [] };
    }, [data.spellLevels]);

    let spellcastingModifier = proficiencyBonus;
    if (data.spellcastingAbility) {
        spellcastingModifier += getAbilityScoreModifier(getAbilityScore(data.spellcastingAbility));
    }
    const spellcastingModifierString = (spellcastingModifier > 0 ? '+' + spellcastingModifier : spellcastingModifier.toString());
    const spellcastingDC = 8 + spellcastingModifier + (data.spellcastingBonus ?? 0);

    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
                <SelectBox label="Spellcasting Ability" options={abilityScoreList} value={data.spellcastingAbility} fullWidth
                    getOptionLabel={(a) => getAbilityScoreLabel(a)}
                    onChange={(e, v) => update({spellcastingAbility: v})} />
                <NumberField label="Additional Spellcasting Bonus" integer value={data.spellcastingBonus} onChange={(e, v) => update({spellcastingBonus: v})} fullWidth />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Tooltip title="Modifier applied to spell attack rolls and other spellcasting checks. Equal to ability score modifier plus proficiency bonus, plus any additional bonus provided.">
                    <TextField disabled label="Spellcasting Modifier" value={spellcastingModifierString} fullWidth />                
                </Tooltip>
                <Tooltip title="Save DC for spells. Equal to 8 plus spellcasting modifier.">
                    <TextField disabled label="Spellcasting DC" value={spellcastingDC} fullWidth />
                </Tooltip>
            </Stack>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: 80}}>Level</TableCell>
                        <TableCell sx={{width: 80}}>Slots</TableCell>
                        <TableCell>Spells</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { spellLevelList.map((i) => 
                        <SpellLevelEditor key={i} index={i} spellLevel={getSpellLevel(i)} update={(v) => updateSpellLevel(i, v)} />
                    )}
                </TableBody>
            </Table>      
            <Stack spacing={0}>
                <Typography variant="h3" sx={{display: 'flex', alignItems: 'center'}}>
                    Innate Spellcasting <IconButton onClick={() => addAdditionalSpell({})}><AddIcon /></IconButton>
                </Typography>
                { additionalSpells.length > 0 &&
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width: '30%'}}>Limit</TableCell>
                                <TableCell>Spells</TableCell>
                                <TableCell sx={{width: 'auto'}} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { additionalSpells.map((spellData, index) =>
                                <AdditionalSpellEditor 
                                    key={getAdditionalSpellKey(index)} 
                                    index={index} 
                                    count={additionalSpells.length}
                                    data={spellData} 
                                    update={updateAdditionalSpell} 
                                    remove={removeAdditionalSpell} 
                                    swap={swapAdditionalSpell}
                                /> 
                            )}
                        </TableBody>
                    </Table>      
                }
            </Stack>
        </Stack> 
    );
});

const SingleTraitEditor = memo(({data, index, count, update, remove, swap}) => {
    return (
        <Stack spacing={2} padding={2} component={Paper} elevation={2}>
            <Stack direction="row" spacing={1}>
                <TextField size="small" label="Name" fullWidth autoComplete="off"
                    value={data.name ?? ''}
                    onChange={(e) => update(index, {name: e.target.value})}
                />
                <Tooltip title="Adjusts the suggested CR for this monster (typically up.) May be a fractional value such as 0.5.">
                    <NumberField size="small" label="CR Adjustment" sx={{width: '30%'}} integer
                        value={data.crAdjustment ?? 0}
                        onChange={(e, v) => update(index, {crAdjustment: v})}
                    />
                </Tooltip>
                <Stack direction="row" spacing={0}>
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                    { index > 0 && 
                        <IconButton onClick={() => swap(index, index - 1)}>
                            <MoveUpIcon />
                        </IconButton>
                    }
                    { (index < (count - 1)) &&
                        <IconButton onClick={() => swap(index, index + 1)}>
                            <MoveDownIcon />
                        </IconButton>
                    }
                </Stack>
            </Stack>
            <MarkdownField label="Description" fullWidth rows={4}
                value={data.description ?? ''}
                onChange={(v) => update(index, {description: v})}
            />
        </Stack>
    )
});

function ImportFeatureDialog({close, add, featureTypes}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [loadedFeatureId, setLoadedFeatureId] = useState(null);
    const [featureData, setFeatureData] = useState();

    const submit = useCallback(() => {
        add({
            name: featureData.name,
            crAdjustment: featureData.cr ?? null,
            description: featureData.description ?? null,
            damage: featureData.damage ?? null,
            limit: featureData.limit ?? null,
            type: featureData.type ?? null,
            actionsUsed: featureData.legendaryActions ?? null
        });
        close();
    }, [add, close, featureData]);

    const fetchFeatureData = useCallback(async (id) => {
        setIsLoading(true);
        const res = await getDocument(id, false);
        if (res.result === 'success') {
            setError();
            setLoadedFeatureId(id);
            setFeatureData(MonsterFeature.deserialize(res));
        }
        else {
            setError('Failed to retrieve document.');
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!isLoading && !error && selectedFeature?.id !== loadedFeatureId) {
            if (selectedFeature?.id) {
                fetchFeatureData(selectedFeature.id);
            }
            else {
                setLoadedFeatureId(null);
                setFeatureData(null);
            }
        }   
    }, [isLoading, error, selectedFeature, loadedFeatureId, fetchFeatureData]);

    return (
        <CommonDialog title="Import Feature" close={close}>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography>
                        Import a premade <Link href="/browse/monster-features">monster feature</Link>. After importing, you can modify it to fit your monster.
                    </Typography>
                    <DocumentPicker label="Monster Feature"
                        query={{category: 'monster-features', 'data.featureType:in': featureTypes}}
                        value={selectedFeature}
                        onChange={(e, v) => setSelectedFeature(v)} />
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
                    {(featureData && !isLoading) &&
                        <Section label={featureData.name} variant="label2">
                            <MarkdownViewer inline text={featureData.description} />
                        </Section>
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <SubmitButton onClick={submit} loading={isLoading} disabled={!featureData || error}>Import</SubmitButton>
            </DialogActions>
        </CommonDialog>
    );
}

const TraitsEditor = memo(({data, update}) => {
    const [traits, addTrait, updateTrait, removeTrait, swapTrait, getTraitKey] = useStateBlockArray('traits', data, update);
    const [isImportDialogOpen, setImportDialogOpen] = useState(false);

    return (
        <Stack spacing={2}>
            { isImportDialogOpen && <ImportFeatureDialog close={() => setImportDialogOpen(false)} add={addTrait} featureTypes={["trait"]} /> }
            <Stack direction="row" spacing={2}>
                <Button variant="outlined"
                    startIcon={<AddIcon />} 
                    onClick={() => addTrait({})}
                >
                    New Trait
                </Button>
                <Button variant="outlined"
                    startIcon={<ImportIcon />}
                    onClick={() => setImportDialogOpen(true)}
                >
                    Import Trait
                </Button>
            </Stack>
            { traits.map((trait, index) => 
                <SingleTraitEditor key={getTraitKey(index)} data={trait} index={index} count={data.traits.length} update={updateTrait} remove={removeTrait} swap={swapTrait} />
            )}
        </Stack>
    )
});

const attackSaveTypes = ['none', 'half', 'full'];
const attackSaveTypeLabels = {
    none: 'none',
    half: 'half damage',
    full: 'no damage'
};

function getAttackSaveTypeLabel(attackSaveType) {
    return attackSaveTypeLabels[attackSaveType] ?? 'Unknown';
}

function getEffectTotalDamage(effect, abilityScoreModifier) {
    return makeDiceFormula(
        (effect.baseDamage?.n ?? 0), 
        (effect.baseDamage?.m ?? 0), 
        (effect.baseDamage?.c ?? 0) + (effect.ignoreAbilityModifier ? 0 : (abilityScoreModifier ?? 0)));
}

const SingleAttackEffectEditor = memo(({data, index, update, remove, abilityScoreModifier}) => {
    const totalDamage = getEffectTotalDamage(data, abilityScoreModifier);
    const hasSave = data.saveType && data.saveType !== 'none';

    return (
        <Stack spacing={2} component={Paper} elevation={3} padding={2}>
            <Stack direction="row" spacing={1}>
                <DiceFormulaField size="small" label="Base Damage" fullWidth noMetrics
                    value={data.baseDamage}
                    onChange={(e, v) => update(index, {baseDamage: v})} />
                <DiceFormulaField size="small" label="Total Damage" fullWidth disabled
                    value={totalDamage}
                />
                <FormControlLabel label="Include Ability Modifier" sx={{width: '100%'}}
                    control={
                        <Switch checked={!data.ignoreAbilityModifier} onChange={(e) => update(index, {ignoreAbilityModifier: !e.target.checked})} />
                    }
                />
                <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                </IconButton>         
            </Stack>
            <Stack direction="row" spacing={1}>
                <ComboBox size="small" label="Damage Type" fullWidth
                    options={damageTypeSuggestions}
                    value={data.damageType}
                    onChange={(e, v) => update(index, {damageType: v})}
                />      
                <SelectBox size="small" label="Saving Throw" fullWidth
                    options={attackSaveTypes}
                    getOptionLabel={getAttackSaveTypeLabel}
                    value={data.saveType ?? 'none'}
                    onChange={(e, v) => update(index, {saveType: v})}
                />
            </Stack>
            { hasSave &&
                <Stack direction="row" spacing={1}>
                    <SelectBox size="small" label="Save Ability" fullWidth
                        options={abilityScoreList}
                        getOptionLabel={getAbilityScoreLabel}
                        value={data.saveAbility ?? null}
                        onChange={(e, v) => update(index, {saveAbility: v})}
                    />
                    <NumberField size="small" label="Save DC" fullWidth integer
                        value={data.saveDC ?? null}
                        onChange={(e, v) => update(index, {saveDC: v})}
                    />
                </Stack>
            }
        </Stack>
    );
});

const defaultEffect = { };

const SingleActionEditor = memo(({data, index, count, update, remove, swap, getAbilityScore, proficiencyBonus}) => {
    function addEffect() {
        update(index, {effects: [...(data.effects ?? []), 
            {...defaultEffect, ignoreAbilityModifier: true}]});
    }
    function updateEffect(effectIndex, fields) {
        update(index, {effects: data.effects.map((e, i) => i === effectIndex ? {...e, ...fields} : e)});
    }
    function removeEffect(effectIndex) {
        update(index, {effects: data.effects.filter((e, i) => i !== effectIndex)});
    }
    
    const isAttack = data.type === 'attack';
    let toHit, abilityScoreModifier;

    if (isAttack) {
        toHit = (proficiencyBonus ?? 0);
        if (data.abilityScore) {
            abilityScoreModifier = getAbilityScoreModifier(getAbilityScore(data.abilityScore));
            toHit += abilityScoreModifier;
        }
        toHit += (data.attackBonus ?? 0);
    }

    return (
        <Stack spacing={2} padding={2} component={Paper} elevation={2}>
            <Stack direction="row" spacing={1}>
                <TextField size="small" label="Name" fullWidth autoComplete="off"
                    value={data.name ?? ''}
                    onChange={(e) => update(index, {name: e.target.value})}
                />
                <SelectBox size="small" label="Type" fullWidth
                    value={data.type ?? null}
                    options={actionTypeList}
                    getOptionLabel={getActionTypeLabel}
                    onChange={(e, v) => update(index, {type: v})}
                />
                <Stack direction="row" spacing={0}>
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                    { index > 0 && 
                        <IconButton onClick={() => swap(index, index - 1)}>
                            <MoveUpIcon />
                        </IconButton>
                    }
                    { (index < (count - 1)) &&
                        <IconButton onClick={() => swap(index, index + 1)}>
                            <MoveDownIcon />
                        </IconButton>
                    }
                </Stack>
            </Stack>
            { isAttack &&
                <>
                    <Stack direction="row" spacing={1}>
                        <SelectBox size="small" label="Attack Type" fullWidth
                            value={data.attackType ?? null}
                            options={attackTypeList}
                            getOptionLabel={getAttackTypeLabel}
                            onChange={(e, v) => update(index, {attackType: v})}
                        />
                        <ComboBox size="small" label="Attack Range" fullWidth
                            value={data.range ?? null}
                            options={weaponRangeSuggestions}
                            onChange={(e, v) => update(index, {range: v})}
                        />
                    </Stack>                
                    <Stack direction="row" spacing={1}>
                        <SelectBox size="small" label="Ability Score" fullWidth
                            value={data.abilityScore ?? null}
                            options={abilityScoreList}
                            getOptionLabel={getAbilityScoreLabel}
                            onChange={(e, v) => update(index, {abilityScore: v})}
                        />
                        <Tooltip title="Extra bonus to attack rolls on top of proficiency bonus and ability modifier. This is usually left at 0.">
                            <NumberField size="small" label="Additional Bonus to Hit" fullWidth integer
                                value={data.attackBonus ?? 0}
                                onChange={(e, v) => update(index, {attackBonus: v})}
                            />
                        </Tooltip>
                        <Tooltip title="Modifier to hit rolls, equal to proficiency bonus plus ability modifier and any additional bonus.">
                            <TextField label="Attack Bonus" size="small" fullWidth disabled value={toHit} />
                        </Tooltip>
                    </Stack>
                    <OutlinedBox
                        label={
                            <Stack direction="row" spacing={0} alignItems="center" marginTop="-5px">
                                <span>Effects</span>
                                <IconButton size="small" sx={{marginTop: "-2px"}} onClick={(e) => addEffect()}>
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                            </Stack>
                    }>
                        <Stack spacing={2} sx={{padding: 1, width: '100%'}}>
                            { (data.effects ?? []).map((effect, effectIndex) => 
                                <SingleAttackEffectEditor 
                                    key={effectIndex} 
                                    data={effect} 
                                    index={effectIndex} 
                                    update={updateEffect} 
                                    remove={removeEffect}
                                    abilityScoreModifier={abilityScoreModifier} 
                                />
                            )}
                        </Stack>
                    </OutlinedBox>
                    <Stack direction="row" spacing={1}>
                        <ComboBox size="small" label="Limit" fullWidth
                            value={data.limit ?? null}
                            options={actionLimitSuggestions}
                            onChange={(e, v) => update(index, {limit: v})}
                        />
                        <Tooltip title="Max number of times this attack can be used as part of the creature's Multiattack action (leave blank if no limit, or set to 0 if this attack is not part of the creature's Multiattack action.)">
                            <NumberField size="small" label="Multiattack Limit" fullWidth
                                value={data.multiattackLimit ?? null}
                                onChange={(e, v) => update(index, {multiattackLimit: v})}
                            />
                        </Tooltip>
                        <Tooltip title="Adjusts the suggested CR for this monster (typically up.) May be a fractional value such as 0.5.">
                            <NumberField size="small" label="CR Adjustment" fullWidth
                                value={data.crAdjustment ?? null}
                                onChange={(e, v) => update(index, {crAdjustment: v})}
                            />
                        </Tooltip>
                    </Stack>                     
                    <MarkdownField label="Additional Effects" fullWidth rows={1}
                        value={data.description ?? ''}
                        onChange={(v) => update(index, {description: v})}
                    />
                </>
            }
            { !isAttack &&
                <>
                    <Stack direction="row" spacing={1}>
                        <ComboBox size="small" label="Limit" fullWidth
                            value={data.limit ?? null}
                            options={actionLimitSuggestions}
                            onChange={(e, v) => update(index, {limit: v})}
                        />
                       <Tooltip title="Average damage output for this action, used only for calculating CR. May be a dice formula or a number.">
                            <DiceFormulaField size="small" label="Est. Damage" fullWidth
                                value={data.damage ?? null}
                                onChange={(e, v) => update(index, {damage: v})}
                            />
                        </Tooltip>
                        <Tooltip title="Adjusts the suggested CR for this monster (typically up.) May be a fractional value such as 0.5.">
                            <NumberField size="small" label="CR Adjustment" fullWidth
                                value={data.crAdjustment ?? null}
                                onChange={(e, v) => update(index, {crAdjustment: v})}
                            />
                        </Tooltip>
                    </Stack>    
                    <MarkdownField label="Description" fullWidth rows={4}
                        value={data.description ?? ''}
                        onChange={(v) => update(index, {description: v})}
                    />
                </>
            }
        </Stack>
    )  
});

const defaultAction = { 
    type: 'action', 
    attackType: 'meleeWeapon', 
    effects: [defaultEffect], 
    description: '', 
    abilityScore: 'str', 
    range: '5 ft.'
 };

const ActionsEditor = memo(({data, update, getAbilityScore, proficiencyBonus}) => {
    const [actions, addAction, updateAction, removeAction, swapAction, getActionKey] = useStateBlockArray('actions', data, update);
    const [isImportDialogOpen, setImportDialogOpen] = useState(false);

    return (
        <Stack spacing={2}>
            { isImportDialogOpen && <ImportFeatureDialog close={() => setImportDialogOpen(false)} add={addAction} featureTypes={["action","bonusAction","reaction"]} /> }
            <Stack direction="row" spacing={2}>
                <Tooltip title="Used to estimate damage per turn, and will generate a basic Multiattack action if one is not provided.">
                    <NumberField label="Attacks Per Turn" fullWidth integer
                        value={data.attacksPerTurn ?? 1}
                        onChange={(e, v) => update({attacksPerTurn: v})}
                    />
                </Tooltip>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Button variant="outlined"
                    startIcon={<AddIcon />} 
                    onClick={() => addAction(defaultAction)}
                >
                    New Action
                </Button>
                <Button variant="outlined"
                    startIcon={<ImportIcon />}
                    onClick={() => setImportDialogOpen(true)}
                >
                    Import Action
                </Button>
            </Stack>
            { (actions ?? []).map((action, index) => 
                <SingleActionEditor 
                    key={getActionKey(index)} 
                    data={action} 
                    index={index}
                    count={actions.length}
                    update={updateAction} 
                    remove={removeAction}
                    swap={swapAction}
                    getAbilityScore={getAbilityScore} 
                    proficiencyBonus={proficiencyBonus} 
                />
            )}
        </Stack>
    )
});

const SingleLegendaryActionEditor = memo(({data, index, count, update, remove, swap}) => {
    return (
        <Stack spacing={2} padding={2} component={Paper} elevation={2}>
            <Stack direction="row" spacing={1}>
                <TextField size="small" label="Name" fullWidth autoComplete="off"
                    value={data.name ?? ''}
                    onChange={(e) => update(index, {name: e.target.value})}
                />
                <NumberField size="small" label="Legendary Actions Used" sx={{width: '50%'}} integer
                    value={data.actionsUsed}
                    onChange={(e, v) => update(index, {actionsUsed: v})}
                />
                <Stack direction="row" spacing={0}>
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                    { index > 0 && 
                        <IconButton onClick={() => swap(index, index - 1)}>
                            <MoveUpIcon />
                        </IconButton>
                    }
                    { (index < (count - 1)) &&
                        <IconButton onClick={() => swap(index, index + 1)}>
                            <MoveDownIcon />
                        </IconButton>
                    }
                </Stack>
            </Stack>
            <MarkdownField label="Description" fullWidth rows={4}
                    value={data.description ?? ''}
                    onChange={(v) => update(index, {description: v})}
                />            
        </Stack>
    );
});

const defaultLegendaryAction = {
    name: '',
    description: '',
    actionsUsed: 1
};

const LegendaryEditor = memo(({data, update}) => {
    const [isImportDialogOpen, setImportDialogOpen] = useState(false);
    const [actions, addAction, updateAction, removeAction, swapAction, getActionKey] = useStateBlockArray('legendaryActions', data, update);

    return (
        <Stack spacing={2}>
            { isImportDialogOpen && <ImportFeatureDialog close={() => setImportDialogOpen(false)} add={addAction} featureTypes={["legendaryAction"]} /> }
            <Stack direction="row" spacing={2}>
                <NumberField label="Legendary Actions / Turn" fullWidth integer
                    value={data.legendaryActionUses ?? null}
                    onChange={(e, v) => update({legendaryActionUses: v})}
                />
                <NumberField label="Legendary Resistances / Day" fullWidth integer
                    value={data.legendaryResistances ?? null}
                    onChange={(e, v) => update({legendaryResistances: v})}
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Button variant="outlined"
                    startIcon={<AddIcon />} 
                    onClick={() => addAction(defaultLegendaryAction)}
                >
                    New Legendary Action
                </Button>
                <Button variant="outlined"
                    startIcon={<ImportIcon />}
                    onClick={() => setImportDialogOpen(true)}
                >
                    Import Legendary Action
                </Button>
            </Stack>
            { actions.map((action, index) => 
                <SingleLegendaryActionEditor 
                    key={getActionKey(index)} 
                    data={action} 
                    index={index} 
                    count={actions.length}
                    update={updateAction} 
                    remove={removeAction}
                    swap={swapAction}
                />
            )}
        </Stack>
    )
});

const SingleLairActionEditor = memo(({data, index, count, update, remove, swap}) => {
    return (
        <Stack spacing={2} padding={2} component={Paper} elevation={2}>
            <Stack direction="row" spacing={1}>
                <TextField size="small" label="Name" fullWidth autoComplete="off"
                    value={data.name ?? ''}
                    onChange={(e) => update(index, {name: e.target.value})}
                />
                <Stack direction="row" spacing={0}>
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                    { index > 0 && 
                        <IconButton onClick={() => swap(index, index - 1)}>
                            <MoveUpIcon />
                        </IconButton>
                    }
                    { (index < (count - 1)) &&
                        <IconButton onClick={() => swap(index, index + 1)}>
                            <MoveDownIcon />
                        </IconButton>
                    }
                </Stack>
            </Stack>
            <MarkdownField label="Description" fullWidth rows={4}
                    value={data.description ?? ''}
                    onChange={(v) => update(index, {description: v})}
                />            
        </Stack>
    );
});

const LairEditor = memo(({data, update, name}) => {
    const [isImportDialogOpen, setImportDialogOpen] = useState(false);
    const [lairActions, addAction, updateAction, removeAction, swapAction, getActionKey] = useStateBlockArray('lairActions', data, update);

    const getGenerationPrompt = useCallback(() => `Describe the lair of a ${name} including any regional effects.`, [name]);

    return (
        <Stack spacing={2}>
            { isImportDialogOpen && <ImportFeatureDialog close={() => setImportDialogOpen(false)} add={addAction} featureTypes={["lairAction"]} /> }
            <Stack direction="row" spacing={2}>
            <Tooltip title="Additional rules governing the use of lair actions.">
                    <TextField label="Lair Action Rules" fullWidth
                        value={data.lairActionRules ?? ''}
                        onChange={(e) => update({lairActionRules: e.target.value})}
                    />
                </Tooltip>
                <NumberField label="Lair Action Initiative" sx={{width: '30%'}} integer
                    value={data.lairActionInitiative ?? 0}
                    onChange={(e, v) => update({lairActionInitiative: v})}
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Button variant="outlined"
                    startIcon={<AddIcon />} 
                    onClick={() => addAction({})}
                >
                    New Lair Action
                </Button>
                <Button variant="outlined"
                    startIcon={<ImportIcon />}
                    onClick={() => setImportDialogOpen(true)}
                >
                    Import Lair Action
                </Button>
            </Stack>
            { lairActions.map((action, index) => 
                <SingleLairActionEditor 
                    key={getActionKey(index)} 
                    data={action} 
                    index={index} 
                    count={data.lairActions.length}
                    update={updateAction} 
                    remove={removeAction}
                    swap={swapAction}
                />
            )}
            <MarkdownField label="Lair Description" rows={12}
                value={data.lair} 
                getGenerationPrompt={getGenerationPrompt}
                onChange={(v) => update({lair: v})} 
            />               
        </Stack>
    )
});

const DescriptionEditor = memo(({data, name, update}) => {
    const getAppearanceGenerationPrompt = useCallback(() => {
        return `Describe the appearance of a ${name}.`;
    }, [name]);
    const getBehaviorGenerationPrompt = useCallback(() => {
        return `Describe the behavior and combat tactics of a ${name}.`;
    }, [name]);
    const getLoreGenerationPrompt = useCallback(() => {
        return `Provide some background lore and history for ${name}s.`;
    }, [name]);

    return (
        <Stack spacing={2}>
            <ImagePicker data={data} update={update} size="small" />
            <MarkdownField label="Appearance" 
                value={data.appearance} 
                getGenerationPrompt={getAppearanceGenerationPrompt} 
                onChange={(v) => update({appearance: v})} 
                rows={12}
            />
            <MarkdownField label="Behavior" 
                value={data.behavior} 
                getGenerationPrompt={getBehaviorGenerationPrompt} 
                onChange={(v) => update({behavior: v})} 
                rows={12}
            />
            <MarkdownField label="Lore" 
                value={data.lore} 
                getGenerationPrompt={getLoreGenerationPrompt} 
                onChange={(v) => update({lore: v})} 
                rows={12}
            />                                    
        </Stack>       
    );
});

function ApplyTemplateDialog({close, data, update}) {
    const [template, setTemplate] = useState(null);
    const [overwrite, setOverwrite] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState();

    const canApply = (template && !error);

    const submit = useCallback(async () => {
        setLoading(true);
        const res = await getDocument(template.id);
        if (res.result === 'success') {
            setError();
            const templateData = await Monster.deserialize(res);
            update(() => Monster.applyTemplate(data, templateData, overwrite));
            close();
        }
        else {
            setError('Failed to load template.');
        }
        setLoading(false);
    }, [data, overwrite, template, update, close]);

    return (
        <CommonDialog title="Apply Template" close={close}>
            <DialogContent>
                <Stack spacing={2}>
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                    <DocumentPicker label="Template" query={{type:'monster-template'}}
                        value={template}
                        onChange={(e, v) => setTemplate(v)} 
                    />
                    <FormControlLabel label="Overwrite existing data when merging isn't possible"
                        control={<Switch value={overwrite} onChange={(e) => setOverwrite(e.target.checked)} />}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <SubmitButton loading={isLoading} disabled={!canApply} onClick={submit}>Apply</SubmitButton>
            </DialogActions>
        </CommonDialog>
    );
}

const steps = [
    { label: 'Vitals' },
    { label: 'Defenses' },
    { label: 'Saving Throws' },
    { label: 'Skills' },
    { label: 'Spellcasting' },
    { label: 'Traits' },
    { label: 'Actions' },
    { label: 'Legendary Features' },
    { label: 'Lair' },
    { label: 'Description' },
];

function MonsterEditor({data, update, template}) {
    const [vitals, updateVitals] = useStateBlockObject('vitals', data, update);
    const [challenge, updateChallenge] = useStateBlockObject('challenge', data, update);
    const [defenses, updateDefenses] = useStateBlockObject('defenses', data, update);
    const [savingThrows, updateSavingThrows] = useStateBlockObject('savingThrows', data, update);
    const [skills, updateSkills] = useStateBlockObject('skills', data, update);
    const [spellcasting, updateSpellcasting] = useStateBlockObject('spellcasting', data, update);
    const [traits, updateTraits] = useStateBlockObject('traits', data, update);
    const [actions, updateActions] = useStateBlockObject('actions', data, update);
    const [legendary, updateLegendary] = useStateBlockObject('legendary', data, update);
    const [lair, updateLair] = useStateBlockObject('lair', data, update);
    const [description, updateDescription] = useStateBlockObject('description', data, update);
    const [step, setStep] = useState(0);
    const [isTemplateDialogOpen, setTemplateDialogOpen] = useState(false);
    const [isCalculatorOpen, setCalculatorOpen] = useState(false);

    const getAbilityScore = useCallback((ability) => {
        return vitals.abilityScores[ability] ?? 10;
    }, [vitals.abilityScores]);    

    return (
        <>
            { isTemplateDialogOpen && <ApplyTemplateDialog close={() => setTemplateDialogOpen(false)} data={data} update={update} />}
            <Stack direction="row" spacing={2} mt={2} width="100%" sx={{alignItems: 'flex-start'}}>
                <Stack width="220px" spacing={2} sx={{justifyContent: 'center'}}>
                    <Stepper activeStep={step} nonLinear orientation="vertical">
                        { steps.map((step, index) =>
                            <Step key={step.label}>
                                <StepButton onClick={() => setStep(index)}>{step.label}</StepButton>
                            </Step>
                        )}
                    </Stepper>
                    { !template &&
                        <>
                            <Button variant="outlined" onClick={() => setTemplateDialogOpen(true)}>
                                Apply Template
                            </Button>
                            <Button variant="outlined" onClick={() => setCalculatorOpen(true)}>
                                CR Calculator
                            </Button>
                        </>
                    }
                </Stack>
                <Box width="100%" padding={1} pb={8}>
                    { step === 0 && <VitalsEditor data={vitals} update={updateVitals} /> }
                    { step === 1 && <DefensesEditor data={defenses} update={updateDefenses} getAbilityScore={getAbilityScore} />}
                    { step === 2 && <SavingThrowsEditor data={savingThrows} update={updateSavingThrows} getAbilityScore={getAbilityScore} proficiencyBonus={vitals.proficiencyBonus ?? 0} /> }
                    { step === 3 && <SkillsEditor data={skills} update={updateSkills} getAbilityScore={getAbilityScore} proficiencyBonus={vitals.proficiencyBonus ?? 0} /> }
                    { step === 4 && <SpellcastingEditor data={spellcasting} update={updateSpellcasting} getAbilityScore={getAbilityScore} proficiencyBonus={vitals.proficiencyBonus ?? 0} /> }
                    { step === 5 && <TraitsEditor data={traits} update={updateTraits} /> }
                    { step === 6 && <ActionsEditor data={actions} update={updateActions} getAbilityScore={getAbilityScore} proficiencyBonus={vitals.proficiencyBonus ?? 0} /> }
                    { step === 7 && <LegendaryEditor data={legendary} update={updateLegendary} /> }
                    { step === 8 && <LairEditor data={lair} update={updateLair} name={vitals.name} /> }
                    { step === 9 && <DescriptionEditor data={description} update={updateDescription} name={vitals.name} /> }
                </Box>               
            </Stack>
            { !template &&
                <PropertiesPanel label="Challenge Rating" width="400px"
                    open={isCalculatorOpen} 
                    onChange={(v) => setCalculatorOpen(v)}>  
                    <ChallengeRatingCalculator 
                        data={challenge} 
                        update={updateChallenge} 
                        actions={actions} 
                        spellcasting={spellcasting} 
                        savingThrows={savingThrows}
                        defenses={defenses}
                        traits={traits}
                        proficiencyBonus={vitals.proficiencyBonus ?? 0}
                        getAbilityScore={getAbilityScore}
                    />
                </PropertiesPanel>
            }
        </>
    );
};

export default MonsterEditor;
