import { useCallback, useState, useEffect } from 'react';
import { Stack, TextField, Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Image from '../common/Image.js';
import CommonDialog from '../common/CommonDialog.js';
import { TagPicker } from '../common/TagPicker.js';
import { UploadImageDialog } from '../app/ImageDropper.js';

export default function ImageAssetEditor({data, update, refresh, documentId}) {
    const [file, setFile] = useState();

    const drop = useCallback((files) => { 
        if (Array.isArray(files)) {
            setFile(files[0]);
        }
        else {
            setFile(files);
        }
    }, []);

    const closeUploadDialog = useCallback(() => {
        setFile();
    }, []);

    const submitUploadDialog = useCallback(({url, thumbnail, altText}) => {
        refresh();
    }, [refresh]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop: drop, maxFiles: 1});

    useEffect(() => {
        function onPaste(e) {
            for (let index in e.clipboardData.items) {
                const item = e.clipboardData.items[index];
                if (item.kind === 'file' &&
                    item.type.startsWith('image/')) {
                    setFile(item.getAsFile());
                }
            }
        }
        window.addEventListener('paste', onPaste);
        return () => window.removeEventListener('paste', onPaste);
    }, []);

    return (
        <Stack spacing={2} mt={2} sx={{width: '100%', alignItems: 'center'}}>
            { file &&
                <CommonDialog title="Upload Image" close={closeUploadDialog}>
                    <UploadImageDialog 
                        file={file} 
                        cancel={closeUploadDialog} 
                        submit={submitUploadDialog} 
                        documentId={documentId} 
                        description={data.description} 
                        tags={data.tags} 
                    />
                </CommonDialog>
            }
            <Stack spacing={2} direction="row" width="100%">
                <Stack spacing={2} width="100%">
                    <TextField fullWidth disabled label="URL" value={data.url ?? ''} />
                    <TextField fullWidth disabled label="Thumbnail URL" value={data.thumbnail ?? ''} />
                </Stack>
                <Box 
                    sx={{
                        display: 'flex', width: '50%', height: '100%', justifyContent: 'center', alignItems: 'center',
                        borderColor: (theme) => isDragActive ? theme.palette.primary : theme.palette.grey[500],
                        borderStyle: 'dashed',
                        borderWidth: '2px',
                        borderRadius: '40px'
                    }} 
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <Typography>Drop image here, or click to browse.</Typography>
                </Box>
            </Stack>
            <TextField fullWidth label="Description"
                value={data.description}
                onChange={(e) => update({description: e.target.value})}
            />
            <TagPicker fullWidth label="Tags"
                category="assets"
                value={data.tags}
                onChange={(e, v) => update({tags: v})}
            />
            <Image src={data.url} alt={data.description} sx={{maxWidth: '100%', maxHeight: '512px'}} />
        </Stack>
    )
}
