import { useState, useEffect, useCallback, memo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Stack, Typography, Alert, Tooltip } from '@mui/material';
import { Close as CloseIcon, DriveFileMove as MoveIcon } from '@mui/icons-material';
import { SelectBox } from '../common/SelectBox.js';
import CommonDialog from '../common/CommonDialog.js';
import SubmitButton from '../common/SubmitButton.js';
import { useTitleBar } from '../../globals.js';
import { listRepositories, moveDocument } from '../../api.js';

function MoveDocumentDialog({title, documentId, repository: fromRepository, close}) {
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [repository, setRepository] = useState();
    const [repositoryList, setRepositoryList] = useState();

    const fetchRepositoryList = useCallback(async () => {
        setIsLoading(true);
        const res = await listRepositories();
        if (res.result === 'success') {
            setError();
            const repositoryList = res.repositories?.filter((r) => r.id !== fromRepository) ?? [];
            setRepositoryList(repositoryList);
            setRepository(repositoryList?.[0]);
        }
        else {
            setError('Failed to fetch repositories.');
        }
        setIsLoading(false);
    }, [fromRepository]);

    const getRepositoryLabel = useCallback((repository) => repository.title, []);

    const submit = useCallback(async () => {
        setIsLoading(true);
        const res = await moveDocument(documentId, repository.id);
        if (res.result === 'success') {
            setError();
            close();
        }
        else {
            setError('Failed to move document.');
        }
        setIsLoading(false);
    }, [repository, documentId, close]);

    useEffect(() => {
        fetchRepositoryList();
    }, [fetchRepositoryList]);

    const canSubmit = !isLoading && repository;

    return (
        <CommonDialog close={close} title="Move Document">
            <DialogContent>
                <Stack spacing={2}>
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                    <Typography>This will move {title} to a new repository.</Typography>
                    <SelectBox fullWidth    
                        value={repository ?? null}
                        options={repositoryList ?? []}
                        getOptionLabel={getRepositoryLabel}
                        loading={repositoryList && true}
                        onChange={(e, v) => setRepository(v)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <SubmitButton onClick={submit} disabled={!canSubmit} loading={isLoading}>Move</SubmitButton>
            </DialogActions>
        </CommonDialog>       
    )
}

const MoveDocument = memo(({title, documentId}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { addWidget, removeWidget } = useTitleBar();

    useEffect(() => {
        if (documentId) {
            addWidget(
                <Tooltip title="Move Document" key="move-document">
                    <IconButton onClick={() => setIsDialogOpen(true)}>
                        <MoveIcon />
                    </IconButton>
                </Tooltip>
            );
            return () => { removeWidget('move-document'); }      
        }
    }, [documentId, addWidget, removeWidget]);  
    
    return <>
        { isDialogOpen && <MoveDocumentDialog title={title} documentId={documentId} close={() => setIsDialogOpen(false)} /> }
    </>;
});

export default MoveDocument;
