import config from '../config.js';

export async function generateText(context, prompt) {
    try {
        let req = { context: context, prompt: prompt };
        
        const response = await fetch(config.apiUrl + '/ai/text', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(req)
        });

        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText };
        }
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}
