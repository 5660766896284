import config from '../config.js';

export async function createDocument(params) {
    try {
        const response = await fetch(config.apiUrl + '/document/create', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(params)
        });

        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}

export async function updateDocument(docId, params) {
    try {
        let req = { documentId: docId, ...params };

        const response = await fetch(config.apiUrl + '/document/update', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(req)
        });
        
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        }       
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    } 
}

export async function listDocuments(filters, sort, order, start, count) {
    try {
        let hasCategoryFilter = false;
        let filterArray = [];
        for (let [key, value] of Object.entries(filters)) {
            if (value != null) {
                if (key === 'category') {
                    hasCategoryFilter = true;
                }
                filterArray.push({ path: key, value: value });
            }
        }

        // by default don't include assets in a document search (this can be overridden by specifically
        // adding a category filter)
        if (!hasCategoryFilter) {
            filterArray.push({ path: 'category:notIn', value: ['assets'] });
        }

        const params = {
            filters: filterArray,
            sort: sort ?? undefined,
            order: (order < 0 ? 'dsc' : 'asc'),
            start: start,
            count: count
        };

        const response = await fetch(config.apiUrl + '/document/list', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(params)
        });

        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    } 
}

export async function getDocument(docId, addToHistory) {
    try {
        let url = `${config.apiUrl}/document/get?id=${docId}`;
        if (addToHistory) {
            url += '&history=1';
        }

        const response = await fetch(url, {
            credentials: 'include'
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }  
}

export async function getTagsInUse(category) {
    try {
        const response = await fetch(config.apiUrl + '/document/tags?category=' + category, {
            credentials: 'include'
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }  
}

export async function deleteDocument(docId) {
    try {
        let req = { documentId: docId };

        const response = await fetch(config.apiUrl + '/document/delete', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(req)
        });
        
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        }       
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    } 
}

export async function cloneDocument(docId, cloneTitle) {
    try {
        let req = { documentId: docId, title: cloneTitle };

        const response = await fetch(config.apiUrl + '/document/clone', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(req)
        });
        
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        }       
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    } 
}

export async function moveDocument(documentId, repositoryId) {
    try {
        let req = { documentId: documentId, repositoryId: repositoryId };

        const response = await fetch(config.apiUrl + '/document/move', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(req)
        });
        
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        }       
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }     
}

export async function favoriteDocument(documentId, toggle) {
    try {
        let url = `${config.apiUrl}/document/favorite?id=${documentId}`;
        if (!toggle) {
            url += '&remove=1';
        }
        const response = await fetch(url, {
            credentials: 'include'
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }      
}

export async function uploadAsset(file, documentId, description, repository, tags, useCompression) {
    try {
        var form = new FormData();
        form.append('metadata', JSON.stringify({ documentId: documentId, description: description, repository: repository, tags: tags ?? [], compress: useCompression }));
        form.append('file', file, file.filename);

        const response = await fetch(config.apiUrl + '/document/upload', {
            method: 'POST',
            body: form,
            credentials: 'include',
        });

        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}
