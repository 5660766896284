import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'; 
import AuthProvider from './components/auth/AuthProvider.js';
import AppRoutes from './components/app/AppRoutes.js';
import ErrorHandler from './components/app/ErrorHandler.js';
import theme from './theme.js';

import './index.css';

const root = createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ErrorHandler>
      <AuthProvider>
          <AppRoutes />
      </AuthProvider>
    </ErrorHandler>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
