import { forwardRef, useId } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { MarkdownEditor } from './MarkdownEditor.js';

const MarkdownField = forwardRef(({variant, label, value, onChange, rows, fullWidth, ...props}, ref) => {
    const id = `component=${useId()}`;

    return (
        <FormControl variant={variant ?? "outlined"} fullWidth={fullWidth}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput id={id} label={label}
                value=''    // tricks it into thinking it is controlled
                sx={{ 
                    padding: 1,
                    minHeight: `${2 + (rows * 1.5)}em`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch'
                }}
                inputComponent={MarkdownEditor}
                inputProps={{ 
                    value: value, 
                    onChange: onChange,
                    ...props
                }}
            />
        </FormControl>
    )
});

export default MarkdownField;
