import { useEffect, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { ContentCopy as CloneIcon } from '@mui/icons-material';
import { useTitleBar } from '../../globals.js';

const CloneDocument = memo(({documentId}) => {
    const { addWidget, removeWidget } = useTitleBar();

    useEffect(() => {
        if (documentId) {
            addWidget(
                <Tooltip title="Clone Document" key="clone-document">
                    <IconButton component={RouterLink} to={`/document/clone/${documentId}`}>
                        <CloneIcon />
                    </IconButton>
                </Tooltip>
            );
            return () => { removeWidget('clone-document'); }      
        }
    }, [documentId, addWidget, removeWidget]);  
    
    return (<>
    </>);
});

export default CloneDocument;

