import { useEffect, useState, useCallback } from 'react';
import { Stack, TextField, Box, Alert, Typography, Divider, Button, InputAdornment, IconButton } from '@mui/material';
import { ContentCopy as CopyIcon } from '@mui/icons-material';
import { useTitleBar, useUserCache, useAuth } from '../../globals.js';
import { useStateBlock } from '../../utils.js';
import { updateUser, getApiKey } from '../../api.js';
import { formatBytes } from '../../utils.js';
import ImagePicker from '../common/ImagePicker.js';
import SubmitButton from '../common/SubmitButton.js';

function ApiKey() {
    const [apiKey, setApiKey] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    const fetchApiKey = useCallback(async (generate) => {
        setIsLoading(true);

        const res = await getApiKey(generate);
        if (res.result === 'success') {
            setError();
            setApiKey(res.apiKey);
        }
        else {
            if (res.reason === 'not_generated') {
                setError();
                setApiKey();
            }
            else {
                setError('An error occurred. Please try again.');
                setApiKey();
            }
        }
        setIsLoading(false);
    }, []);

    const copyToClipboard = useCallback(async () => {
        try {
            if (apiKey) {
                navigator.clipboard.writeText(apiKey);
            }
        }
        catch (error) {
            console.error(error);
        }
    }, [apiKey]);

    useEffect(() => {
        fetchApiKey(false);
    }, []);

    return (
        <Stack spacing={1} sx={{width: '100%'}}>
            <Typography variant="h3">API Key</Typography>
            <Typography>
                An API key allows external services (such as VTTs) limited, read-only access to your documents. 
                Personally identifying information is not shared through API keys.
            </Typography>
            <Alert severity="warning">
                Do not share your API key with anyone else.
                If your API key is compromised, you can generate a new one; this will invalidate old API keys.
            </Alert>
            { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
            <Stack direction="row" spacing={2}>
                <TextField disabled sx={{width: '400px'}}
                    value={isLoading ? 'Loading...' : (apiKey ?? 'No API Key')} 
                    InputProps={{...(!isLoading && {
                        endAdornment: 
                            <InputAdornment position="end">
                                <IconButton onClick={copyToClipboard}>
                                    <CopyIcon />
                                </IconButton>
                            </InputAdornment>
                    })}}
                />
                { !isLoading &&
                    <Button disabled={isLoading} onClick={() => fetchApiKey(true)}>{apiKey ? 'Regenerate' : 'Generate'}</Button>
                }
            </Stack>
        </Stack>
    );
}

function ProfileEditPage({userId}) {
    const { setTitle } = useTitleBar();
    const { localUser, refreshUser } = useUserCache();
    const { isLoggedIn, require: requireAuth } = useAuth();
    const [data, updateInner] = useStateBlock({});
    const [hasChanges, setHasChanges] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const update = useCallback((params) => {
        updateInner(params);
        setHasChanges(true);
    }, [updateInner]);

    const submit = useCallback(async () => {
        setIsLoading(true);

        const res = await updateUser({
            thumbnail: data.thumbnail,
            altText: data.altText
        });
        if (res.result === 'success') {
            setHasChanges(false);
            setError();
            refreshUser(localUser.id);
        }
        else {
            setError('Failed to update user information. Please try again.');
        }

        setIsLoading(false);
    }, [data, localUser, refreshUser]);

    useEffect(() => {
        requireAuth();
        setTitle('My Profile')
    }, []);

    useEffect(() => {
        update(() => localUser ?? {});
        setHasChanges(false);
    }, [localUser, update]);

    if (!isLoggedIn) {
        return (
            <Stack spacing={2} width='100%'>
                <Alert severity="error">You must be logged in to view or edit your profile.</Alert>
            </Stack>
        )
    }

    return (
        <Stack spacing={2} sx={{width: '100%', mt: 2}}>
            <Typography variant="h2">My Profile</Typography>
            { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
            <TextField label="E-mail" fullWidth disabled
                value={data.email ?? ''} 
            />
            <ImagePicker data={data} update={update} noImage />
            { hasChanges &&
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'right'}}>
                    <SubmitButton loading={isLoading} onClick={submit}>Save Changes</SubmitButton>
                </Box>
            }
            <Stack spacing={1} width="100%">
                <Typography variant="h3">Usage</Typography>
                <Typography>You have used {formatBytes(data.storage ?? 0)} of storage space.</Typography>
            </Stack>
            <ApiKey />
        </Stack>
    )
}

export default ProfileEditPage;
