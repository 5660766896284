import { useState, useEffect, createElement, useCallback } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { CircularProgress, Alert, Fab, Stack, Chip, Typography } from '@mui/material';
import { Edit as EditIcon} from '@mui/icons-material';
import { getDocumentType } from '../../documents.js';
import { useRepositoryCache, useTitleBar, useSetDocumentContext, useAuth } from '../../globals.js'
import { getDocument } from '../../api.js';
import FavoriteDocument from './FavoriteDocument.js';
import DeleteDocument from './DeleteDocument.js';
import CloneDocument from './CloneDocument.js';
import MoveDocument from './MoveDocument.js';

function DocumentViewPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [documentType, setDocumentType] = useState();
    const [documentCategory, setDocumentCategory] = useState();
    const [documentTags, setDocumentTags] = useState();
    const [documentTitle, setDocumentTitle] = useState();
    const [documentData, setDocumentData] = useState();
    const [documentRole, setDocumentRole] = useState();
    const [repositoryId, setRepositoryId] = useState();
    const { id: documentId } = useParams();
    const { setTitle } = useTitleBar();
    const setDocumentContext = useSetDocumentContext();
    const { getRepository } = useRepositoryCache();
    const { isLoggedIn, require: requireAuth } = useAuth();

    const fetchDocument = useCallback(async (documentId) => {
        setIsLoading(true);
        const response = await getDocument(documentId, true);
        if (response.result === 'success') {
            const documentType = getDocumentType(response.type);
            if (documentType) {
                setDocumentType(documentType);
                setDocumentTags(response.tags);
                setDocumentTitle(response.title);
                setDocumentCategory(response.category);
                setDocumentRole(response.role);
                setRepositoryId(response.repository);
                setDocumentData(documentType.deserialize(response));
                setError();
            }
            else {
                setError(`Unable to open document: unknown type ${response.type}.`);
            }
        }
        else {
            if (response.reason === 'not_authorized') {
                setError('Not authorized to view document.');
                setDocumentTitle('Unauthorized');
                requireAuth();
            }
            else {
                setError('Failed to retrieve document. Please try again.');
            }
        }
        setIsLoading(false);
    }, [requireAuth]);

    useEffect(() => {
        if (!isLoading && documentId && !documentType && !error) {
            fetchDocument(documentId);
        }
    }, [fetchDocument, documentId, documentType, isLoading, isLoggedIn, error]);

    useEffect(() => {
        if (!documentTitle) {
            setTitle(error ? 'Error' : 'Loading...');
        }
        else {
            const newTitle = documentTitle;
            setTitle(newTitle); 
        }

        if (documentCategory) {
            setDocumentContext.setCategory(documentCategory);
        }
        if (repositoryId) {
            setDocumentContext.setRepository(repositoryId);
        }
    }, [documentTitle, isLoading, error, setTitle, documentCategory, repositoryId, setDocumentContext]);

    let repository;
    if (repositoryId) {
        repository = getRepository(repositoryId);
    }

    const isEditor = documentRole === 'editor' || documentRole === 'owner';

    return (
        <Stack sx={{width: '100%', display: 'flex' }}>
            { documentId && <>
                { isLoggedIn && <FavoriteDocument documentId={documentId} /> }
                <CloneDocument documentId={documentId} title={documentTitle} />
                { isEditor && <>
                    <MoveDocument documentId={documentId} title={documentTitle} />
                    <DeleteDocument documentId={documentId} title={documentTitle} />
                </> }
            </> }
            { isLoading && <CircularProgress /> }
            { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
            { documentData && documentType?.viewer?.({
                data: documentData, 
                documentId: documentId, 
                repository: repository
            }) }
            { isEditor &&
                <Fab color="primary" sx={{ position: 'fixed', bottom: 32, right: 32 }} component={RouterLink} to={`/document/${documentId}/edit`}>
                    <EditIcon />
                </Fab>
            }
            { !(documentType?.noFooter ?? false) &&
                <Stack spacing={1} mt={4} alignItems="center">
                    { (documentTags?.length > 0 ?? 0) && 
                        <Stack direction="row" justifyContent="center" spacing={1}>
                            { documentTags.map((tag) => <Chip label={tag} key={tag} />)}
                        </Stack>
                    }
                    { repository?.title && 
                        <Typography variant="caption">{repository.title}{repository?.license && ` • ${repository?.license}`}</Typography> 
                    }
                    <br />
                    <br />
                </Stack>
            }
        </Stack>
    );
}

export default DocumentViewPage;

