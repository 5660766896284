import { useState, useEffect, useCallback } from 'react';
import { Stack, Typography, Pagination, LinearProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { listDocuments } from '../../api';
import { DocumentButton } from './DocumentButton.js';
import { useTitleBar } from '../../globals.js';
import TwoColumnList from '../common/MultiColumnList.js';

const resultsPerPage = 20;

export default function SearchPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const { setTitle } = useTitleBar();
    const { text: searchText } = useParams();
    const [results, setResults] = useState();
    const [total, setTotal] = useState(0);
    const [start, setStart] = useState(0);

    const fetchDocuments = useCallback(async (text, start) => {
        setIsLoading(true);
        const res = await listDocuments({text: text}, null, null, start, resultsPerPage);
        if (res.result === 'success') {
            setResults(res.documents);
            setTotal(res.total);
        }
        setIsLoading(false);
    }, []);
        
    const numPages = Math.ceil(total / resultsPerPage);
    const currentPage = (start / resultsPerPage) + 1;
    const setCurrentPage = useCallback((v) => {
        const newStart = (v - 1) * resultsPerPage;
        setStart(newStart);
        fetchDocuments(searchText, newStart);
    }, [fetchDocuments, searchText]);

    useEffect(() => {
        if (searchText) {
            fetchDocuments(searchText, 0);
            setTitle('Search: ' + searchText);
        }
        else {
            setResults([]);
            setTotal(0);
            setTitle('Search');
        }
    }, []);

    return (
        <Stack spacing={2} mt={1} width='100%'>
            <Typography variant="subtitle1">Results for '{searchText}' {!isLoading && `(${total} total)`}</Typography>

            <Stack direction="row" spacing={2} sx={{display: 'flex', width: '100%'}} alignItems="center">
                <Pagination count={numPages} page={currentPage ?? 1} onChange={(e, v) => setCurrentPage(v)} siblingCount={8} />
                { isLoading && <LinearProgress sx={{flexGrow: 1}} /> }
            </Stack>
            <TwoColumnList spacing={1} 
                items={results}
                render={(item, props) => <DocumentButton doc={item} {...props} />}
            />
        </Stack>
    );
}
