import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Stack, Typography, LinearProgress, Link } from '@mui/material';
import { useUserCache, useTitleBar, useAuth } from '../../globals.js';
import { listDocuments } from '../../api.js';
import { DocumentButton } from './DocumentButton.js';
import MultiColumnList from '../common/MultiColumnList.js';

function Favorites() {
    const [documents, setDocuments] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const { localUser } = useUserCache();

    const fetchFavorites = useCallback(async (favorites) => {
        setIsLoading(true);
        const res = await listDocuments({id: favorites});
        if (res.result === 'success') {
            setDocuments(res.documents);
            setError();
        }
        else {
            setError('Failed to fetch history.');
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if ((localUser?.favorites?.length ?? 0) > 0) {
            fetchFavorites(localUser.favorites);
        }
    }, [localUser, fetchFavorites]);

    return <>
        { isLoading && <LinearProgress /> }
        { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
        { (documents && documents.length > 0) ?
            <MultiColumnList spacing={1} itemWidth={400} items={documents}
                render={(item, props) => <DocumentButton doc={item} {...props}  />}
            />
            :
            !isLoading && <Typography>You haven't favorited any documents yet!</Typography>
        }
    </>;
}

function History() {
    const [documents, setDocuments] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const { localUser } = useUserCache();

    const fetchHistory = useCallback(async (history) => {
        setIsLoading(true);
        const res = await listDocuments({id: history});
        if (res.result === 'success') {
            // the documents will not be ordered in history order, so we need to sort them
            let docs = [];
            for (let docId of history) {
                const doc = res.documents.find((doc) => doc.id === docId);
                if (doc) {
                    docs = [...docs, doc];
                }
            }
            setDocuments(docs);
            setError();
        }
        else {
            setError('Failed to fetch history.');
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if ((localUser?.history?.length ?? 0) > 0) {
            fetchHistory(localUser.history);
        }
    }, [localUser, fetchHistory]);

    return <>
        { isLoading && <LinearProgress /> }
        { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
        { (documents && documents.length > 0) ?
            <MultiColumnList spacing={1} itemWidth={400} items={documents}
                render={(item, props) => <DocumentButton doc={item} {...props}  />}
            />
            :
            !isLoading && <Typography>You haven't viewed any documents yet!</Typography>
        }
    </>;
}

function HomePage() {
    const { localUser, refreshUser } = useUserCache();
    const { isLoggedIn, require: requireAuth } = useAuth();
    const navigate = useNavigate();
    const { setTitle } = useTitleBar();
    const [showSourcesHint, setShowSourcesHint] = useState(false);

    const navigateToSources = useCallback(() => {
        navigate('/sources');
    }, [navigate]);

    useEffect(() => {
        setTitle("Home");

        if (localUser) {
            refreshUser(localUser.id);
        }
    }, []);

    useEffect(() => {
        if (localUser && localUser.subscriptions && localUser.subscriptions.length === 0) {
            setShowSourcesHint(true);
        }
        else {
            setShowSourcesHint(false);
        }
    }, [localUser]);

    if (!isLoggedIn) {
        return (
            <Stack spacing={2} width='100%' mt={1}>
                <Typography variant="h1">Welcome to Codex!</Typography>
                <Typography variant="body1">
                    Codex is a tool for Game Masters to build and manage campaigns and homebrew for 5th Edition and
                    compatible games.
                </Typography>
                <Typography>
                <Link onClick={requireAuth}>Log in</Link> to get started!
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack spacing={4} sx={{width: '100%'}} mt={1}>
            { showSourcesHint && 
                <Alert severity="info">
                    You have no subscriptions, so you won't see any content. Would you like to set some up now? 
                    <Button onClick={navigateToSources}>Manage Sources</Button>
                </Alert>
            }
            <Stack spacing={2}>
                <Typography variant="h1">Recently Viewed</Typography>
                <History />
            </Stack>
            <Stack spacing={2}>
                <Typography variant="h1">Favorites</Typography>
                <Favorites />
            </Stack>
            <br />
        </Stack>
    )
}

export { HomePage };
