import { useState, useEffect, createElement, useCallback } from 'react';
import { useParams, useNavigate, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useMediaQuery, useTheme, CircularProgress, Alert, Fab, Box, DialogContent, DialogContentText, DialogActions, Button, Snackbar, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Save as SaveIcon, Article as PropertiesIcon } from '@mui/icons-material';
import { getDocumentType } from '../../documents.js';
import { useTitleBar, useSetDocumentContext, useAuth } from '../../globals.js'
import { useStateBlock } from '../../utils.js';
import { getDocument, updateDocument } from '../../api.js';
import { cleanTags } from '../common/TagPicker.js';
import PropertiesPanel from '../common/PropertiesPanel.js';
import FavoriteDocument from './FavoriteDocument.js';
import DeleteDocument from './DeleteDocument.js';
import CloneDocument from './CloneDocument.js';
import MoveDocument from './MoveDocument.js';
import CommonDialog from '../common/CommonDialog.js';

function ConfirmNavigateDialog({confirm, cancel, save}) {
    return (
        <CommonDialog close={cancel} title="Leave this page?">
            <DialogContent>
                <DialogContentText>You have unsaved changes which will be lost if you leave. Are you sure?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} autoFocus>Cancel</Button>
                <Button onClick={confirm}>Continue Without Saving</Button>
                <Button onClick={save}>Save and Continue</Button>
            </DialogActions>
        </CommonDialog>
    );
}

function handleBeforeUnload(e) {
    e.preventDefault();
}

function DocumentEditPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [renderKey, setRenderKey] = useState(0);
    const [documentType, setDocumentType] = useState();
    const [documentTitle, setDocumentTitle] = useState();
    const [documentCategory, setDocumentCategory] = useState();
    const [documentRepository, setDocumentRepository] = useState();
    const [documentData, updateDocumentData] = useStateBlock();
    const [hasChanges, setHasChanges] = useState(false);
    const { id: documentId } = useParams();
    const { setTitle } = useTitleBar();
    const setDocumentContext = useSetDocumentContext();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    const theme = useTheme();
    const useFab = true; // useMediaQuery(theme.breakpoints.up('xl'));

    const shouldBlock = useCallback(({currentLocation, nextLocation}) => 
        (currentLocation.pathname !== nextLocation.pathname) && hasChanges, 
    [hasChanges]);
    const blocker = useBlocker(shouldBlock);

    const fetchDocument = useCallback(async (documentId) => {
        setIsLoading(true);
        const response = await getDocument(documentId, true);
        if (response.result === 'success') {

            // check if we only have read access to this document
            if (response.role === 'viewer') {
                navigate(`/document/${documentId}`, {replace: true});
            }

            const documentType = getDocumentType(response.type);
            if (documentType) {
                setDocumentType(documentType);
                setDocumentTitle(response.title);
                setDocumentCategory(response.category);
                setDocumentRepository(response.repository);
                const initialData = documentType.deserialize(response);
                updateDocumentData(() => initialData);
                setRenderKey((renderKey) => renderKey + 1);
                setError();
            }
            else {
                setError(`Unable to open document: unknown type ${response.type}.`);
            }
        }
        else {
            if (response.reason === 'not_authorized') {
                setError('Not authorized to view or edit this document.');
            }
            else {
                setError('Failed to retrieve document. Please try again.');
            }
        }
        setIsLoading(false);
    }, [updateDocumentData, navigate]);

    useEffect(() => {
        if (hasChanges) {
            window.addEventListener("beforeunload", handleBeforeUnload, null);
            return () => { 
                window.removeEventListener("beforeunload", handleBeforeUnload);
            }
        }
        else {
            if (blocker.state === "blocked") {
                blocker.proceed?.();
            }
            else {
                blocker.reset?.();
            }
        }
    }, [hasChanges, blocker]);

    const save = useCallback(async () => {
        if (documentData) {
            const errorList = documentType.validate?.(documentData) ?? [];
            if (errorList.length > 0) {
                setError(errorList.join('\n'));
            }
            else {
                setIsLoading(true);
                const document = documentType.serialize(documentData);
    
                // do any universal validation here
                document.tags = cleanTags(document.tags);
    
                const response = await updateDocument(documentId, document);     
                if (response.result === 'success') {
                    setError();
                    setHasChanges(false);

                    if (blocker.state !== "blocked") {
                        navigate(-1);
                    }
                }
                else {
                    setError('Failed to save document. Please try again.');
                }
                setIsLoading(false);     
            }         
        }
    }, [documentId, documentData, documentType, navigate, blocker]);

    const refresh = useCallback(async () => {
        if (!isLoading && documentId) {
            fetchDocument(documentId);
        }
    }, [fetchDocument, documentId, isLoading]);

    useEffect(() => {
        if (!isLoading && documentId && !documentType) {
            fetchDocument(documentId);
        }
    }, [fetchDocument, documentId, documentType, isLoading, isLoggedIn, navigate]);

    useEffect(() => {
        if (isLoading) {
            setTitle('Loading...');
        }
        else if (documentTitle) {
            setTitle(`${documentTitle} (Editing)`);
        }
        else {
            setTitle('Error');
        }

        if (documentCategory) {
            setDocumentContext.setCategory(documentCategory);
        }
        if (documentRepository) {
            setDocumentContext.setRepository(documentRepository);
        }
    }, [documentTitle, isLoading, setTitle, documentCategory, documentRepository, setDocumentContext]);

    const updateWrapper = useCallback((params) => {
        updateDocumentData(params);
        setHasChanges(true);
    }, [updateDocumentData]);
 
    const cancelBlocker = useCallback(() => blocker.reset?.(), [blocker]);
    const confirmBlocker = useCallback(() => blocker.proceed?.(), [blocker]);
    const isBlocked = blocker.state === "blocked";

    return (<>
        { error && 
            <Snackbar open={Boolean(error)}>
                <Alert severity="error" onClose={() => setError()} sx={{width: '100%'}}>{error}</Alert>
            </Snackbar> 
        }
        { documentId && <>
            <FavoriteDocument documentId={documentId} />
            <CloneDocument documentId={documentId} title={documentTitle} />
            <MoveDocument documentId={documentId} title={documentTitle} />
            <DeleteDocument documentId={documentId} title={documentTitle} />
        </> }
        <Box id="document-edit-page" width="100%" height="100%">
            { isBlocked && <ConfirmNavigateDialog cancel={cancelBlocker} confirm={confirmBlocker} save={save} /> }
            { isLoading && <CircularProgress /> }
            { documentType?.editor?.({
                key: renderKey,
                data: documentData, 
                update: updateWrapper, 
                refresh: refresh,
                documentId: documentId,
            }) }
        </Box>
        { useFab &&
            <Box sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: '1100' }}>
                <Fab onClick={save} color="primary">
                    <SaveIcon />
                </Fab>
            </Box>
        }
    </>);
}

export default DocumentEditPage;

