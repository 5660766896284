const proficiencyBonusByCR = [
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    3,
    3,
    3,
    3,
    4,
    4,
    4,
    4,
    5,
    5,
    5,
    5,
    6,
    6,
    6,
    6,
    7,
    7,
    7,
    7,
    8,
    8,
    8,
    8,
    9,
    9
];

function getProficiencyBonusByCR(cr) {
    if (cr > 33) {
        return proficiencyBonusByCR(cr);
    }
    else {
        return proficiencyBonusByCR[cr] ?? 2;
    }
}

const offensiveCRStats = [
    { cr: 0, attackBonus: 3, minDamage: 0, saveDC: 13},
    { cr: 1, attackBonus: 3, minDamage: 2, saveDC: 13},
    { cr: 2, attackBonus: 3, minDamage: 4, saveDC: 13},
    { cr: 3, attackBonus: 3, minDamage: 6, saveDC: 13},
    { cr: 4, attackBonus: 3, minDamage: 9, saveDC: 13},
    { cr: 5, attackBonus: 3, minDamage: 15, saveDC: 13},
    { cr: 6, attackBonus: 3, minDamage: 21, saveDC: 13},
    { cr: 7, attackBonus: 4, minDamage: 27, saveDC: 14},
    { cr: 8, attackBonus: 5, minDamage: 33, saveDC: 15},
    { cr: 9, attackBonus: 6, minDamage: 39, saveDC: 15},
    { cr: 10, attackBonus: 6, minDamage: 45, saveDC: 15},
    { cr: 11, attackBonus: 6, minDamage: 51, saveDC: 16},
    { cr: 12, attackBonus: 7, minDamage: 57, saveDC: 16},
    { cr: 13, attackBonus: 7, minDamage: 63, saveDC: 16},
    { cr: 14, attackBonus: 7, minDamage: 69, saveDC: 17},
    { cr: 15, attackBonus: 8, minDamage: 75, saveDC: 17},
    { cr: 16, attackBonus: 8, minDamage: 81, saveDC: 18},
    { cr: 17, attackBonus: 8, minDamage: 87, saveDC: 18},
    { cr: 18, attackBonus: 8, minDamage: 93, saveDC: 18},
    { cr: 19, attackBonus: 9, minDamage: 99, saveDC: 18},
    { cr: 20, attackBonus: 10, minDamage: 105, saveDC: 19},
    { cr: 21, attackBonus: 10, minDamage: 111, saveDC: 19},
    { cr: 22, attackBonus: 10, minDamage: 117, saveDC: 19},
    { cr: 23, attackBonus: 10, minDamage: 123, saveDC: 19},
    { cr: 24, attackBonus: 11, minDamage: 141, saveDC: 20},
    { cr: 25, attackBonus: 11, minDamage: 159, saveDC: 20},
    { cr: 26, attackBonus: 11, minDamage: 177, saveDC: 20},
    { cr: 27, attackBonus: 12, minDamage: 195, saveDC: 21},
    { cr: 28, attackBonus: 12, minDamage: 213, saveDC: 21},
    { cr: 29, attackBonus: 12, minDamage: 231, saveDC: 21},
    { cr: 30, attackBonus: 13, minDamage: 249, saveDC: 22},
    { cr: 31, attackBonus: 13, minDamage: 267, saveDC: 22},
    { cr: 32, attackBonus: 13, minDamage: 285, saveDC: 22},
    { cr: 33, attackBonus: 14, minDamage: 303, saveDC: 23},
];

function getOffensiveCRFromDamage(damage) {
    for (let i = 1; i < 33; i++) {
        if (damage < offensiveCRStats[i].minDamage) {
            return offensiveCRStats[i - 1];
        }
    }
    return offensiveCRStats[33];
}

const defensiveCRStats = [
    { cr: 0, armorClass: 10, minHitPoints: 1 },
    { cr: 1, armorClass: 13, minHitPoints: 7 },
    { cr: 2, armorClass: 13, minHitPoints: 36 },
    { cr: 3, armorClass: 13, minHitPoints: 50 },
    { cr: 4, armorClass: 13, minHitPoints: 71 },
    { cr: 5, armorClass: 13, minHitPoints: 86 },
    { cr: 6, armorClass: 13, minHitPoints: 101 },
    { cr: 7, armorClass: 14, minHitPoints: 116 },
    { cr: 8, armorClass: 15, minHitPoints: 131 },
    { cr: 9, armorClass: 15, minHitPoints: 146 },
    { cr: 10, armorClass: 15, minHitPoints: 161 },
    { cr: 11, armorClass: 16, minHitPoints: 176 },
    { cr: 12, armorClass: 16, minHitPoints: 191 },
    { cr: 13, armorClass: 17, minHitPoints: 206 },
    { cr: 14, armorClass: 17, minHitPoints: 221 },
    { cr: 15, armorClass: 17, minHitPoints: 236 },
    { cr: 16, armorClass: 18, minHitPoints: 251 },
    { cr: 17, armorClass: 18, minHitPoints: 266 },
    { cr: 18, armorClass: 18, minHitPoints: 281 },
    { cr: 19, armorClass: 18, minHitPoints: 296 },
    { cr: 20, armorClass: 19, minHitPoints: 311 },
    { cr: 21, armorClass: 19, minHitPoints: 326 },
    { cr: 22, armorClass: 19, minHitPoints: 341 },
    { cr: 23, armorClass: 19, minHitPoints: 356 },
    { cr: 24, armorClass: 19, minHitPoints: 401 },
    { cr: 25, armorClass: 19, minHitPoints: 446 },
    { cr: 26, armorClass: 19, minHitPoints: 491 },
    { cr: 27, armorClass: 19, minHitPoints: 536 },
    { cr: 28, armorClass: 19, minHitPoints: 581 },
    { cr: 29, armorClass: 19, minHitPoints: 626 },
    { cr: 30, armorClass: 19, minHitPoints: 671 },
    { cr: 31, armorClass: 19, minHitPoints: 716 },
    { cr: 32, armorClass: 19, minHitPoints: 761 },
    { cr: 33, armorClass: 19, minHitPoints: 806 },
];

function getDefensiveCRFromHitPoints(hitPoints) {
    for (let i = 1; i < 33; i++) {
        if (hitPoints < defensiveCRStats[i].minHitPoints) {
            return defensiveCRStats[i - 1];
        }
    }
    return defensiveCRStats[33];
}

function challengeRatingToString(cr) {
    if (cr === 0) return '0';
    else if (cr === 1) return '1/8';
    else if (cr === 2) return '1/4';
    else if (cr === 3) return '1/2';
    else if (cr >= 4) return (cr - 3).toString();
}

const challengeRatingToXPTable = [
    10,
    25,
    50,
    100,
    200,
    450,
    700,
    1100,
    1800,
    2300,
    2900,
    3900,
    5000,
    5900,
    7200,
    8400,
    10000,
    11500,
    13000,
    15000,
    18000,
    20000,
    22000,
    25000,
    33000,
    41000,
    50000,
    62000,
    75000,
    90000,
    105000,
    120000,
    135000,
    155000
];

function challengeRatingToXP(cr) {
    if (cr > 33) {
        return challengeRatingToXPTable[33];
    }
    return challengeRatingToXPTable[cr] ?? 0;
}

const abilityScoreList = [
    'str',
    'dex',
    'con',
    'int',
    'wis',
    'cha'
];

const abilityScoreLabels = {
    'str': 'Strength',
    'dex': 'Dexterity',
    'con': 'Constitution',
    'int': 'Intelligence',
    'wis': 'Wisdom',
    'cha': 'Charisma'
}

function getAbilityScoreLabel(abilityScore) {
    return abilityScoreLabels[abilityScore] ?? null;
}

function getAbilityScoreModifier(abilityScore) {
    return Math.floor(((abilityScore ?? 10) - 10) * 0.5);
}

const skillList = [
    'acrobatics', 
    'animalHandling', 
    'arcana', 
    'athletics', 
    'deception',
    'history',
    'insight',
    'intimidation',
    'investigation',
    'medicine',
    'nature',
    'perception',
    'persuasion',
    'religion',
    'sleightOfHand',
    'stealth',
    'survival'
];

const skillDetails = {
    'acrobatics': { label: 'Acrobatics', ability: 'dex' },
    'animalHandling': { label: 'Animal Handling', ability: 'wis' },
    'arcana': { label: 'Arcana', ability: 'int' },
    'athletics': { label: 'Athletics', ability: 'str' },
    'deception': { label: 'Deception', ability: 'cha' },
    'history': { label: 'History', ability: 'int' },
    'insight': { label: 'Insight', ability: 'wis' },
    'intimidation': { label: 'Intimidation', ability: 'cha' },
    'investigation': { label: 'Investigation', ability: 'int' },
    'medicine': { label: 'Medicine', ability: 'wis' },
    'nature': { label: 'Nature', ability: 'int' },
    'perception': { label: 'Perception', ability: 'wis' },
    'persuasion': { label: 'Persuasion', ability: 'cha' },
    'religion': { label: 'Religion', ability: 'wis' },
    'sleightOfHand': { label: 'Sleight of Hand', ability: 'dex' },
    'stealth': { label: 'Stealth', ability: 'dex' },
    'survival': { label: 'Survival', ability: 'wis' }
}

function getSkillLabel(skill) {
    return skillDetails[skill]?.label ?? null;
}

function getSkillAbilityScore(skill) {
    return skillDetails[skill]?.ability ?? null;
}

const spellLevelList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9
];

const spellLevelLabels = [
    'cantrip',
    '1st level',
    '2nd level',
    '3rd level',
    '4th level',
    '5th level',
    '6th level',
    '7th level',
    '8th level',
    '9th level'
];

function getSpellLevelLabel(level) {
    return spellLevelLabels[level] ?? '';
}

const actionTypeList = ['action', 'attack', 'bonusAction', 'reaction'];

const actionTypeLabels = {
    action: 'Action',
    attack: 'Attack',
    bonusAction: 'Bonus Action',
    reaction: 'Reaction'
};

function getActionTypeLabel(actionType) {
    return actionTypeLabels[actionType] ?? 'Unknown';
}

const attackTypeList = [
    'meleeWeapon',
    'rangedWeapon',
    'meleeSpell',
    'rangedSpell'
];

const attackTypeLabels = {
    meleeWeapon: 'Melee Weapon',
    rangedWeapon: 'Ranged Weapon',
    meleeSpell: 'Melee Spell',
    rangedSpell: 'Ranged Spell'
};

function getAttackTypeLabel(attackType) {
    return attackTypeLabels[attackType] ?? 'Unknown';
}

const itemRarityList = [0, 1, 2, 3, 4];

const itemRarityLabels = [
    'common',
    'uncommon',
    'rare',
    'very rare',
    'legendary'
];

function getItemRarityLabel(rarity) {
    return itemRarityLabels[rarity] ?? 'unknown';
}

export { 
    getProficiencyBonusByCR, 
    challengeRatingToString, 
    challengeRatingToXP,
    getOffensiveCRFromDamage, 
    getDefensiveCRFromHitPoints, 
    abilityScoreList, 
    getAbilityScoreLabel,
    getAbilityScoreModifier,
    skillList,
    getSkillLabel,
    getSkillAbilityScore,
    spellLevelList,
    getSpellLevelLabel,
    actionTypeList,
    getActionTypeLabel,
    attackTypeList,
    getAttackTypeLabel,
    itemRarityList,
    getItemRarityLabel
};

