const pronounSuggestions = [
    'they/them',
    'he/him',
    'she/her',
    'he/they',
    'she/they',
    'he/she/they'
];

const speciesSuggestions = [
    'beast',
    'dragonborn',
    'elemental',
    'elf',
    'fey',
    'dwarf',
    'giant',
    'gnome',
    'goblin',
    'half-elf',
    'half-orc',
    'halfling',
    'hobgoblin',
    'human',
    'orc',
    'tiefling',
];

const alignmentSuggestions = [
    'lawful good',
    'neutral good',
    'chaotic good',
    'lawful neutral',
    'true neutral',
    'chaotic netural',
    'lawful evil',
    'neutral evil',
    'chaotic evil'
];

const monsterAlignmentSuggestions = [
    'any',
    'unaligned',
    ...alignmentSuggestions,
    'any good',
    'any neutral',
    'any evil',
    'any lawful',
    'any chaotic'
];

const sizeSuggestions = [
    'tiny',
    'small',
    'medium',
    'large',
    'huge',
    'gargantuan'
];

const creatureTypeSuggestions = [
    'aberration',
    'beast',
    'celestial',
    'construct',
    'dragon',
    'elemental',
    'fey',
    'fiend',
    'giant',
    'humanoid',
    'monstrosity',
    'ooze',
    'plant',
    'undead'
];

const creatureSubtypeSuggestions = [
    'any species'
];

const damageTypeSuggestions = [
    'acid',
    'bludgeoning',
    'cold',
    'fire',
    'force',
    'lightning',
    'necrotic',
    'piercing',
    'poison',
    'psychic',
    'radiant',
    'slashing',
    'thunder'
];

const damageTypeResistanceSuggestions = [
    ...damageTypeSuggestions,
    'bludgeoning, piercing, and slashing from nonmagical attacks'
];

const conditionSuggestions = [
    'blinded',
    'charmed',
    'defeaned',
    'exhaustion',
    'frightened',
    'grappled',
    'paralyzed',
    'petrified',
    'poisoned',
    'prone',
    'restrained',
    'stunned',
    'unconscious',
];

const senseSuggestions = [
    'blindsight 10ft.',
    'blindsight 30ft.',
    'blindsight 60ft.',
    'darkvision 30ft.',
    'darkvision 60ft.',
    'darkvision 120ft.',
    'tremorsense 10ft.',
    'tremorsense 30ft.',
    'tremorsense 60ft.',
    'tremorsense 120ft',
    'truesight 10ft.',
    'truesight 30ft.',
    'truesight 60ft.',
    'truesight 120ft.',
];

const languageSuggestions = [
    'Abyssal',
    'Celestial',
    'Common',
    'Deep Speech',
    'Draconic',
    'Dwarvish',
    'Elvish',
    'Giant',
    'Gnomish',
    'Goblin',
    'Halfling',
    'Infernal',
    'Orcish',
    'Primordial',
    'Sylvan',
    'Undercommon',
    'all',
    'any one language (usually Common)',
    'telepathy 120 ft.'
];

const weaponRangeSuggestions = [
    '5 ft.',
    '10 ft.',
    '15 ft.',
    '20 ft.',
    '30 ft.',
    '60 ft.',
    '120 ft.',
    '5/15 ft.',
    '20/60 ft.',
    '25/100 ft.',
    '30/120 ft.',
    '80/320 ft.',
    '100/400 ft.',
    '150/600 ft.'
];

const armorTypeSuggestions = [
    'padded armor',
    'leather armor',
    'studded leather',
    'hide armor',
    'chain shirt',
    'scale mail',
    'breastplate',
    'half plate',
    'ring mail',
    'chain mail',
    'split armor',
    'plate armor',
];

const creatureArmorSuggestions = [
    'natural',
    ...armorTypeSuggestions,
    'mage armor',
];

const speedSuggestions = [
    '10 ft.',
    '20 ft.',
    '25 ft.',
    '30 ft.',
    '35 ft.',
    '40 ft.',
    '50 ft.',
    '60 ft.',
    'burrow 5 ft.',
    'burrow 10 ft.',
    'burrow 20 ft.',
    'burrow 30 ft.',
    'burrow 40 ft.',
    'burrow 50 ft.',
    'burrow 60 ft.',
    'climb 10 ft.',
    'climb 20 ft.',
    'climb 30 ft.',
    'climb 40 ft.',
    'climb 50 ft.',
    'climb 60 ft.',
    'fly 10 ft.',
    'fly 20 ft.',
    'fly 30 ft.',
    'fly 40 ft.',
    'fly 50 ft.',
    'fly 60 ft.',
    'fly 80 ft.',
    'fly 120 ft.',
    'swim 30 ft.',
    'swim 40 ft.',
    'swim 50 ft.',
    'swim 60 ft.',
];

const castTimeSuggestions = [
    '1 action',
    '1 bonus action',
    '1 reaction',
    '1 minute',
    '10 minutes',
    '1 hour',
    '8 hours',
    '24 hours'
];

const spellSchoolSuggestions = [
    'abjuration',
    'conjuration',
    'divination',
    'enchantment',
    'evocation',
    'illusion',
    'necromancy',
    'transmutation'
];

const spellRangeSuggestions = [
    'self',
    'touch',
    'special',
    'sight',
    '5 ft.',
    '10 ft.',
    '15 ft.',
    '20 ft.',
    '30 ft.',
    '60 ft.',
    '90 ft.',
    '120 ft.',
    '150 ft.',
    '240 ft.',
    '300 ft.',
    '360 ft.',
    '1 mile'
];

const spellDurationSuggestions = [
    'instant',
    'until dispelled',
    'special',
    '1 round',
    '1 minute',
    '10 minutes',
    '1 hour',
    '8 hours',
    '24 hours',
    '7 days',
];

const spellTagSuggestions = [
    'affliction',
    'area',
    'concealment',
    'communication',
    'control',
    'damage',
    'detection',
    'enhancement',
    'environment',
    'information',
    'movement',
    'negation',
    'protection',
    'transformation',
    'travel',
    'social',
    'summoning',
    'healing',
    'warding',
    ...damageTypeSuggestions
];

const classSuggestions = [
    'bard',
    'cleric',
    'druid',
    'paladin',
    'ranger',
    'sorcerer',
    'warlock',
    'wizard'
];

const actionLimitSuggestions = [
    '1/Day',
    '2/Day',
    '3/Day',
    '4/Day',
    'Recharge 6',
    'Recharge 5-6',
    'Recharge 4-6',
    'Recharge 3-6',
];

const spellLimitSuggestions = [
    'At-will',
    '1/Day',
    '2/Day',
    '3/Day',
    '4/Day',
    '5/Day'
];

const raritySuggestions = [
    'common',
    'uncommon',
    'rare',
    'very rare',
    'legendary'
];

const itemTypeSuggestions = [
    'armor',
    'weapon',
    'ammunition',
    'consumable',
    'equipment',
    'treasure'
];

const itemSubtypeSuggestions = {
    'armor': [
        'any',
        'light',
        'medium',
        'heavy',
        'shield',
        ...armorTypeSuggestions
    ],
    'weapon': [
        'any',
        'arrow',
        'battleaxe',
        'blowgun',
        'club',
        'dagger',
        'dart',
        'flail',
        'glaive',
        'greataxe',
        'greatclub',
        'greatsword',
        'halberd',
        'hand crossbow',
        'handaxe',
        'heavy crossbow',
        'javelin',
        'lance',
        'light crossbow',
        'light hammer',
        'longbow',
        'longsword',
        'mace',
        'maul',
        'morningstar',
        'net',
        'pike',
        'quarterstaff',
        'rapier',
        'scimitar',
        'shortbow',
        'shortsword',
        'sickle',
        'sling',
        'spear',
        'trident',
        'war pick',
        'warhammer',
        'whip',
    ],
    'consumable': [
        'potion',
        'scroll',
        'wand'
    ],
    'ammunition': [
        'arrow',
        'bolt',
        'bullet',
    ],
    'equipment': [
        'amulet',
        'belt',
        'boots',
        'cloak',
        'ring',
        'rod',
        'staff'
    ]
}

const challengeRatingSuggestions = Array(33).fill().map((v, i) => i);

export {
    pronounSuggestions, 
    speciesSuggestions, 
    alignmentSuggestions, 
    sizeSuggestions, 
    creatureTypeSuggestions, 
    creatureSubtypeSuggestions,
    damageTypeSuggestions, 
    damageTypeResistanceSuggestions, 
    conditionSuggestions, 
    senseSuggestions,
    languageSuggestions,
    weaponRangeSuggestions,
    armorTypeSuggestions,
    creatureArmorSuggestions,
    monsterAlignmentSuggestions,
    speedSuggestions,
    castTimeSuggestions,
    spellSchoolSuggestions,
    spellRangeSuggestions,
    spellDurationSuggestions,
    spellTagSuggestions,
    classSuggestions,
    actionLimitSuggestions,
    spellLimitSuggestions,
    raritySuggestions,
    challengeRatingSuggestions,
    itemTypeSuggestions,
    itemSubtypeSuggestions
 };
