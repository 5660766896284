import { useState, useCallback, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, TextField, Stack, Alert, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import CommonDialog from '../common/CommonDialog.js';
import SubmitButton from '../common/SubmitButton.js';
import { useTitleBar } from '../../globals.js';
import { deleteDocument } from '../../api.js';

function DeleteDocumentDialog({title, documentId, close}) {
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [confirm, setConfirm] = useState();
    const navigate = useNavigate();

    const submit = useCallback(async () => {
        setIsLoading(true);
        const res = await deleteDocument(documentId);
        if (res.result === 'success') {
            close();
            navigate('/');
            setError();
        }
        else {
            setError('Failed to delete document. Please try again.');
        }
        setIsLoading(false);
    }, [close, documentId, navigate]);

    const canSubmit = (confirm === 'DELETE') && !isLoading;

    return (
        <CommonDialog close={close} title="Delete Document">
            <DialogContent>
                <Stack spacing={2}>
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                    <Typography>Are you sure you want to delete {title}? This is permanent. To continue, type DELETE in the box below.</Typography>
                    <TextField fullWidth autoComplete="off" value={confirm ?? ''} onChange={(e) => setConfirm(e.target.value.toUpperCase())} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <SubmitButton onClick={submit} disabled={!canSubmit} loading={isLoading}>Delete</SubmitButton>
            </DialogActions>
        </CommonDialog>
    );
}

const DeleteDocument = memo(({title, documentId}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { addWidget, removeWidget } = useTitleBar();

    useEffect(() => {
        if (documentId) {
            addWidget(
                <Tooltip title="Delete Document" key="delete-document">
                    <IconButton onClick={() => setIsDialogOpen(true)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            );
            return () => { removeWidget('delete-document'); }      
        }
    }, [documentId, addWidget, removeWidget]);  
    
    return <>
        { isDialogOpen && <DeleteDocumentDialog title={title} documentId={documentId} close={() => setIsDialogOpen(false)} /> }
    </>;
});

export default DeleteDocument;
