import config from '../config.js';

export async function createRepository(title) {
    try {
        const response = await fetch(config.apiUrl + '/repository/create', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({title: title})
        });    
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}

export async function updateRepository(repoId, fields) {
    try {
        let req = { repositoryId: repoId, ...fields };

        const response = await fetch(config.apiUrl + '/repository/update', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(req)
        });    
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }    
}

export async function getRepository(repoId) {
    try {
        const response = await fetch(config.apiUrl + '/repository/get?id=' + repoId, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}

export async function listRepositories() {
    try {
        const response = await fetch(config.apiUrl + '/repository/list', {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }   
}

export async function subscribeToRepository(repoId) {
    try {
        const response = await fetch(config.apiUrl + '/repository/subscribe?id=' + repoId, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }    
}

export async function unsubscribeFromRepository(repoId) {
    try {
        const response = await fetch(config.apiUrl + '/repository/unsubscribe?id=' + repoId, {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }    
}

