import { makeDiceFormula } from "../dice";
import { skillList, abilityScoreList, spellLevelList, challengeRatingToString } from "../dnd5e.js";
import { formatSentence } from "../text.js";

function getDescription(data) {
    return formatSentence(`${data.vitals.size ?? ''} ${data.vitals.type ?? ''} (CR ${challengeRatingToString(data.vitals.challengeRating)})`);
}

function getTags(data) {
    const isLegendary = (data.legendary.legendaryActionUses > 0 || data.legendary.legendaryResistances > 0);
    const hasLair = data.lair.lairActions.length > 0;
    const hasSpells = data.spellcasting.spellLevels.some((spellLevel) => spellLevel.spells.length > 0);

    let tags = new Set(data.vitals.tags);
    if (isLegendary) {
        tags.add('legendary');
    }
    if (hasLair) {
        tags.add('lair');
    }
    if (hasSpells) {
        tags.add('spellcaster');
    }

    return [...tags];
}

const defaultSkill = {proficiency: null, bonus: null};
const defaultSavingThrow = {proficient: null, bonus: null};
const defaultSpellLevel = {slots: null, spells: []};

const Monster = {
    name: 'Monster (5E)',
    plural: 'Monsters',

    init: () => {
        let data = Monster.deserialize({});
        return {
            ...data,
            vitals: {
                ...data.vitals,
                noun: 'the creature',
                abilityScores: {
                    str: 10,
                    dex: 10,
                    con: 10,
                    int: 10,
                    wis: 10,
                    cha: 10
                },
                //challengeRating: 0,
                //proficiencyBonus: 2
            },
            defenses: {
                ...data.defenses,
                //hitPoints: makeDiceFormula(1, 8, 0),
                //armorClass: 10
            },
            lair: {
                ...data.lair,
                //lairActionInitiative: 20
            }           
        }
    },

    validate: (data) => {
        let errors = [];
        if (!data.vitals.name) {
            errors.push('Name is required');
        }
        return errors;
    },

    deserialize: (document) => ({
        vitals: {
            name: document.title ?? null,
            noun: document.data?.noun ?? null,
            alignment: document.data?.alignment ?? null,
            type: document.data?.type ?? null,
            subtype: document.data?.subtype ?? null,
            size: document.data?.size ?? null,
            speed: document.data?.speed ?? [],
            abilityScores: Object.fromEntries(abilityScoreList.map((ability) =>
                [ability, document.data?.abilityScores?.[ability] ?? null]
            )),
            senses: document.data?.senses ?? [],
            languages: document.data?.languages ?? [],
            tags: document.tags ?? [],
            challengeRating: document.data?.challengeRating ?? null,
            proficiencyBonus: document.data?.proficiencyBonus ?? null
        },
        challenge: {
            overrideDamagePerTurn: document.data?.overrideDamagePerTurn ?? null,
            overrideAttackBonus: document.data?.overrideAttackBonus ?? null,
            overrideEffectiveHP : document.data?.overrideEffectiveHP ?? null,
            overrideEffectiveAC : document.data?.overrideEffectiveAC ?? null
        },
        defenses: {
            hitPoints: document.data?.hitPoints ?? null,
            armorClass: document.data?.armorClass ?? null,
            armorType: document.data?.armorType ?? null,
            resistances: document.data?.resistances ?? [],
            damageImmunities: document.data?.damageImmunities ?? [],
            conditionImmunities: document.data?.conditionImmunities ?? [],
            vulnerabilities: document.data?.vulnerabilities ?? []
        },
        savingThrows: {
            savingThrows: Object.fromEntries(abilityScoreList.map((ability) =>
                [ability, document.data?.savingThrows?.[ability] ?? defaultSavingThrow]
            )),
        },
        skills: {
            skills: Object.fromEntries(skillList.map((skill) =>
                [skill, document.data?.skills?.[skill] ?? defaultSkill]
            )),
        },
        spellcasting: {
            spellcastingAbility: document.data?.spellcastingAbility ?? null,
            spellcastingBonus: document.data?.spellcastingBonus ?? null,
            spellLevels: spellLevelList.map((level) => 
                document.data?.spellLevels?.[level] ?? defaultSpellLevel
            ),
            additionalSpells: document.data?.additionalSpells ?? [],
        },
        traits: {
            traits: document.data?.traits ?? []
        },
        actions: {
            attacksPerTurn: document.data?.attacksPerTurn ?? null,
            actions: document.data?.actions ?? []
        },
        legendary: {
            legendaryActionUses: document.data?.legendaryActionUses ?? null,
            legendaryResistances: document.data?.legendaryResistances ?? null,
            legendaryActions: document.data?.legendaryActions ?? []
        },
        lair: {
            lairActionInitiative: document.data?.lairActionInitiative ?? null,
            lairActionRules: document.data?.lairActionRules ?? null,
            lairActions: document.data?.lairActions ?? [],
            lair: document.data?.lair ?? null
        },
        description: {
            image: document.data?.image ?? null,
            thumbnail: document.thumbnail ?? null,
            altText: document.altText ?? null,
            appearance: document.data?.appearance ?? null,
            behavior: document.data?.behavior ?? null,
            lore: document.data?.lore ?? null
        }
    }),

    serialize: (data) => {
        return {
            title: data.vitals.name,
            description: getDescription(data),
            thumbnail: data.description.thumbnail,
            altText: data.description.altText,
            tags: getTags(data),
            data: {
                ...data.vitals,
                ...data.challenge,
                ...data.defenses,
                ...data.savingThrows,
                ...data.skills,
                ...data.spellcasting,
                ...data.traits,
                ...data.actions,
                ...data.legendary,
                ...data.lair,
                ...data.description,
            }
        }
    },

    applyTemplate: (data, template, overwrite) => {
        function select(a, b) {
            if (overwrite) {
                if (b) {
                    return b;
                }
                return a;
            }
            else {
                if (a) {
                    return a;
                }
                return b;
            }
        }

        function join(a, b) {
            return [...(a ?? []), ...(b ?? [])];
        }

        return {
            vitals: {
                name: data.vitals.name,
                noun: data.vitals.noun,
                alignment: select(data.vitals.alignment, template.vitals.alignment),
                type: select(data.vitals.type, template.vitals.type),
                subtype: select(data.vitals.subtype, template.vitals.subtype),
                size: select(data.vitals.size, template.vitals.size),
                speed: join(data.vitals.speed, template.vitals.speed),
                abilityScores: Object.fromEntries(abilityScoreList.map((ability) =>
                    [ability, (data.vitals.abilityScores?.[ability] ?? 10) + ((template.vitals.abilityScores?.[ability] ?? 10) - 10)]
                )),
                senses: join(data.vitals.senses, template.vitals.senses),
                languages: join(data.vitals.languages, template.vitals.languages),
                tags: join(data.vitals.tags, template.vitals.tags),
                challengeRating: select(data.vitals.challengeRating, template.vitals.challengeRating),
                proficiencyBonus: select(data.vitals.proficiencyBonus, template.vitals.proficiencyBonus)
            },
            challenge: data.challenge,
            defenses: {
                hitPoints: select(data.defenses.hitPoints, template.defenses.hitPoints),
                armorClass: select(data.defenses.armorClass, template.defenses.armorClass),
                armorType: select(data.defenses.armorType, template.defenses.armorType),
                resistances: join(data.defenses.resistances, template.defenses.resistances),
                damageImmunities: join(data.defenses.damageImmunities, template.defenses.damageImmunities),
                conditionImmunities: join(data.defenses.conditionImmunities, template.defenses.conditionImmunities),
                vulnerabilities: join(data.defenses.vulnerabilities, template.defenses.vulnerabilities)
            },
            savingThrows: {
                savingThrows: Object.fromEntries(abilityScoreList.map((ability) =>
                    [ability, {
                        proficiency: select(data.savingThrows.savingThrows[ability].proficiency, template.savingThrows.savingThrows[ability].proficiency),
                        bonus: select(data.savingThrows.savingThrows[ability].bonus, template.savingThrows.savingThrows[ability].bonus)
                    }]
                ))
            },
            skills: {
                skills: Object.fromEntries(skillList.map((skill) => 
                    [skill, {
                        proficiency: select(data.skills.skills[skill].proficiency, template.skills.skills[skill].proficiency),
                        bonus: select(data.skills.skills[skill].bonus, template.skills.skills[skill].bonus)
                    }]
                ))
            },
            spellcasting: {
                spellcastingAbility: select(data.spellcasting.spellcastingAbility, template.spellcasting.spellcastingAbility),
                spellcastingBonus: select(data.spellcasting.spellcastingBonus, template.spellcasting.spellcastingBonus),
                spellLevels: spellLevelList.map((level) => ({
                    slots: select(data.spellcasting.spellLevels[level].slots, template.spellcasting.spellLevels[level].slots),
                    spells: join(data.spellcasting.spellLevels[level].spells, template.spellcasting.spellLevels[level].spells)
                })),
                additionalSpells: join(data.spellcasting.additionalSpells, template.spellcasting.additionalSpells)
            },
            traits: {
                traits: join(data.traits.traits, template.traits.traits)
            },
            actions: {
                attacksPerTurn: select(data.actions.attacksPerTurn, template.actions.attacksPerTurn),
                actions: join(data.actions.actions, template.actions.actions)
            },
            legendary: {
                legendaryActionUses: select(data.legendary.legendaryActionUses, template.legendary.legendaryActionUses),
                legendaryResistances: select(data.legendary.legendaryResistances, template.legendary.legendaryResistances),
                legendaryActions: join(data.legendary.legendaryActions, template.legendary.legendaryActions)
            },
            lair: {
                lairActionInitiative: select(data.lair.lairActionInitiative, template.lair.lairActionInitiative),
                lairActionRules: select(data.lair.lairActionRules, template.lair.lairActionRules),
                lairActions: join(data.lair.lairActions, template.lair.lairActions),
                lair: select(data.lair.lair, template.lair.lair)
            },
            description: {
                image: select(data.description.image, template.description.image),
                thumbnail: select(data.description.thumbnail, template.description.thumbnail),
                altText: select(data.description.altText, template.description.altText),
                appearance: select(data.description.appearance, template.description.appearance),
                behavior: select(data.description.behavior, template.description.behavior),
                lore: select(data.description.lore, template.description.lore)
            }
        }
    }
}

export default Monster;