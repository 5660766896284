import { Box } from '@mui/material';
import { MarkdownViewer } from '../common/MarkdownViewer.js'; 

function ArticleViewer({data}) {   
    return (
        <Box sx={{width: '100%', mt: 1, pl: 1, pr: 1}}>
            <MarkdownViewer text={data.text} />
        </Box>
    );
}

export default ArticleViewer;

