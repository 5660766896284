import { useTheme, useMediaQuery, Typography, Stack, Divider, Paper, Box } from '@mui/material';
import { getItemDescription } from '../../models/item.js';
import { Image } from '../common/Image.js';
import { MarkdownViewer } from '../common/MarkdownViewer.js';
import Section from '../common/Section.js';
import { diceFormulaToString } from '../../dice.js';

function ItemViewer({data}) {
    const theme = useTheme();
    const twoColumns = useMediaQuery(theme.breakpoints.up('lg'));
    const subtitle = getItemDescription(data);
    const charges = diceFormulaToString(data.charges);

    return (
        <Stack spacing={2}>
            <Stack spacing={2} component={Paper} elevation={2} padding={1} width='100%'>
                <Typography variant="subtitle1">{subtitle}</Typography>
                <Stack direction="row" spacing={2} width='100%'>
                    <Stack spacing={0.5} width='100%'>
                        {data.cost && <Section label="Cost">{data.cost}</Section>}
                        {data.charges && 
                            <Section label="Charges">
                                {charges}
                                {data.recharge && ` (${data.recharge})`}
                            </Section>
                        }
                        <Box sx={{width: '100%'}}>
                            <MarkdownViewer text={data.description} />
                        </Box>
                    </Stack>
                    {(twoColumns && data.image) &&
                        <Image src={data.image} alt={data.altText} style={{objectFit: 'cover', maxWidth: '40%' }} />
                    }
                </Stack>
            </Stack>
            { (!twoColumns && data.image) && 
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <Image src={data.image} alt={data.altText} sx={{ maxWidth: '95%', objectFit: 'cover' }} />
                </Box>
            }
        </Stack>
    );
}

export default ItemViewer;
