import { useState, forwardRef, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMediaQuery, AppBar as MuiAppBar, Box, IconButton, Toolbar, Drawer as MuiDrawer, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Container, Paper } from '@mui/material';
import { 
    Menu as MenuIcon, 
    ChevronLeft as ChevronLeftIcon, 
    ChevronRight as ChevronRightIcon, 
    Storage as SourceIcon,
    Photo as ImageIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { TitleBarProvider, TitleBar } from './TitleBar.js';
import { ImageViewer } from './ImageViewer.js';
import { ImageDropper } from './ImageDropper.js';
import { RepositoryCache } from './RepositoryCache.js';
import { UserCache } from './UserCache.js';
import DocumentContext from './DocumentContext.js';
import CreateDocument from './CreateDocument.js';
import SearchBox from './SearchBox.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { documentCategories } from '../../documents.js';

const drawerWidth = 300;
const appBarHeight = '4em';

const ContentPanel = styled('div', { shouldForwardProp: (prop) => prop !== 'isOpen' })(
    ({ theme, isOpen }) => ( {
        padding: 0,
        margin: 0,
        position: 'absolute',
        top: appBarHeight,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            /*
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            */
            marginLeft: 0,
            ...(isOpen && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: `${drawerWidth}px`,
            }),
        },
    }),
);

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'isOpen' })(
    ({ theme, isOpen }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(isOpen && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    })
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function Drawer({close, children, ...props}) {
    const isPersistent = (props.variant === "persistent");

    const closeIfTemporary = useCallback(() => {
        if (!isPersistent) {
            close();
        }
    }, [isPersistent, close]);

    return (
        <MuiDrawer {...props} onClick={closeIfTemporary} onKeyDown={closeIfTemporary}>
            {children}
        </MuiDrawer>
    );
}

const Link = forwardRef(function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

function AppContext(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <UserCache>
                <RepositoryCache>
                    <DocumentContext>
                        <TitleBarProvider>
                            <ImageViewer>
                                <ImageDropper>
                                    {props.children}
                                </ImageDropper>
                            </ImageViewer>
                        </TitleBarProvider>
                    </DocumentContext>
                </RepositoryCache>
            </UserCache>
        </LocalizationProvider>
    );
}

function AppFrame(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const persistentDrawer = useMediaQuery(theme.breakpoints.up('lg'));
    const [isDrawerOpen, setDrawerOpen] = useState(persistentDrawer);

    const handleDrawerOpen = useCallback(() => {
        setDrawerOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const navigateToHomePage = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <AppContext> 
            <Drawer variant={persistentDrawer ? "persistent" : "temporary"} anchor="left" 
                open={isDrawerOpen}
                close={handleDrawerClose}
                sx={{ 
                    width: drawerWidth, 
                    flexShrink: 0, 
                    '& .MuiDrawer-paper': { 
                        width: drawerWidth, boxSizing: 'outline-box' 
                        } 
                }}
                PaperProps={{
                    elevation: 2
                }}
            >
                <DrawerHeader>
                    <div 
                        onClick={navigateToHomePage}
                        style={{ 
                            fontWeight: 700,
                            fontSize: 36,
                            marginLeft: 16,
                            cursor: 'pointer'
                        }}>
                        CODEX
                    </div>
                    <IconButton onClick={handleDrawerClose} sx={{ marginLeft: "auto" }}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding>
                        <SearchBox />
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/sources">
                            <ListItemIcon>
                                <SourceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sources" />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/assets">
                            <ListItemIcon>
                                <ImageIcon />
                            </ListItemIcon>
                            <ListItemText primary="Images" />
                        </ListItemButton>
                    </ListItem>

                    <Divider />

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/sessions">
                            <ListItemIcon>{documentCategories['sessions'].icon}</ListItemIcon>
                            <ListItemText>Sessions</ListItemText>
                        </ListItemButton>
                    </ListItem>   

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/notes">
                            <ListItemIcon>{documentCategories['notes'].icon}</ListItemIcon>
                            <ListItemText>Notes</ListItemText>
                        </ListItemButton>
                    </ListItem>   

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/rules">
                            <ListItemIcon>{documentCategories['rules'].icon}</ListItemIcon>
                            <ListItemText>Rules</ListItemText>
                        </ListItemButton>
                    </ListItem>                           

                    <Divider />

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/lore">
                            <ListItemIcon>{documentCategories['lore'].icon}</ListItemIcon>
                            <ListItemText>Lore</ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/characters">
                            <ListItemIcon>{documentCategories['characters'].icon}</ListItemIcon>
                            <ListItemText>Characters</ListItemText>
                        </ListItemButton>
                    </ListItem>        

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/maps">
                            <ListItemIcon>{documentCategories['maps'].icon}</ListItemIcon>
                            <ListItemText>Maps</ListItemText>
                        </ListItemButton>
                    </ListItem>   

                    <Divider />

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/spells">
                            <ListItemIcon>{documentCategories['spells'].icon}</ListItemIcon>
                            <ListItemText>Spells</ListItemText>
                        </ListItemButton>
                    </ListItem>        

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/items">
                            <ListItemIcon>{documentCategories['items'].icon}</ListItemIcon>
                            <ListItemText>Items</ListItemText>
                        </ListItemButton>
                    </ListItem>                 

                    <Divider />

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/monsters">
                            <ListItemIcon>{documentCategories['monsters'].icon}</ListItemIcon>
                            <ListItemText>Monsters</ListItemText>
                        </ListItemButton>
                    </ListItem>      

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/monster-features">
                            <ListItemIcon>{documentCategories['monster-features'].icon}</ListItemIcon>
                            <ListItemText>Monster Features</ListItemText>
                        </ListItemButton>
                    </ListItem>   

                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/browse/monster-templates">
                            <ListItemIcon>{documentCategories['monster-templates'].icon}</ListItemIcon>
                            <ListItemText>Monster Templates</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            <AppBar isOpen={isDrawerOpen} sx={{position: 'fixed', height: '4em'}}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1, display: 'flex', width: '100%', alignItems: 'center' }}>
                        <IconButton onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(isDrawerOpen && { display: "none" }) }}>
                            <MenuIcon />
                        </IconButton>
                        <TitleBar />
                    </Box>
                </Toolbar>
            </AppBar>

            <ContentPanel id="content-panel" isOpen={isDrawerOpen}>
                <Container id="content-container" component={Paper} elevation={1} disableGutters maxWidth="lg" sx={{height: 'auto', minHeight: '100%'}}>
                    <CreateDocument />
                    {props.children}
                </Container>
            </ContentPanel>
        </AppContext>
    );
}

export default AppFrame;
