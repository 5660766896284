import config from '../config.js';

export async function getUserStatus() {
    try {
        const response = await fetch(config.apiUrl + '/user/status', {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}

export async function loginUser(email, password) {
    try {
        const response = await fetch(config.apiUrl + '/user/login', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({email: email, password: password})
        });     
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}

export async function logoutUser() {
    try {
        const response = await fetch(config.apiUrl + '/user/logout', {
            credentials: 'include',
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }
}

export async function registerUser(email, password, accessCode) {
    try {
        const response = await fetch(config.apiUrl + '/user/register', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({email: email, password: password, accessCode: accessCode})
        });    
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }       
}

export async function getUser(id) {
    try {
        const response = await fetch(config.apiUrl + '/user/get?id=' + id, {
            credentials: 'include'
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }     
}

export async function getApiKey(generate = false) {
    try {
        let url = config.apiUrl + '/user/apiKey';
        if (generate) {
            url += '?generate=1';
        }

        const response = await fetch(url, {
            credentials: 'include'
        });
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }      
}

export async function updateUser(params) {
    try {
        const response = await fetch(config.apiUrl + '/user/update', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(params)
        });    
        if (response.status === 200) {
            return await response.json();
        }
        else {
            return { result: 'error', reason: response.statusText }
        } 
    }
    catch (error) {
        console.error(error);
        return { result: 'error', reason: error.message };
    }     
}
