import { Stack, Typography } from '@mui/material';
import Image from '../common/Image.js';
import { formatBytes } from '../../utils.js';

export default function ImageAssetViewer({data}) {
    return (
        <Stack spacing={2} sx={{maxWidth: '100%', alignItems: 'center'}}>
            <Image src={data.url} alt={data.description} sx={{maxWidth: '100%'}} noTooltip />
            <Typography variant="body2">{formatBytes(data.size)} - {data.contentType}</Typography>
            <Typography variant="subtitle1">{data.description}</Typography>
        </Stack>
    )
}
