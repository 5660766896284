import { useReducer, useCallback, useEffect, useState } from 'react';

function basicReducer(state, action) {
    return action(state);
};

function useStateBlock(initialState, postprocess) {
    const [state, dispatch] = useReducer(basicReducer, initialState);
    const update = useCallback((params) => {
        if (typeof params === 'function') {
            if (postprocess) {
                dispatch((state) => {
                    const result = params(state);
                    const postResult = postprocess(result);
                    return postResult ?? result;
                });
            }
            else {
                dispatch(params);
            }
        }
        else if (typeof params === 'object') {
            if (postprocess) {
                dispatch((state) => { 
                    let result = postprocess({...state, ...params});
                    return result ?? state
                });
            }
            else {
                dispatch((state) => { 
                    const result = {...state, ...params};
                    //console.log(`stateBlock update: state is ${JSON.stringify(state)} -- params is ${JSON.stringify(params)} -- result is ${JSON.stringify(result)}`);
                    return result;
                });
            }
        }
        else {
            throw Error("Invalid parameter to update - must either be a function or an object")
        }
    }, [postprocess]);
    return [state, update];
}

function useStateBlockObject(field, data, update) {
    const updateChild = useCallback((params) => {
        if (typeof params === 'function') {
            update((state) => ({ ...state, [field]: params(state[field]) }))
        }
        else {
            update((state) => ({ ...state, [field]: { ...(state[field] ?? {}), ...params}}));
        }
    }, [field, update]);
    return [data[field], updateChild];
}

function useStateBlockArray(field, data, update) {
    const [baseKey, setBaseKey] = useState(1);

    const addItem = useCallback((item) => {
        update((state) => { 
            return { ...state, [field]: [...(state[field] ?? []), item]}
        });
    }, [field, update]);

    const updateItem = useCallback((index, params) => {
        if (typeof params === 'function') {
            update((state) => { return { ...state, [field]: (state[field] ?? []).map((v, i) => i === index ? params(state[field][i]) : v)}});
        }
        else {
            update((state) => { return { ...state, [field]: (state[field] ?? []).map((v, i) => i === index ? {...v, ...params} : v)}});
        }
    }, [field, update]);

    const removeItem = useCallback((index) => {
        update((state) => { return { ...state, [field]: (state[field] ?? []).filter((v, i) => i !== index)}});
        setBaseKey((baseKey) => baseKey + 1);
    }, [field, update]);

    const swapItem = useCallback((index, newIndex) => {
        update((state) => {
            let array = [...(state[field] ?? [])];
            const tmp = array[index];
            array[index] = array[newIndex];
            array[newIndex] = tmp;
            return { ...state, [field]: array}} 
        );
        setBaseKey((baseKey) => baseKey + 1);
    }, [field, update]);

    const getItemKey = useCallback((index) => {
        return baseKey + (1 / index);
    }, [baseKey]);

    return [data[field] ?? [], addItem, updateItem, removeItem, swapItem, getItemKey];
}

function useStateMonitor(handler, state, initialState) {
    useEffect(() => {
        if (state !== initialState) {
            handler(state);
        }
    }, [state, initialState, handler]);
}

const units = ['bytes', 'KiB', 'MiB', 'GiB'];
   
function formatBytes(x){
  let l = 0, n = parseInt(x, 10) || 0;

  while(n >= 1024 && ++l){
      n = n/1024;
  }
  
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export { useStateBlock, useStateBlockArray, useStateBlockObject, useStateMonitor, formatBytes };
