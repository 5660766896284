import { useCallback } from 'react';
import { Grid, TextField } from '@mui/material';
import SelectBox from '../common/SelectBox.js';
import NumberField from '../common/NumberField.js';
import MarkdownField from '../common/MarkdownField.js';
import ComboBox from '../common/ComboBox.js';
import DiceFormulaField from '../common/DiceFormulaField.js';
import { TagPicker } from '../common/TagPicker';
import { actionLimitSuggestions } from '../../suggestions.js';

const featureTypes = [
    'trait',
    'action',
    'bonusAction',
    'reaction',
    'legendaryAction',
    'lairAction'
];

const featureTypeLabels = {
    'trait': 'Trait',
    'action': 'Action',
    'bonusAction': 'Bonus Action',
    'reaction': 'Reaction',
    'legendaryAction': 'Legendary Action',
    'lairAction': 'Lair Action'
};

function getFeatureTypeLabel(featureType) {
    return featureTypeLabels[featureType] ?? '[invalid]';
}

function TraitEditor({data, update}) {
    const getGenerationPrompt = useCallback(() => 
        `Provide a one-sentence mechanical description for a monster trait called '${data.name}'.`
    , [data.name]);

    return (
        <Grid item xs={12} order={10}>
            <MarkdownField label="Description" fullWidth rows={8}
                getGenerationPrompt={getGenerationPrompt}
                value={data.description ?? ''}
                onChange={(v) => update({description: v})}
            />
        </Grid>
    )
}

function LairActionEditor({data, update}) {
    const getGenerationPrompt = useCallback(() => 
        `Provide a one-sentence mechanical description for a monster lair action called '${data.name}'.`
    , [data.name]);

    return (
        <Grid item xs={12} order={10}>
            <MarkdownField label="Description" fullWidth rows={8}
                getGenerationPrompt={getGenerationPrompt}
                value={data.description ?? ''}
                onChange={(v) => update({description: v})}
            />
        </Grid>
    )
}

function LegendaryActionEditor({data, update}) {
    const getGenerationPrompt = useCallback(() => 
        `Provide a one-sentence mechanical description for a monster legendary action called '${data.name}'.`
    , [data.name]);

    return (<>
        <Grid item xs={12} order={10}>
            <NumberField label="Legendary Actions Used" fullWidth integer
                value={data.legendaryActions ?? null}
                onChange={(e, v) => update({legendaryActions: v})}
            />      
        </Grid>
        <Grid item xs={12} order={11}>
            <MarkdownField label="Description" fullWidth rows={8}
                getGenerationPrompt={getGenerationPrompt}
                value={data.description ?? ''}
                onChange={(v) => update({description: v})}
            />
        </Grid>
    </>);
}

function ActionEditor({data, update}) {
    const getGenerationPrompt = useCallback(() => 
        `Provide a one-sentence mechanical description for a monster action called '${data.name}'.`
    , [data.name]);

    return (<>
        <Grid item xs={6} order={7}>
            <ComboBox label="Limit" fullWidth
                options={actionLimitSuggestions}
                value={data.limit ?? null}
                onChange={(e, v) => update({limit: v})}
            />
        </Grid>
        <Grid item xs={6} order={8}>
            <DiceFormulaField label="Est. Damage" fullWidth
                value={data.damage ?? null}
                onChange={(e, v) => update({damage: v})}
            />
        </Grid>
        <Grid item xs={12} order={11}>
            <MarkdownField label="Description" fullWidth rows={8}
                getGenerationPrompt={getGenerationPrompt}
                value={data.description ?? ''}
                onChange={(v) => update({description: v})}
            />
        </Grid>
    </>);
}

function MonsterFeatureEditor({data, update}) {
    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12} lg={8} order={1}>
                <TextField label="Name" fullWidth autoComplete="off" required
                        value={data.name ?? ''}
                        onChange={(e) => update({name: e.target.value})}
                    />
            </Grid>
            <Grid item xs={6} lg={4} order={2}>
                <SelectBox label="Type" fullWidth required
                    options={featureTypes}
                    getOptionLabel={getFeatureTypeLabel}
                    value={data.type ?? null}
                    onChange={(e, v) => update({type: v})}
                />
            </Grid>
            <Grid item xs={12} lg={8} order={{xs: 4, lg: 3}}>
                <TagPicker label="Tags" fullWidth
                    category="monster-features"
                    value={data.tags ?? []}
                    onChange={(e, v) => update({tags: v})}
                />
            </Grid>
            <Grid item xs={6} lg={4} order={{xs: 3, lg: 4}}>
                <NumberField label="CR Adjustment" fullWidth
                    value={data.cr ?? null}
                    onChange={(e, v) => update({cr: v})}
                />
            </Grid>
            { data.type === 'trait' && <TraitEditor data={data} update={update} /> }
            { data.type === 'legendaryAction' && <LegendaryActionEditor data={data} update={update} /> }
            { data.type === 'lairAction' && <LairActionEditor data={data} update={update} /> }
            { (data.type === 'action' || data.type === 'bonusAction' || data.type === 'reaction') && <ActionEditor data={data} update={update} /> }
        </Grid>
    )
}

export default MonsterFeatureEditor;
export { getFeatureTypeLabel };
