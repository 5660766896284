import { createContext, useContext, useState, useCallback, useReducer, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery, Typography, Stack, IconButton, Avatar, Menu, MenuItem } from '@mui/material';
import { Person as PersonIcon, Login as LoginIcon, MoreHoriz as ExpandIcon, KeyboardArrowRight as CollapseIcon } from '@mui/icons-material';
import { useUserCache, useAuth } from '../../globals.js';

const TitleBarContext = createContext(null);

function useTitleBar() {
    return useContext(TitleBarContext);
}

function widgetReducer(widgets, params) {
    if (params.action === 'add') {
        const filteredWidgets = widgets.filter((w) => w.key !== params.widget.key);
        const newWidgets = [...filteredWidgets, params.widget];
        return newWidgets;
    }
    else if (params.action === 'remove') {
        const newWidgets = widgets.filter((w) => w.key !== params.key);
        return newWidgets;       
    }
}

function TitleBarProvider(props) {
    const [title, setTitle] = useState('Untitled');
    const [widgets, dispatchWidgets] = useReducer(widgetReducer, []);

    const setTitleWrapper = useCallback((newTitle) => {
        setTitle(newTitle);
        document.title = newTitle + " - Codex";
    }, []);

    const addWidget = useCallback((widget) => {
        dispatchWidgets({action: 'add', widget: widget});
    }, []);

    const removeWidget = useCallback((key) => {
        dispatchWidgets({action: 'remove', key: key});
    }, []);

    return (
        <TitleBarContext.Provider value={{
            title: title,
            setTitle: setTitleWrapper,
            widgets: widgets,
            addWidget: addWidget,
            removeWidget: removeWidget,
        }}>
            {props.children}
        </TitleBarContext.Provider>
    );   
}

const AvatarMenu = memo(() => {
    const { localUser } = useUserCache();
    const navigate = useNavigate();
    const [anchor, setAnchor] = useState(null);
    const isOpen = Boolean(anchor);
    const { isLoggedIn, logout, require } = useAuth();
    
    const close = useCallback(() => setAnchor(null), []);
    const profile = useCallback(() => navigate(`/user/${localUser?.id}/edit`), [localUser?.id, navigate]);

    const clicked = useCallback((e) => {
        if (!isLoggedIn) {
            require();
        }
        else {
            setAnchor(e.target);
        }
    }, [isLoggedIn, require]);

    return (<>
        <IconButton onClick={clicked}>
            <Avatar src={localUser?.thumbnail} alt={localUser?.altText}>
                { isLoggedIn ? <PersonIcon /> : <LoginIcon /> }
            </Avatar>
        </IconButton>
        { isOpen &&
            <Menu anchorEl={anchor} open={true} onClose={close}>
                <MenuItem onClick={profile}>Profile</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        }
    </>);
});

function CollapsableWidgets({children}) {
    const [isCollapsed, setCollapsed] = useState(true);

    return (
        <Stack direction="row" alignItems="center">
            { isCollapsed && <IconButton onClick={() => setCollapsed(false)}><ExpandIcon /></IconButton>}
            { !isCollapsed && <>
                {children}
                <IconButton onClick={() => setCollapsed(true)}><CollapseIcon /></IconButton>
            </>}
        </Stack>
    );
}

function TitleBar() {
    const theme = useTheme();
    const centered = useMediaQuery(theme.breakpoints.up('lg'));
    const collapsable = useMediaQuery(theme.breakpoints.down('md'));
    const titleBar = useTitleBar();

    return ( 
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{display: 'flex', width: '100%', minWidth: 0}}>
                <div style={{flex: '1 1 100%', minWidth: 0}}>
                    <Typography component="div" variant="h1"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '1.5em',
                            ...(centered && {
                                display: 'flex',
                                justifyContent: 'center'
                            })
                        }}
                    >
                        {titleBar.title}
                    </Typography>
                </div>
                { collapsable ?
                    <CollapsableWidgets>
                                {titleBar.widgets}
                                <AvatarMenu />
                    </CollapsableWidgets>    
                    :
                    <Stack direction="row" alignItems="center">
                        {titleBar.widgets}
                        <AvatarMenu />
                    </Stack>           
                }
            </Stack>
        </>
    );
}

export default TitleBar;
export { useTitleBar, TitleBarProvider, TitleBar };
