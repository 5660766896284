import { Fragment, useEffect } from 'react';
import { RouterProvider, Route, Outlet, useLocation, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { SourcesPage } from './SourcesPage.js';
import { BrowsePage } from "./BrowsePage.js";
import { HomePage } from './HomePage.js';
import DocumentViewPage from './DocumentViewPage.js';
import DocumentDraftPage from './DocumentDraftPage.js';
import DocumentEditPage from './DocumentEditPage.js';
import ProfileEditPage from './ProfileEditPage.js';
import SearchPage from './SearchPage.js';
import ErrorPage from './ErrorPage.js';
import AppFrame from './AppFrame.js';

function Remount(props) {
    const location = useLocation();
    return (
        <Fragment key={location.key}>
            {props.children}
        </Fragment>
    );
}

function RouterDebug() {
/*
    const location = useLocation();
    useEffect(() => {
        console.log(`nav: ${location.pathname} | ${location.hash} | ${JSON.stringify(location.state ?? {})}`);
    }, [location]);
*/
}

function AppShell() {
    return (
        <AppFrame>
            <RouterDebug />
            <Outlet />
        </AppFrame>
    )
}

function ErrorShell() {
    return (
        <AppFrame>
            <ErrorPage />
        </AppFrame>
    )
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<AppShell />} errorElement={<ErrorShell />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/sources" element={<SourcesPage />} />
                <Route path="/search/:text" element={<Remount><SearchPage /></Remount>} />
                <Route path="/browse/:category" element={<Remount><BrowsePage /></Remount>} />
                <Route path="/document/:id" element={<Remount><DocumentViewPage /></Remount>} />
                <Route path="/document/:id/edit" element={<Remount><DocumentEditPage /></Remount>} />
                <Route path="/document/draft/:category/:type" element={<Remount><DocumentDraftPage /></Remount>} />
                <Route path="/document/clone/:id" element={<Remount><DocumentDraftPage /></Remount>} />
                <Route path="/user/:id/edit" element={<Remount><ProfileEditPage /></Remount>} />
            </Route>
        </>           
    )
);

export default function AppRouter() {

    return (
        <RouterProvider router={router} />
    );
}
