import { useState, useCallback, createContext, useContext, forwardRef, useRef, useEffect } from 'react';
import { VariableSizeList } from 'react-window';
import { InputAdornment, useTheme, useMediaQuery, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { listDocuments } from '../../api.js';
import { getDocumentCategory } from '../../documents.js';
import { useRepositoryCache } from '../app/RepositoryCache.js';
import { ComboBox } from './ComboBox.js';
import { SelectBox } from './SelectBox.js';

function isOptionEqualToValue(a, b) {
    return a.id === b.id;
}

const LISTBOX_PADDING = 8; // px

function Row(props) {
    const { data: items, index, style } = props;
    const [itemProps, itemData, itemState] = items[index];
    const { getRepository } = useRepositoryCache();
    const inlineStyle = {
      ...style,
      top: style.top + LISTBOX_PADDING,
    };

    const category = getDocumentCategory(itemData.category);
    const repoName = getRepository(itemData.repository)?.title;

    return (
        <ListItem {...itemProps} 
            style={inlineStyle}
            secondaryAction={repoName && 
                <Typography variant="body2" sx={{maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{repoName}</Typography>
            }
        >
            { category?.icon &&
                <ListItemIcon>
                    {category.icon}
                </ListItemIcon>
            }
            <ListItemText>
                {itemData.label}
            </ListItemText>
        </ListItem>
    );
  }

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) =>  {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
}
 
const VirtualizedList = forwardRef(({children: items, ...props}, ref) => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = items?.length ?? 0;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
          return 48;
        }
    
        return itemSize;
    };
    
    const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return items?.map(getChildSize).reduce((a, b) => a + b, 0) ?? 0;
    };
    
    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={props}>
                <VariableSizeList
                    itemData={items}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(items[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {Row}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    )
});

const DocumentPicker = forwardRef(({query, freeSolo, value, ...props}, ref) => {
    const [options, setOptions] = useState();

    const isLink = (value?.id && true);

    const fetchOptions = useCallback(async () => {
        if (!options) {
            const res = await listDocuments(query ?? {});
            if (res.result === 'success') {
                const newOptions = res.documents.map((doc) => { return {
                    label: doc.title,
                    category: doc.category,
                    type: doc.type,
                    repository: doc.repository,
                    id: doc.id
                }});
                setOptions(newOptions);
                return newOptions;
            }
        }
        return options;
    }, [options, query]);

    const fallbackOptions = value ? [value] : [];

    if (freeSolo) {
        return <ComboBox {...props} 
            value={value} 
            fetchOptions={fetchOptions} 
            options={fallbackOptions} 
            isOptionEqualToValue={isOptionEqualToValue}
            InputProps={{
                startAdornment: isLink && <InputAdornment position="start"><LinkIcon fontSize="small" /></InputAdornment>
            }}
            ListboxComponent={VirtualizedList}
            renderOption={(props, option, state) => [props, option, state]}
        />;
    }
    else {
        return <SelectBox {...props} value={value} fetchOptions={fetchOptions} options={fallbackOptions} isOptionEqualToValue={isOptionEqualToValue}
            InputProps={{
                startAdornment: isLink && <InputAdornment position="start"><LinkIcon fontSize="small" /></InputAdornment>
            }}
            ListboxComponent={VirtualizedList}
            renderOption={(props, option, state) => [props, option, state]}
        />;
    }
});

export default DocumentPicker;
export { DocumentPicker };
