import { useState, useCallback, forwardRef, useRef, createContext, useContext, useEffect } from 'react';
import { VariableSizeList } from 'react-window';
import { useTheme, useMediaQuery, ListItem, ListItemIcon, ListItemText, Chip, Typography } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { listDocuments } from '../../api.js';
import { getDocumentCategory } from '../../documents.js';
import { useRepositoryCache } from '../app/RepositoryCache.js';
import { MultiComboBox } from './MultiComboBox.js';
import { MultiSelectBox } from './MultiSelectBox.js';

const LISTBOX_PADDING = 8; // px

function Row(props) {
    const { data: items, index, style } = props;
    const [itemProps, itemData, itemState] = items[index];
    const { getRepository } = useRepositoryCache();
    const inlineStyle = {
      ...style,
      top: style.top + LISTBOX_PADDING,
    };

    const category = getDocumentCategory(itemData.category);
    const repoName = getRepository(itemData.repository)?.title;

    return (
        <ListItem {...itemProps} 
            style={inlineStyle}
            secondaryAction={repoName && 
                <Typography variant="body2" sx={{maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{repoName}</Typography>
            }
        >
            { category?.icon &&
                <ListItemIcon>
                    {category.icon}
                </ListItemIcon>
            }
            <ListItemText>{itemData.label}</ListItemText>
        </ListItem>
    );
  }

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) =>  {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
}
 
const VirtualizedList = forwardRef(({children: items, ...props}, ref) => {
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = items?.length ?? 0;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
          return 48;
        }
    
        return itemSize;
    };
    
    const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return items?.map(getChildSize).reduce((a, b) => a + b, 0) ?? 0;
    };
    
    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={props}>
                <VariableSizeList
                    itemData={items}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(items[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {Row}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    )
});

const linkIcon = <LinkIcon />;

const MultiDocumentPicker = forwardRef(({query, freeSolo, ...props}, ref) => {
    const [options, setOptions] = useState();

    const fetchOptions = useCallback(async () => {
        if (!options) {
            const res = await listDocuments(query ?? {});
            if (res.result === 'success') {
                const newOptions = res.documents
                    .map((doc) => ({ 
                        label: doc.title, 
                        id: doc.id,
                        category: doc.category,
                        repository: doc.repository,
                        type: doc.type
                    }));
                setOptions(newOptions);
                return newOptions;
            }
            else {
                throw Error('Failed to load documents');
            }
        }
        return options;
    }, [options, query]);

    const renderTags = (tags, getTagProps) => 
        tags.map((value, index) => {
            const tagProps = getTagProps(index);
            if (typeof value === 'string') {
                return <Chip {...tagProps} key={index} label={value} />
            }
            else {
                return <Chip {...tagProps} key={index} label={value.label} icon={linkIcon} />
            }
        });

    if (freeSolo) {
        return <MultiComboBox {...props} 
            fetchOptions={fetchOptions} 
            options={[]}
            ref={ref} 
            ListboxComponent={VirtualizedList}
            renderOption={(props, option, state) => [props, option, state]}
            renderTags={renderTags}
        />;
    }
    else {
        return <MultiSelectBox {...props} 
            fetchOptions={fetchOptions} 
            options={[]}
            ref={ref} 
            ListboxComponent={VirtualizedList}
            renderOption={(props, option, state) => [props, option, state]}
            renderTags={renderTags}
        />;
    }
});

export { MultiDocumentPicker };
