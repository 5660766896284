import { forwardRef, useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { useImageViewer} from '../../globals.js';
import { styled } from '@mui/material/styles';

const StyledImg = styled('img', {}) ({
    cursor: 'pointer'
});

const BaseImage = forwardRef(({src, alt, noTooltip, ...props}, ref) => {
    const { open } = useImageViewer();

    const handleClick = useCallback(() => {
        open(src);
    }, [src, open]);

    return (
        <Tooltip title={noTooltip ? undefined : alt}>
           <StyledImg onClick={handleClick} src={src} alt={alt} {...props} ref={ref} />     
        </Tooltip>
    );
});

const Image = styled(BaseImage)({
    borderRadius: '24px'
});

export default Image;
export { Image };
