import { Place as PointOfInterestIcon } from '@mui/icons-material';
import { AnchorIcon, BattleIcon, BridgeIcon, CastleIcon, CaveIcon, CityIcon, IslandIcon, PortalIcon, MonsterIcon, MountainIcon, ObeliskIcon, RuinsIcon, ShipwreckIcon,
    TempleIcon, TentIcon, TrapIcon, TreasureIcon, VillageIcon, ShopIcon, TavernIcon, HouseIcon, ForestIcon, RiverIcon, RoadIcon, TowerIcon, CrownIcon} from '../../icons.js';

export const landmarkIcons = {
    poi: { label: 'Point of Interest', icon: <PointOfInterestIcon /> },
    anchor: { label: 'Anchor', icon: <AnchorIcon /> },
    battle: { label: 'Battle', icon: <BattleIcon /> },
    bridge: { label: 'Bridge', icon: <BridgeIcon /> },
    castle: { label: 'Castle', icon: <CastleIcon /> },
    cave: { label: 'Cave', icon: <CaveIcon /> },
    city: { label: 'City', icon: <CityIcon /> },
    crown: { label: 'Crown', icon: <CrownIcon /> },
    forest: { label: 'Forest', icon: <ForestIcon /> },
    house: { label: 'House', icon: <HouseIcon /> },
    island: { label: 'Island', icon: <IslandIcon /> },
    portal: { label: 'Portal', icon: <PortalIcon /> },
    monster: { label: 'Monster', icon: <MonsterIcon /> },
    mountain: { label: 'Mountain', icon: <MountainIcon /> },
    obelisk: { label: 'Obelisk', icon: <ObeliskIcon /> },
    river: { label: 'River', icon: <RiverIcon /> },
    road: { label: 'Road', icon: <RoadIcon /> },
    ruins: { label: 'Ruins', icon: <RuinsIcon /> },
    shipwreck: { label: 'Shipwreck', icon: <ShipwreckIcon /> },
    temple: { label: 'Temple', icon: <TempleIcon /> },
    tower: { label: 'Tower', icon: <TowerIcon /> },
    camp: { label: 'Camp', icon: <TentIcon /> },
    shop: { label: 'Shop', icon: <ShopIcon /> },
    tavern: { label: 'Tavern', icon: <TavernIcon /> },
    trap: { label: 'Trap', icon: <TrapIcon /> },
    treasure: { label: 'Treasure', icon: <TreasureIcon /> },
    village: { label: 'Village', icon: <VillageIcon /> },
};

export const landmarkIconKeys = Object.keys(landmarkIcons);

export function getLandmarkIconLabel(key) {
    return landmarkIcons[key]?.label ?? 'Unknown';
}

