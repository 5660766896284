const diceFormulaRegex = /\s*((\d+)?d(\d+))?\s*(([+-])?\s*(\d+)?)\s*?$/ig;

function diceFormulaToString(formula) {
    let result = '';
    if (formula?.n !== 0 && formula?.m) {
        result += formula.n;
    }
    if (formula?.m) {
        result += 'd' + formula.m;
    }
    if (formula?.c) {
        if (result) {
            if (formula.c >= 0) {
                result += ' + ' + formula.c;
            }
            else {
                result += result + ' - ' + Math.abs(formula.c);
            }
        }
        else {
            result = formula.c.toString();
        }
    }
    return result;
}

function parseDiceFormula(str) {
    const values = [...str.matchAll(diceFormulaRegex)][0];
    if (values[3] || values[6]) {
        let isValid = false;

        let n = Number(values[2] ?? 1);
        if (isNaN(n)) {
            n = 1;
        }

        let m = Number(values[3] ?? 0);
        if (isNaN(m)) {
            m = 0;
        }
        else {
            isValid = true;
        }

        const sign = (values[5] === '-') ? -1 : 1;
        let c = Number(values[6]);
        if (isNaN(c)) {
            c = 0;
        }
        else {
            isValid = true;
        }
        c *= sign;

        if (isValid) {
            return {
                n: n,
                m: m,
                c: c
            }
        }
    }
    return null;
}

function getDiceFormulaMetrics(formula) {
    const n = (formula?.n ?? 0);
    const m = (formula?.m ?? 0);
    const c = (formula?.c ?? 0);

    const min = (m > 0 ? n : 0) + c;
    const max = (n * m) + c;
    const avg = (max + min) * 0.5;
    return { min: min, max: max, avg: avg };
}

function makeDiceFormula(dieCount, dieSize, bonus) {
    return { n: dieCount ?? 1, m: dieSize ?? 0, c: bonus ?? 0 };
}

export { diceFormulaToString, parseDiceFormula, getDiceFormulaMetrics, makeDiceFormula };
