import { useCallback, useState, useEffect } from 'react';
import { useRepositoryCache } from '../../globals.js';
import { listRepositories } from '../../api.js';
import MultiSelectBox from './MultiSelectBox.js';

export default function MultiRepositoryPicker({label, value, onChange, minRole, ...props}) {
    const [repositoryList, setRepositoryList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { getRepository } = useRepositoryCache();

    const getRepositoryLabel = useCallback((id) => getRepository(id)?.title ?? 'Loading...', [getRepository]);

    const fetchRepositoryList = useCallback(async (minRole) => {
        setIsLoading(true);
        const res = await listRepositories();
        if (res.result === 'success') {
            let newRepoList = [];
            for (let repo of res.repositories) {
                let allow = true;
                if (minRole === 'owner') {
                    allow = (repo.role === 'owner');
                }
                else if (minRole === 'editor') {
                    allow = (repo.role === 'owner' || repo.role === 'editor');
                }
                else if (minRole === 'viewer') {
                    allow = (repo.role === 'owner' || repo.role === 'editor' || repo.role === 'viewer');
                }

                if (allow) {
                    newRepoList.push(repo.id);
                }
            }
            setRepositoryList(newRepoList);
        }
        else {
            setRepositoryList([]);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!isLoading && !repositoryList) {
            fetchRepositoryList(minRole);
        }
    }, [fetchRepositoryList, repositoryList, minRole, isLoading]);

    return (     
        <MultiSelectBox label={label} fullWidth
            options={repositoryList ?? []} 
            getOptionLabel={getRepositoryLabel}
            loading={isLoading}
            value={value}
            onChange={onChange}
            {...props}
        />
    );
}
