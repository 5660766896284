import { formatSentence } from '../text';

function getDescription(data) {
    let desc = '';
    if (data.vitals.alignment) {
        desc += `${data.vitals.alignment} `;
    }
    if (data.vitals.species) {
        desc += `${data.vitals.species} `;
    }
    if (data.vitals.pronouns) {
        desc += `(${data.vitals.pronouns})`;
    }
    if (data.vitals.epithet) {
        if (desc) {
            desc = `${data.vitals.epithet}, ${desc}`;
        }
        else {
            desc = data.vitals.epithet;
        }
    }
    return formatSentence(desc).trim();
}

const Character = {
    name: 'Character',
    plural: 'Characters',

    init: () => Character.deserialize({}),
    
    validate: (data) => {
        let errors = [];
        if (!data.vitals.name) {
            errors.push('A name is required.');
        }
        return errors;
    },

    deserialize: (document) => ({
        vitals: {
            name: document.title ?? null,
            tags: document.tags ?? [],
            epithet: document.data?.epithet ?? null,
            pronouns: document.data?.pronouns ?? null,
            species: document.data?.species ?? null,
            alignment: document.data?.alignment ?? null,
            age: document.data?.age ?? null,
            statBlock: document.data?.statBlock ?? null
        },
        appearance: {
            text: document.data?.appearance ?? null,
            image: document.data?.image ?? null,
            thumbnail: document.thumbnail ?? null,
            altText: document.altText ?? null
        },
        personality: {
            ideal: document.data?.ideal ?? null,
            bond: document.data?.bond ?? null,
            flaw: document.data?.flaw ?? null,
            text: document.data?.personality ?? null,
        },
        biography: {
            text: document.data?.biography ?? null,
        },
        notes: {
            text: document.data?.notes ?? null,
        },
        relationships: document.data?.relationships ?? [],
    }),
    
    serialize: (data) => ({
        title: data.vitals.name,
        tags: data.vitals.tags,
        description: getDescription(data),
        thumbnail: data.appearance.thumbnail,
        altText: data.appearance.altText,
        data: {
            epithet: data.vitals.epithet,
            pronouns: data.vitals.pronouns,
            species: data.vitals.species,
            alignment: data.vitals.alignment,
            age: data.vitals.age,
            statBlock: data.vitals.statBlock,
            appearance: data.appearance.text,
            image: data.appearance.image,
            personality: data.personality.text,
            ideal: data.personality.ideal,
            bond: data.personality.bond,
            flaw: data.personality.flaw,
            biography: data.biography.text,
            notes: data.notes.text,
            relationships: data.relationships,
        }
    })
}

export default Character;


