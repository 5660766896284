import { useState, forwardRef, useCallback } from 'react';
import { TextField } from '@mui/material';

const NumberField = forwardRef(({label, value, onChange, integer, autoComplete, ...props}, ref) => {
    const [inputValue, setInputValue] = useState();
    const [error, setError] = useState(false);
    
    const handleChange = useCallback((e) => {
        setInputValue(e.target.value);

        if (e.target.value === ''){
            setError(false);
            onChange(e, null);
        }
        else {
            const num = Number(e.target.value);
            if (!isNaN(num) && (!integer || num === Math.trunc(num))) { 
                if (onChange) {
                    onChange(e, num);
                }
                setError(false);
            }
            else {
                setError(true);
            }
        }
    }, [onChange, integer]);

    const handleBlur = useCallback(() => {
        setInputValue();
        setError(false);
    }, []);

    const handleFocus = useCallback(() => {
        setInputValue(value?.toString() ??'');
    }, [value]);

    return (
        <TextField 
            {...props}
            label={label} 
            autoComplete={autoComplete ?? "off"}
            value={inputValue ?? (value?.toString() ?? '')}
            onChange={handleChange} 
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={error}
            ref={ref}
        />
    ); 
});

export default NumberField;
export { NumberField };
