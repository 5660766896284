import { useState, useEffect, useCallback, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import { Link, Popper, Paper, Typography, Stack, Box } from '@mui/material';
import { useStateBlock } from '../../utils.js';
import { getDocument } from '../../api.js';
import { getDocumentType } from '../../documents.js';
import { useRepositoryCache } from '../../globals.js';

function DocumentPreview({documentId, data, update}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const { getRepository } = useRepositoryCache();

    const fetchDocument = useCallback(async (id) => {
        setIsLoading(true);
        const res = await getDocument(id, false);
        if (res.result === 'success') {
            const docType = getDocumentType(res.type);
            if (docType) {
                const documentData = docType.deserialize(res);
                update(() => ({
                    title: res.title,
                    type: res.type,
                    description: res.description,
                    repository: res.repository,
                    data: documentData
                }));
            }
            else {
                setError("Unknown document type.");
            }
        }
        else {
            setError("Failed to retrieve document.");
        }
        setIsLoading(false);
    }, [update]);

    useEffect(() => {
        if (!isLoading && !data) {
            fetchDocument(documentId);
        }
    });

    let repoName;
    if (data?.repository) {
        repoName = getRepository(data?.repository)?.title;
    }

    let preview;
    if (data?.type) {
        preview = getDocumentType(data.type)?.preview?.({data: data.data});
    }

    return (
        data &&
            <Box component={Paper} elevation={5}
                sx={{
                    padding: 1,
                    margin: 1,
                }}
            >
                <Stack spacing={0} 
                    sx={{
                        maxWidth: '30em', 
                        maxHeight: '14em', 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis',
                        maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0em, rgba(0, 0, 0, 1) 10em, rgba(0, 0, 0, 0) 14em)'    
                    }}
                >
                    <Stack direction="row" sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant="h4">{data.title}</Typography>
                        { repoName && <Typography variant="body2">{repoName}</Typography> }
                    </Stack>
                    <Typography variant="subtitle2">{data.description}</Typography>
                    { preview }
                </Stack>
            </Box>               
    );
}

function DocumentLink({href, documentId, children, ...props}) {
    const [data, update] = useStateBlock();
    const popupState = usePopupState({ variant: 'popper' });

    return (
        <>
            <Popper {...bindPopper(popupState)}>
                {popupState.isOpen &&
                    <DocumentPreview documentId={documentId} data={data} update={update} />
                }
            </Popper>
            <Link component={RouterLink} to={href} {...props} {...bindHover(popupState)}>
                {children}
            </Link>
        </>
    );
}

const PreviewLink = memo(({href, children, ...props}) => {
    const [documentId, setDocumentId] = useState();

    useEffect(() => {
        const documentRegex = /\/document\/([a-z\d]{24})/g;
        const match = [...href.matchAll(documentRegex)][0]?.[1];
        if (match) {
            setDocumentId(match);
        }
    }, [href]);

    if (documentId) {
        return (
            <DocumentLink href={href} documentId={documentId} {...props}>
                {children}
            </DocumentLink>
        );
    }
    else {
        return (
            <Link component={RouterLink} to={href} {...props}>
                {children}
            </Link>
        );
    }
});

export default PreviewLink;

