import {     
    Person as CharacterIcon, 
    Rule as RuleIcon, 
    Note as NoteIcon, 
    EventNote as SessionIcon, 
    AutoStories as LoreIcon,
    Pets as MonsterIcon,
    AutoAwesome as SpellIcon,
    Backpack as ItemIcon,
    Photo as AssetIcon,
    Spa as MonsterFeatureIcon,
    MapOutlined as MapIcon,
    PetsOutlined as MonsterTemplateIcon
 } from '@mui/icons-material';
import Article from './models/article.js';
import Character from './models/character.js';
import Session from './models/session.js';
import Spell from './models/spell.js';
import Monster from './models/monster.js';
import Item from './models/item.js';
import Image from './models/image.js';
import MonsterFeature from './models/monsterFeature.js';
import MonsterTemplate from './models/monsterTemplate.js';
import Map from './models/map.js';
import { MonsterFilters } from './components/doc/MonsterFilters.js';
import { SpellFilters } from './components/doc/SpellFilters.js';
import ItemFilters from './components/doc/ItemFilters.js';
import ImageAssetBrowser from './components/doc/ImageAssetBrowser.js';
import { DragonHeadIcon, DragonHeadOutlinedIcon, ClawIcon } from './icons.js';

import ArticleViewer from './components/doc/ArticleViewer.js';
import ArticleEditor from './components/doc/ArticleEditor.js';
import CharacterViewer from './components/doc/CharacterViewer.js';
import CharacterEditor from './components/doc/CharacterEditor.js';
import SessionViewer from './components/doc/SessionViewer.js';
import SessionEditor from './components/doc/SessionEditor.js';
import SpellViewer from './components/doc/SpellViewer.js';
import SpellEditor from './components/doc/SpellEditor.js';
import SpellPreview from './components/doc/SpellPreview.js';
import MonsterViewer from './components/doc/MonsterViewer.js';
import MonsterEditor from './components/doc/MonsterEditor.js';
import ItemViewer from './components/doc/ItemViewer.js';
import ItemEditor from './components/doc/ItemEditor.js';
import MonsterFeatureViewer from './components/doc/MonsterFeatureViewer.js';
import MonsterFeatureEditor from './components/doc/MonsterFeatureEditor.js';
import ImageAssetViewer from './components/doc/ImageAssetViewer.js';
import ImageAssetEditor from './components/doc/ImageAssetEditor.js';
import MapViewer from './components/doc/MapViewer.js';
import MapEditor from './components/doc/MapEditor.js';

const documentTypes = {
    'article': {
        ...Article,
        viewer: (props) => <ArticleViewer {...props} />,
        editor: (props) => <ArticleEditor {...props} />
    },
    'character': {
        ...Character,
        viewer: (props) => <CharacterViewer {...props} />,
        editor: (props) => <CharacterEditor {...props} />,
    },
    'session': {
        ...Session,
        viewer: (props) => <SessionViewer {...props} />,
        editor: (props) => <SessionEditor {...props} />
    },
    'spell': {
        ...Spell,
        viewer: (props) => <SpellViewer {...props} />,
        editor: (props) => <SpellEditor {...props} />,
        preview: (props) => <SpellPreview {...props} />
    },
    'monster': {
        ...Monster,
        viewer: (props) => <MonsterViewer {...props} />,
        editor: (props) => <MonsterEditor {...props} />,
    },
    'item': {
        ...Item,
        viewer: (props) => <ItemViewer {...props} />,
        editor: (props) => <ItemEditor {...props} />,
    },
    'image': {
        ...Image,
        viewer: (props) => <ImageAssetViewer {...props} />,
        editor: (props) => <ImageAssetEditor {...props} />,
    },
    'monster-feature': {
        ...MonsterFeature,
        viewer: (props) => <MonsterFeatureViewer {...props} />,
        editor: (props) => <MonsterFeatureEditor {...props} />,
    },
    'monster-template': {
        ...MonsterTemplate,
        viewer: (props) => <MonsterViewer {...props} template />,
        editor: (props) => <MonsterEditor {...props} template />,
    },
    'map': { 
        ...Map,
        viewer: (props) => <MapViewer {...props} />,
        editor: (props) => <MapEditor {...props} />
    },
};
Object.freeze(documentTypes);

const documentCategories = {
    'lore': {
        name: 'Lore',
        plural: 'Lore',
        types: ['article'],
        icon: <LoreIcon />,
    },
    'sessions': {
        name: 'Session',
        plural: 'Sessions',
        types: ['session'],
        icon: <SessionIcon />
    },
    'notes': {
        name: 'Note',
        plural: 'Notes',
        types: ['article'],
        icon: <NoteIcon />
    },
    'rules': {
        name: 'Rule',
        plural: 'Rules',
        types: ['article'],
        icon: <RuleIcon />
    },
    'characters': {
        name: 'Character',
        plural: 'Characters',
        types: ['character'],
        icon: <CharacterIcon />
    },
    'monsters': {
        name: 'Monster',
        plural: 'Monsters',
        types: ['monster'],
        filters: MonsterFilters,
        icon: <DragonHeadIcon />,
        sort: [
            { name: 'data.challengeRating', label: 'CR (Low to High)', order: 1 },
            { name: 'data.challengeRating', label: 'CR (High to Low)', order: -1 }
        ]
    },
    'monster-features': {
        name: 'Monster Feature',
        plural: 'Monster Features',
        types: ['monster-feature'],
        icon: <ClawIcon />
    },
    'monster-templates': {
        name: 'Monster Template',
        plural: 'Monster Templates',
        types: ['monster-template'],
        icon: <DragonHeadOutlinedIcon />
    },
    'spells': {
        name: 'Spell',
        plural: 'Spells',
        types: ['spell'],
        filters: SpellFilters,
        icon: <SpellIcon />,
        sort: [
            { name: 'data.level', label: 'Level (0-9)', order: 1 },
            { name: 'data.level', label: 'Level (9-0)', order: -1 }
        ]
    },
    'items': {
        name: 'Item',
        plural: 'Items',
        types: ['item'],
        icon: <ItemIcon />,
        filters: ItemFilters,
        sort: [
            { name: 'data.rarity', label: 'Rarity (Least to Most)', order: 1 },
            { name: 'data.rarity', label: 'Rarity (Most to Least)', order: -1 }
        ]
    },
    'assets': {
        disableCreate: true,
        name: 'Image',
        plural: 'Images',
        types: ['image'],
        icon: <AssetIcon />,
        resultsPerPage: 30,
        renderResults: (results) => <ImageAssetBrowser images={results} />
    },
    'maps': {
        name: 'Map',
        plural: 'Maps',
        types: ['map'],
        icon: <MapIcon />
    }
}
Object.freeze(documentCategories);

function getDocumentType(type) {
    return (type && documentTypes[type]) ?? null;
}

function getDocumentCategory(category) {
    return (category && documentCategories[category]) ?? null;
}

export { getDocumentType, documentTypes, getDocumentCategory, documentCategories };
