import { useCallback, useEffect, useState } from 'react';
import { useRepositoryCache } from '../../globals.js';
import SelectBox from './SelectBox.js';
import { listRepositories } from '../../api.js';

export default function RepositoryPicker({value, useDefault, onChange, label, minRole, ...props}) {
    const { getRepository } = useRepositoryCache();
    const [repositoryList, setRepositoryList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getRepositoryLabel = useCallback((id) => getRepository(id)?.title ?? 'Loading...', [getRepository]);

    const fetchRepositoryList = useCallback(async (minRole) => {
        setIsLoading(true);
        const res = await listRepositories();
        if (res.result === 'success') {
            let newRepoList = [];
            for (let repo of res.repositories) {
                let allow = true;
                if (minRole === 'owner') {
                    allow = (repo.role === 'owner');
                }
                else if (minRole === 'editor') {
                    allow = (repo.role === 'owner' || repo.role === 'editor');
                }
                else if (minRole === 'viewer') {
                    allow = (repo.role === 'owner' || repo.role === 'editor' || repo.role === 'viewer');
                }

                if (allow) {
                    newRepoList.push(repo.id);
                }
            }
            setRepositoryList(newRepoList);
        }
        else {
            setRepositoryList([]);
        }
        setIsLoading(false);
    }, []);

    const handleChange = useCallback((e, v) => {
        if (onChange) {
            onChange(v);
        }
    }, [onChange]);

    useEffect(() => {
        if (useDefault && 
            !value &&
            onChange &&
            (repositoryList?.length ?? 0) > 0) {
            onChange(repositoryList[0]);
        }
    }, [repositoryList, onChange, value, useDefault]);

    useEffect(() => {
        if (!isLoading && !repositoryList) {
            fetchRepositoryList(minRole);
        }
    }, [fetchRepositoryList, repositoryList, minRole, isLoading]);

    return (
        <SelectBox label={label ?? "Repository"} fullWidth
            value={value ?? null}
            options={repositoryList ?? []}
            getOptionLabel={getRepositoryLabel}
            loading={isLoading}
            onChange={handleChange}
            {...props}
        />
    );
}
