import { useCallback } from 'react';
import { Box, Stack, TextField, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { ComboBox } from '../common/ComboBox.js';
import { MultiComboBox } from '../common/MultiComboBox.js';
import { SelectBox } from '../common/SelectBox.js';
import { OutlinedBox } from '../common/OutlinedBox.js';
import MarkdownField from '../common/MarkdownField.js';
import { TagPicker } from '../common/TagPicker.js';
import ImagePicker from '../common/ImagePicker.js';
import { spellLevelList, getSpellLevelLabel } from '../../dnd5e.js';
import { castTimeSuggestions, spellSchoolSuggestions, spellRangeSuggestions, spellDurationSuggestions, classSuggestions, spellTagSuggestions } from '../../suggestions.js';

function SpellEditor({data, update}) {
    const getGenerationPrompt = useCallback(() => {
        return `Describe a ${getSpellLevelLabel(data.level)} spell from the ${data.school} school called ${data.name}.`
    }, [data.name, data.school, data.level]);

    const isReaction = (data.castTime === '1 reaction');

    return (
        <Stack spacing={2}>
            <TextField label="Name" autoComplete="off" fullWidth required
                value={data.name ?? ''} 
                onChange={(e) => update({name: e.target.value})} 
                sx={{marginTop: 1}}
            />
            <ImagePicker data={data} update={update} size="small" />
            <Stack direction="row" spacing={1}>
                <SelectBox label="Level" options={spellLevelList} fullWidth
                    getOptionLabel={(level) => getSpellLevelLabel(level)}
                    value={data.level}
                    onChange={(e, v) => update({level: v})}
                />
                <ComboBox label="Cast Time" options={castTimeSuggestions} fullWidth
                    value={data.castTime}
                    onChange={(e, v) => update({castTime: v})}
                />
            </Stack>           
            { isReaction &&
                <TextField label="Activation" fullWidth autoComplete="off"
                    value={data.activation ?? ''}
                    onChange={(e) => update({activation: e.target.value})}
                />
            }
            <Stack direction="row" spacing={1}>
                <ComboBox label="School" options={spellSchoolSuggestions} fullWidth
                    value={data.school}
                    onChange={(e, v) => update({school: v})}
                />
                <MultiComboBox label="Classes" options={classSuggestions} fullWidth
                    value={data.classes}
                    onChange={(e, v) => update({classes: v})}
                />
            </Stack>
            <Stack direction="row" spacing={1}>
                <ComboBox label="Range" options={spellRangeSuggestions} fullWidth
                    value={data.range}
                    onChange={(e, v) => update({range: v})}
                />
                <ComboBox label="Duration" options={spellDurationSuggestions} fullWidth
                    value={data.duration}
                    onChange={(e, v) => update({duration: v})}
                />
            </Stack>
            <OutlinedBox label="Features" sx={{breakInside: 'avoid'}}>
                <Stack direction="row" sx={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                    <FormControlLabel
                        label = "Verbal"
                        control={<Checkbox checked={data.verbal} onChange={(e) => update({verbal: e.target.checked})} />}
                    />
                    <FormControlLabel
                        label = "Somatic"
                        control={<Checkbox checked={data.somatic} onChange={(e) => update({somatic: e.target.checked})} />}
                    />
                    <FormControlLabel
                        label = "Material"
                        control={<Checkbox checked={data.material} onChange={(e) => update({material: e.target.checked})} />}
                    />
                    <FormControlLabel
                        label = "Ritual"
                        control={<Checkbox checked={data.ritual} onChange={(e) => update({ritual: e.target.checked})} />}
                    />
                    <FormControlLabel
                        label = "Concentration"
                        control={<Checkbox checked={data.concentration} onChange={(e) => update({concentration: e.target.checked})} />}
                    />
                </Stack>
            </OutlinedBox>
            { data.material &&
                <Stack direction="row" width='100%'>
                    <TextField label="Materials" fullWidth autoComplete="off"
                        value={data.components ?? ''}
                        onChange={(e) => update({components: e.target.value})} 
                    />
                    <FormControlLabel
                        sx={{marginLeft: '16px'}}
                        label = "Consume"
                        control={<Checkbox checked={data.consumeComponents} onChange={(e) => update({consumeComponents: e.target.checked})} />}
                    />                       
                </Stack>
            }
            <TagPicker label="Tags" value={data.tags} onChange={(e, v) => update({tags: v})} suggestions={spellTagSuggestions} category="spells" />
            <MarkdownField label="Description" rows={8}
                value={data.text}
                onChange={(v) => update({text: v})} 
                getGenerationPrompt={getGenerationPrompt}
            />
            <MarkdownField label="At Higher Levels" fullWidth multiline rows={4}
                value={data.upcast ?? ''}
                onChange={(v) => update({upcast: v})}
            />
        </Stack>
    );
}
        
export default SpellEditor;

