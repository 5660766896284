import { useCallback, useState } from 'react';
import { Popper, Box, Stack } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DocumentPicker from '../common/DocumentPicker.js';
import { useNavigate } from 'react-router-dom';

const ResultsPopper = styled(Popper)({
    width: 'fit-content',
    minWidth: '500px'
});

const searchIcon = <SearchIcon />;

export default function SearchBox() {
    const navigate = useNavigate();
    const [renderKey, setRenderKey] = useState(0);

    const handleChange = useCallback((e, v) => {
        if (v?.id) {
            navigate('/document/' + v.id);
            setRenderKey((key) => key + 1);
        }
    }, [navigate]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            if (e.target.value) {
                navigate('/search/' + encodeURIComponent(e.target.value));
                setRenderKey((key) => key + 1);
            }
        }
    }, [navigate]);

    return (
        <Box sx={{pl: 1, pr: 1, pt: 1, pb: 1, width: '100%'}}>
            <DocumentPicker fullWidth freeSolo size="small" key={renderKey}
                label={<Stack direction="row" spacing={1}>{searchIcon}<span>Search</span></Stack>}
                value={null}
                onChange={handleChange} 
                onKeyDown={handleKeyDown}
                PopperComponent={ResultsPopper}
            />
        </Box>
    );
}

