import dayjs from 'dayjs';

function getSessionTitleAndSubtitle(session) {
    let strings = [];

    if (session.name) {
        strings = [...strings, session.name];
    }
    if (session.date) {
        strings = [...strings, session.date.format('MMMM D, YYYY')];
    }
    if (session.number) {
        strings = [...strings, 'Session ' + session.number];
    }

    if (strings.length === 0) {
        strings = ['Session'];
    }

    let title = strings[0];
    let subtitle = strings.slice(1).join(' / ');
    return [title, subtitle];
}

const Session = {
    name: 'Session',
    plural: 'Sessions',
 
    init: () => Session.deserialize({}),
    
    validate: (data) => {
        let errors = [];
        if (!data.name && !data.number && !data.date) {
            errors.push('Either a name, number, or date must be provided.');
        }
        return errors;
    },

    deserialize: (document) => ({
        tags: document.tags ?? [],
        date: document.data?.date ? dayjs(document.data.date) : null,
        number: document.data?.number ?? null,
        name: document.data?.name ?? null,
        recap: document.data?.recap ?? null,
        planning: document.data?.planning ?? null,
        notes: document.data?.notes ?? null
    }),

    serialize: (data) => {
        const [title, subtitle] = getSessionTitleAndSubtitle(data);
        return {
            title: title,
            description: subtitle,
            tags: data.tags,
            data: {
                name: data.name,
                number: data.number,
                date: data.date?.format() ?? null,
                recap: data.recap,
                planning: data.planning,
                notes: data.notes
            }
        }
    }
}

export default Session;
export { getSessionTitleAndSubtitle };

