import { formatSentence } from '../text.js';
import { getItemRarityLabel } from '../dnd5e.js';

function getItemDescription(data) {
    let desc = '';
    if (data.type) {
        desc += data.type;
        if (data.subtype) {
            desc += ` (${data.subtype})`;
        }
    }
    if (data.rarity != null) {
        if (desc) {
            desc += ', ';
        }
        desc += getItemRarityLabel(data.rarity);
    }
    if (data.attunement) {
        desc += ' (requires attunement';
        if (data.attunementRules) {
            desc += ' by ' + data.attunementRules;
        }
        desc += ')';
    }
    return formatSentence(desc);
}

const Item = {
    name: 'Item (5E)',
    plural: 'Items',

    init: () => Item.deserialize({}),
    
    validate: (data) => {
        let errors = [];
        if (!data.name) {
            errors.push('Name is required');
        }
        return errors;
    },

    deserialize: (document) => ({
        name: document.title ?? null,
        thumbnail: document.thumbnail ?? null,
        altText: document.altText ?? null,
        image: document.data?.image ?? null,
        type: document.data?.type ?? null,
        subtype: document.data?.subtype ?? null,
        rarity: document.data?.rarity ?? null,
        attunement: document.data?.attunement ?? false,
        attunementRules: document.data?.attunementRules ?? null,
        description: document.data?.description ?? null,
        cost: document.data?.cost ?? null,
        charges: document.data?.charges ?? null,
        recharge: document.data?.recharge ?? null
    }),

    serialize: (data) => ({
        title: data.name,
        description: getItemDescription(data),
        thumbnail: data.thumbnail,
        altText: data.altText,
        data: {
            type: data.type,
            subtype: data.subtype,
            rarity: data.rarity,
            attunement: data.attunement,
            attunementRules: data.attunementRules,
            image: data.image,
            description: data.description,
            cost: data.cost,
            charges: data.charges,
            recharge: data.recharge
        }
    })
};

export default Item;
export { getItemDescription };

