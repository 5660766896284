import { useState, memo, useCallback } from 'react';
import { Box, Stack, TextField, Tooltip, Button, IconButton, Alert, Stepper, Step, StepButton, StepContent, Paper, } from '@mui/material';
import { Delete as DeleteIcon, 
    AddCircle as AddIcon,
    ArrowUpward as MoveUpIcon,
    ArrowDownward as MoveDownIcon } from '@mui/icons-material';
import ImagePicker from '../common/ImagePicker.js';
import MarkdownField from '../common/MarkdownField.js';
import { DocumentPicker } from '../common/DocumentPicker.js';
import { ComboBox } from '../common/ComboBox.js'
import { TagPicker } from '../common/TagPicker.js';
import { OutlinedBox } from '../common/OutlinedBox.js';
import { useStateBlockObject, useStateBlockArray } from '../../utils.js';
import { pronounSuggestions, speciesSuggestions, alignmentSuggestions } from '../../suggestions.js';

const VitalsEditor = memo(({data, update}) => {
    return (
        <Stack spacing={2}>
            <TextField label="Name" autoComplete="off" fullWidth required
                value={data.name ?? ''} 
                onChange={(e) => update({name: e.target.value})} />
            <Tooltip title='Brief description of the character, such as "Barber of Seville" or "Child of the forest".'>
                <TextField label="Epithet" autoComplete="off" fullWidth 
                    value={data.epithet ?? ''} 
                    onChange={(e) => update({epithet: e.target.value})} 
                />
            </Tooltip>
            <Stack direction="row" spacing={1}>
                <ComboBox label="Species" fullWidth
                    options={speciesSuggestions} 
                    value={data.species ?? ''} 
                    onChange={(e, v) => update({species: v})} />
                <ComboBox label="Pronouns" fullWidth
                    options={pronounSuggestions} 
                    value={data.pronouns}
                    onChange={(e, v) => update({pronouns: v})} />
            </Stack>
            <Stack direction="row" spacing={1}>
                <TextField label="Age" autoComplete="off" fullWidth
                    value={data.age ?? ''} 
                    onChange={(e) => update({age: e.target.value})} />
                <ComboBox label="Alignment" fullWidth
                    options={alignmentSuggestions}
                    value={data.alignment}
                    onChange={(e, v) => update({alignment: v})} />
            </Stack>
            <TagPicker label="Tags" fullWidth category="characters" value={data.tags} onChange={(e, v) => update({tags: v})} />
            <DocumentPicker label="Stat Block" 
                query={{category: "monsters"}}
                value={data.statBlock ?? null}
                onChange={(e, v) => update({statBlock: v})} />
        </Stack>
    )
});

const AppearanceEditor = memo(({data, update, summary}) => {   
    const getGenerationPrompt = useCallback(() => {
        return `Describe the appearance, physical characteristics and speech of a ${summary}.`;
    }, [summary]);

    return (
        <Stack spacing={2}>
            <ImagePicker data={data} update={update} />
            <MarkdownField label="Description" rows={12}
                value={data.text} 
                onChange={(v) => update({text: v})}
                getGenerationPrompt={getGenerationPrompt}        
            />        
        </Stack>
    );
});

const PersonalityEditor = memo(({data, update, summary}) => {
    const getGenerationPrompt = useCallback(() => {
        return `Describe the personality for a ${summary}, then provide a one sentence ideal, bond, and flaw.`;
    }, [summary]);

    return (
        <Stack spacing={2}>
            <TextField label="Ideal" autoComplete="off" fullWidth multiline
                value={data.ideal ?? ''} 
                onChange={(e) => update({ideal: e.target.value})} 
            />
            <TextField label="Bond" autoComplete="off" fullWidth multiline
                value={data.bond ?? ''} 
                onChange={(e) => update({bond: e.target.value})} 
            />
            <TextField label="Flaw" autoComplete="off" fullWidth multiline
                value={data.flaw ?? ''} 
                onChange={(e) => update({flaw: e.target.value})} 
            />
            <MarkdownField label="Description" fullWidth rows={12}
                value={data.text} 
                onChange={(v) => update({text: v})}
                getGenerationPrompt={getGenerationPrompt}
            />
        </Stack>
    );
});

const BiographyEditor = memo(({data, update, summary}) => {
    const getGenerationPrompt = useCallback(() => {
        return `Write a short biography for a ${summary}.`;
    }, [summary]);

    return (
        <MarkdownField label="Description" fullWidth rows={12}
            value={data.text} 
            onChange={(v) => update({text: v})}
            getGenerationPrompt={getGenerationPrompt}
        />        
    );
});

const NotesEditor = memo(({data, update}) => {
    return (
        <MarkdownField label="Description" fullWidth rows={12}
            value={data.text} 
            onChange={(v) => update({text: v})}
        />   
    );
});

const SingleRelationshipEditor = memo(({data, index, count, update, remove, swap}) => {
    const handleChange = useCallback((e, v) => {
        if (typeof v === 'string') {
            update(index, { name: v, characterId: null });
        }
        else {
            update(index, { name: v?.label ?? '', characterId: v?.id ?? null });
        }
    }, [update, index]);

    const value = (data.characterId ? { label: data.name ?? data.characterId, id: data.characterId } : data.name) ?? '';

    return (
        <Stack spacing={2} padding={1} width="100%" component={Paper} elevation={2}>
            <Stack direction="row" alignItems="center">
                <DocumentPicker label="Character" freeSolo fullWidth query={{category: 'characters'}} onChange={handleChange} value={value} />
                <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                </IconButton>
                { index > 0 && 
                    <IconButton onClick={() => swap(index, index - 1)}>
                        <MoveUpIcon />
                    </IconButton>
                }
                { (index < (count - 1)) &&
                    <IconButton onClick={() => swap(index, index + 1)}>
                        <MoveDownIcon />
                    </IconButton>
                }
            </Stack>
            <MarkdownField label="Relationship" fullWidth rows={4}
                value={data.description}
                onChange={(v) => update(index, {description: v})}
            />
        </Stack>
    );   
});

const RelationshipsEditor = memo(({data, update}) => {
    const [items, addItem, updateItem, removeItem, swapItem, getItemKey] = useStateBlockArray('relationships', data, update);

    return (
        <Stack spacing={1}>
            <Box>
                <Button variant="outlined"
                    startIcon={<AddIcon />} 
                    onClick={() => addItem({})}
                >
                    New Relationship
                </Button>
            </Box>
            {items.map((value, index) => 
                <SingleRelationshipEditor key={getItemKey(index)} index={index} count={data.length} data={value} update={updateItem} remove={removeItem} swap={swapItem} />
            )}
        </Stack>
    );
});

const steps = [
    { label: 'Vitals' },
    { label: 'Appearance' },
    { label: 'Personality' },
    { label: 'Biography' },
    { label: 'Relationships' },
    { label: 'Notes' },
];

function CharacterEditor({data, update}) {
    const [vitals, updateVitals] = useStateBlockObject('vitals', data, update);
    const [appearance, updateAppearance] = useStateBlockObject('appearance', data, update);
    const [personality, updatePersonality] = useStateBlockObject('personality', data, update);
    const [biography, updateBiography] = useStateBlockObject('biography', data, update);
    const [notes, updateNotes] = useStateBlockObject('notes', data, update);
    const [step, setStep] = useState(0);

    let summary = `${vitals.alignment} ${vitals.species} named ${vitals.name}`;
    if (vitals.pronouns) {
        summary += ` (${vitals.pronouns})`;
    }

    return (
        <Stack direction="row" spacing={2} width="100%">
            <Box width="220px">
                <Stepper activeStep={step} nonLinear orientation="vertical">
                    { steps.map((step, index) =>
                        <Step key={step.label}>
                            <StepButton onClick={() => setStep(index)}>{step.label}</StepButton>
                        </Step>
                    )}
                </Stepper>
            </Box>
            <Box width="100%" padding={1}>
                { step === 0 && <VitalsEditor data={vitals} update={updateVitals} /> }
                { step === 1 && <AppearanceEditor data={appearance} update={updateAppearance} summary={summary} /> }
                { step === 2 && <PersonalityEditor data={personality} update={updatePersonality} summary={summary} />}
                { step === 3 && <BiographyEditor data={biography} update={updateBiography} summary={summary} /> }
                { step === 4 && <RelationshipsEditor data={data} update={update} /> }
                { step === 5 && <NotesEditor data={notes} update={updateNotes} /> }
            </Box>               
        </Stack>        
    )
}

export default CharacterEditor;

