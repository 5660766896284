const Article = {
    name: 'Article',
    plural: 'Articles',

    init: () => Article.deserialize({}),
    
    deserialize: (document) => ({
        title: document.title ?? null,
        tags: document.tags ?? [],
        description: document.description ?? null,
        thumbnail: document.thumbnail ?? null,
        altText: document.altText ?? null,
        text: document.data?.text ?? '',
    }),

    serialize: (data) => ({
        title: data.title,
        description: data.description,
        tags: data.tags,
        thumbnail: data.thumbnail,
        altText: data.altText,
        data: {
            text: data.text
        }
    }),

    validate: (data) => {
        let errors = [];
        if (!data.title) {
            errors.push('A title is required.');
        }
        return errors;
    },
}

export default Article;


