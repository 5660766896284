
import { useRef, useState, useEffect, memo } from 'react';
import { useTheme, Stack } from '@mui/material';

const MultiColumnList = memo(({items, itemWidth, maxCols, spacing, render, ItemProps}) => {
    const [windowWidth, setWindowWidth] = useState();
    const [cols, setCols] = useState(1);
    const theme = useTheme();
    const gap = parseInt(theme.spacing(spacing ?? 1));
    const ref = useRef();

    useEffect(() => {
        function resize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);

    useEffect(() => {
        let cols = 1;
        if (itemWidth > 0) {
            while ((((cols + 1) * itemWidth) + (cols * gap)) < ref.current.clientWidth 
                && cols < (maxCols ?? 12) 
                && cols < (items?.length ?? 0)) {
                cols += 1;
            }
        }
        setCols(cols);
    });

    const colList = [...Array(cols).keys()];

    return (
        <Stack direction="row" spacing={spacing ?? 1} width="100%" ref={ref}>
            { colList.map((_, col) => 
                <Stack spacing={spacing ?? 1} key={col} sx={{flex: '1 0 0', overflow: 'hidden'}}>
                    { items.map((item, index) =>
                        (index % cols === col) && render(item, {key: index, ...ItemProps})
                    )}                   
                </Stack>
            )}
        </Stack>
    );
});

export default MultiColumnList;
