import { useMediaQuery, useTheme, Box, Stack, Typography, Divider, Paper } from '@mui/material';
import { Image } from '../common/Image.js';
import { MarkdownViewer } from '../common/MarkdownViewer.js';
import Section from '../common/Section.js';
import { capitalizeFirstLetter } from '../../text.js';
import { getSpellDescription } from '../../models/spell.js';

function SpellViewer({data}) {
    const theme = useTheme();
    const twoColumns = useMediaQuery(theme.breakpoints.up('lg'));
    const castTime = capitalizeFirstLetter(data.castTime);
    const range = capitalizeFirstLetter(data.range);
    const duration = data.concentration ?
        `Concentration, up to ${data.duration}` : capitalizeFirstLetter(data.duration);
    const subtitle = getSpellDescription(data);

    return (
        <Stack spacing={2}>
            <Stack spacing={2} component={Paper} elevation={2} padding={1} width='100%'>
                <Stack direction="row" spacing={4}>
                    <Stack spacing={1} sx={{width: '100%'}}>
                        <Typography variant="subtitle1">{subtitle}</Typography>
                        <div>
                            <Section label="Cast Time" compact>
                                {capitalizeFirstLetter(castTime)}
                                {data.activation && ` (${data.activation})`}
                                {data.ritual && ', or as a ritual'}
                            </Section>
                            <Section label="Range" compact>{capitalizeFirstLetter(range)}</Section>
                            <Section label="Duration" compact>{capitalizeFirstLetter(duration)}</Section>
                            <Section label="Components" compact>{data.verbal && 'V'} {data.somatic && 'S'} {data.material && 'M'}</Section>
                            {(data.material && data.components) &&
                                <Section label="Materials" compact>{capitalizeFirstLetter(data.components)} {data.consumeComponents && '(consumed on use)'}</Section>
                            }
                            {(data.classes?.length ?? 0) > 0 &&
                                <Section label="Classes" compact>{data.classes.map(c => capitalizeFirstLetter(c)).join(', ')}</Section>
                            }
                        </div>
                        <Divider />
                        <MarkdownViewer text={data.text}/>
                        {data.upcast &&
                            <Section label="At Higher Levels." compact>{data.upcast}</Section>
                        }
                    </Stack>
                    { (twoColumns && data.image) && 
                        <Image src={data.image} alt={data.altText} 
                            sx={{
                                maxWidth: '40%',
                                objectFit: 'cover'
                            }}
                        />
                    }
                </Stack>
            </Stack>
            { (!twoColumns && data.image) && 
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <Image src={data.image} alt={data.altText} sx={{ maxWidth: '95%', objectFit: 'cover' }} />
                </Box>
            }
        </Stack>
    );
}

export default SpellViewer;

