let config = { };

if (process.env.NODE_ENV === 'production') {
    config.apiUrl = 'https://codex-api.ottermine.net'
}
else {
    config.apiUrl = 'http://localhost:3001'
}

Object.freeze(config);

export default config;
