import { useState, useCallback, forwardRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';

function getOptionLabelDefault(o) {
    if (typeof o === 'string') {
        return o;
    }
    else if (o?.label) {
        return o.label;
    }
    else {
        return '[invalid]';
    }
}

const ComboBox = forwardRef(({label, options, onChange, fetchOptions, noOptionsText, loadingText, getOptionLabel, InputProps, value, ...props}, ref) => {
    const [asyncOptions, setAsyncOptions] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [prevInputValue, setPrevInputValue] = useState(getOptionLabel ? getOptionLabel(value) : getOptionLabelDefault(value));

    const handleOpen = useCallback(async () => {
        setIsOpen(true);
        if (fetchOptions && !asyncOptions && !isLoading) {
            setIsLoading(true);
            
            try {
                const res = await fetchOptions();
                if ((res?.length ?? 0) === 0) {
                    setError(noOptionsText ?? 'No results found.');
                }
                else {
                    setAsyncOptions(res);
                }
            }
            catch (error) {
                setError(error?.message ?? 'Failed to fetch options');
            }

            setIsLoading(false);
        }
    }, [isLoading, asyncOptions, fetchOptions, noOptionsText]);

    const handleClose = useCallback(async () => {
        setIsOpen(false);
    }, []);

    const handleChange = useCallback((e, v) => {
        onChange?.(e, v);
    }, [onChange]);

    const handleInputChange = useCallback((e, v) => {
        if (v !== prevInputValue) {
            onChange(e, v);
            setPrevInputValue(v);
        }
    }, [onChange, prevInputValue]);

    return (
        <Autocomplete 
            freeSolo 
            ref={ref}
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            loading={isLoading || Boolean(error)}
            loadingText={error ?? loadingText}
            options={asyncOptions ?? (options ?? [])} 
            getOptionLabel={getOptionLabel ?? getOptionLabelDefault}
            onInputChange={handleInputChange} 
            onChange={handleChange}
            value={value ?? ''}
            {...props}
            renderInput={(params) => 
                <TextField {...params} label={label} InputProps={{...params.InputProps, ...(InputProps ?? [])}} />
            } />
    );
});

export default ComboBox;
export { ComboBox };
