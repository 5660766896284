import { useCallback, memo } from 'react';
import { styled, Drawer } from '@mui/material';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as HideSideIcon } from '@mui/icons-material';

const hideSideIcon = <HideSideIcon />;
const defaultWidth = '600px';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const PropertyPanelLayout = memo(({close, label, labelProps, children}) => {
    return (<>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{flexGrow: 1}}>
                {label && <Typography variant="h3_noborder" {...labelProps}>{label}</Typography>}    
            </Box>       
            <IconButton onClick={close}>
                { hideSideIcon }
            </IconButton>
        </Box>
        {children}
    </>);
});

function PropertiesPanel({children, open, onChange, width, ...props}) {
    const close = useCallback(() => onChange(false), [onChange]);

    return (<>
        <Drawer variant="persistent" anchor="right" open={open} PaperProps={{sx: {zIndex: 1010, maxWidth: '100%'}}}>
            <DrawerHeader />
            <Box padding={2} sx={{width: (width ?? defaultWidth), maxWidth: '100%'}}>
                { open && 
                    <PropertyPanelLayout close={close} {...props}>
                        {children}
                    </PropertyPanelLayout>
                }
            </Box>
        </Drawer>
    </>);    
}

export default PropertiesPanel;
