import { useEffect } from 'react';
import { Stack, Alert } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import { useTitleBar } from '../../globals.js';

export default function ErrorPage() {
    const error = useRouteError();
    const { setTitle } = useTitleBar();

    const is404 = error.status === 404;

    useEffect(() => {
        if (is404) {
            setTitle('Page not found');
        }
        else {
            setTitle('Error');
        }
    }, []);
    
    return (
        <Stack spacing={2} mt={1} width="100%">
            { is404 ?
                <Alert severity="error">No page could be found at the given location.</Alert>
                :
                <Alert severity="error">An unknown error occurred.</Alert>
            }
        </Stack>
    );
}
