
const countWords = [
    'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'
];

function formatCount(n) {
    if (countWords[n]) {
        return countWords[n];
    }
    return n.toString();
}

function formatIncidents(n) {
    if (n === 1) {
        return 'once';
    }
    else if (n === 2) {
        return 'twice';
    }
    else {
        return `${formatCount(n)} times`;
    }
}

function capitalizeFirstLetter(s) {
    if (!s || s.length === 0 || !s.charAt) {
        return s;
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
}

function formatSentence(s) {
    return capitalizeFirstLetter(s.trim());
}

function formatList(items, conjunction) {
    let result = items[0];
    for (let i = 1; i < items.length; i++) {
        if (i === 0) {
            result += items[i];
        }
        else {
            if (i > 1) {
                result += ',';
            }
            result += ' ';
            if (conjunction && i === items.length - 1) {
                result += conjunction;
                result += ' ';
            }
            result += items[i];
        }
    }
    return result;
}

export { formatCount, formatIncidents, capitalizeFirstLetter, formatList, formatSentence };
