import { createContext, useContext, useReducer, useState, useCallback, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Stack, Typography, TextField, InputAdornment, ImageList, ImageListItem, CircularProgress, Tooltip, Button, Alert, FormControlLabel, Switch } from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import RepositoryPicker from '../common/RepositoryPicker.js';
import CommonDialog from '../common/CommonDialog.js';
import SubmitButton from '../common/SubmitButton.js';
import { TagPicker } from '../common/TagPicker.js';
import { listDocuments, uploadAsset } from '../../api.js';
import { formatBytes } from '../../utils.js';
import { useDocumentContext, useSetDocumentContext, useAuth } from '../../globals.js';

const ImageDropperContext = createContext();

function useImageDropper() {
    return useContext(ImageDropperContext);
}

function UploadImageDialog({file, cancel, submit, documentId, description: initialDescription, tags: initialTags}) {
    const [isLoading, setIsLoading] = useState(false); 
    const [previewUrl] = useState(file ? URL.createObjectURL(file) : '');
    const [dimensions, setDimensions] = useState();
    const [useCompression, setUseCompression] = useState(true);
    const [description, setDescription] = useState(initialDescription ?? file?.name?.replace(/\.[^/.]+$/, "") ?? '');
    const [tags, setTags] = useState(initialTags ?? []);
    const documentContext = useDocumentContext();
    const setDocumentContext = useSetDocumentContext();
    const [repository, setRepository] = useState(documentContext.repository)
    const [error, setError] = useState();
    const { isLoggedIn, require: requireAuth } = useAuth();

    const imageLoaded = useCallback((e) => {
        setDimensions([e.target.naturalWidth, e.target.naturalHeight]);

        if (file.size > 10000000) {
            setError('File is too large. Uploads have a limit of 10MiB.');
        }
    }, [file.size]);

    const upload = useCallback(async () => {
        setIsLoading(true);

        const response = await uploadAsset(file, documentId, description, repository, tags, useCompression);
        if (response.result === 'success') {
            submit({url: response.url, thumbnail: response.thumbnail, altText: description});
            setDocumentContext.setRepository(repository);
        }
        else if (response.reason === 'file_too_large') {
            setError('File is larger than 10MB, which is the maximum size allowed.')
        }
        else {
            setError('Failed to upload image. Please try again.');
        }

        setIsLoading(false);
    }, [description, file, repository, tags, submit, useCompression, setDocumentContext, documentId]);

    const imageInfo = 
        [
            file && formatBytes(file?.size ?? 0),
            dimensions && `${dimensions[0]}x${dimensions[1]}`,
            file.type
        ]
        .filter((x) => Boolean(x))
        .join(', ');
    
    const isEnabled = (documentId || repository) && !isLoading && !error;

    useEffect(() => {
        requireAuth();
    }, []);

    return (
        isLoggedIn ? <>
                <DialogContent>
                    <Stack spacing={2}>
                        { documentId &&
                            <Alert severity="warning">
                                This will replace the existing image at the URL. You may continue to see the old image until you clear your browser cache.
                            </Alert>
                        }
                        <Box sx={{ position: 'relative' }}>
                            <img src={previewUrl} alt={description} onLoad={imageLoaded}
                                style={{maxWidth: '100%', maxHeight: '512px', borderRadius: '5%', objectFit: 'contain', filter: isLoading && 'opacity(50%)'}}
                            />
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography variant="caption">
                                {imageInfo}
                            </Typography>
                        </Box>
                        { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                        { !documentId &&
                            <RepositoryPicker label="Repository" fullWidth useDefault
                                value={repository}
                                onChange={(v) => setRepository(v)}
                            />
                        }
                        <TextField label="Description" fullWidth autoComplete="off" autoFocus
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <TagPicker label="Tags" category="assets" fullWidth
                            value={tags}
                            onChange={(e, v) => setTags(v)}
                        />
                        <FormControlLabel
                            label="Compress image to reduce file size"
                            control={
                                <Switch checked={useCompression} onChange={(e) => setUseCompression(e.target.checked)} />
                            }
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel}>Cancel</Button>
                    <SubmitButton onClick={upload} disabled={!isEnabled} loading={isLoading}>Upload</SubmitButton>
                </DialogActions>
            </>
            : <>
                <DialogContent>
                    <DialogContentText>You must log in to upload images.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel}>Close</Button>
                </DialogActions>
            </>
    );
}

function imagesReducer(state, action) {
    //console.log(`in updateDocuments: old state is ${JSON.stringify(state)}, new state is ${JSON.stringify(action)}`);
    if (action.key > state.key) {
        return action;
    }
    else {
        return state;
    }
}

function queryReducer(state, action) {
    return { ...state, ...action, key: (state.key ?? 0) + 1 };
}

function BrowseImageDialog({cancel, submit, upload}) {
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [{images, key: lastFetchKey}, updateImages] = useReducer(imagesReducer, {key: 0});
    const [query, updateQuery] = useReducer(queryReducer, {key: 1});

    const onDrop = useCallback((files) => { 
        if (Array.isArray(files)) {
            upload(files[0]);
        }
        else {
            upload(files);
        }
    }, [upload]);

    const fetchImageList = useCallback(async () => {
        setIsLoading(true);

        const fetchKey = query.key;
        const res = await listDocuments({category: 'assets', text: query.text, tags: query.tags});
        if (res.result === 'success') {
            updateImages({key: fetchKey, images: res.documents});
            setError();
        }
        else {
            setError('Failed to retrieve results. Please try again.');
        }

        setIsLoading(false);       
    }, [query]);
    
    const imageClicked = useCallback((e) => {
        const url = e.target.src;
        for (let image of images) {
            if (image.thumbnail === url || image.description === url) {
                submit({url: image.description, thumbnail: image.thumbnail, altText: image.altText});
            }
        }
    }, [images, submit]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop: onDrop, maxFiles: 1});

    useEffect(() => {
        if (!isLoading && !error && lastFetchKey < query.key) {
            fetchImageList();
        }
    }, [isLoading, lastFetchKey, query.key, fetchImageList, error]);

    useEffect(() => {
        const handlePaste = (e) => {
            for (let index in e.clipboardData.items) {
                const item = e.clipboardData.items[index];
                if (item.kind === 'file' &&
                    item.type.startsWith('image/')) {
                    upload(item.getAsFile());
                }
            }
        }

        window.addEventListener("paste", handlePaste);
        return () => {
            window.removeEventListener("paste", handlePaste);
        }
    }, [upload]);

    return (
        <DialogContent>      
            <Stack spacing={2}>
                <Box 
                    sx={{
                        display: 'flex', width: '100%', height: '100px', justifyContent: 'center', alignItems: 'center',
                        borderColor: (theme) => isDragActive ? theme.palette.primary : theme.palette.grey[500],
                        borderStyle: 'dashed',
                        borderWidth: '2px',
                        borderRadius: '40px'
                    }} 
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <Typography>Drop files here, or click to browse.</Typography>
                </Box>

                <TextField label="Search" fullWidth autoComplete="off"
                        value={query.text ?? ''} 
                        onChange={(e) => updateQuery({text: e.target.value})} 
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                        }}
                    />
                <TagPicker label="Tags" category="assets" fullWidth
                    value={query.tags ?? []}
                    onChange={(e,v ) => updateQuery({tags: v})}
                    />
                <Box sx={{ height: '524px '}}>
                    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', maxHeight: '520px' }}>
                        <ImageList cols={3} sx={{flexGrow: 0, padding: '0 1em'}}>
                            { (images ?? []).map((image) => 
                                <ImageListItem key={image.description}>
                                    <Tooltip title={image.altText} sx={{display: 'block'}}>
                                        <img
                                            src={image.thumbnail ? image.thumbnail : image.description} 
                                            alt={image.altText} 
                                            onClick={imageClicked} 
                                            loading="lazy" />
                                    </Tooltip>
                                </ImageListItem>
                            )}
                        </ImageList>
                        { isLoading && 
                            <Box sx={{display: 'flex', position: 'absolute', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: "rgba(0,0,0,0.5)"}}>
                                <CircularProgress size={80} />
                            </Box>
                        }
                        { (!isLoading && images?.length === 0) &&
                            <Box sx={{display: 'flex', position: 'absolute', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography>No results found.</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            </Stack>
        </DialogContent>
    )
}

function ImageDropperDialog({close, file: dropFile}) {
    const [file, setFile] = useState(dropFile);

    const cancel = useCallback(() => close(), [close]);
    const submit = useCallback((image) => close(image), [close]);

    return (
        <CommonDialog close={cancel} title={file ? 'Upload Image' : 'Browse Images'}>
            { file ?
                <UploadImageDialog cancel={cancel} submit={submit} file={file} />
                    :
                <BrowseImageDialog cancel={cancel} submit={submit} upload={setFile} />
             }
        </CommonDialog>
    );
}

function reduceImageDropperState(state, action) {
    if (action.type === 'open') {
        const { resolve, file } = action;
        if (state.isOpen) {
            resolve(null);
            return state;
        }
        else {
            return { open: true, resolve: resolve, file: file };
        }
    }
    else if (action.type === 'close') {
        state.resolve(action.result ?? null);
        return { open: false };
    }
    throw Error();
}

function ImageDropper(props) {
    const [state, dispatchState] = useReducer(reduceImageDropperState, {open: false});

    const close = useCallback((data) => {
        dispatchState({type: 'close', result: data});
    }, []);

    const open = useCallback(async (file) => {
        return new Promise((resolve, reject) => {
            dispatchState({type: 'open', resolve: resolve, file: file});
        })
    }, []);
   
    return (
        <>
            {state.open && <ImageDropperDialog file={state.file} close={close} />}
            <ImageDropperContext.Provider value={open}>
                {props.children}
            </ImageDropperContext.Provider>
        </>
    )
}

export { ImageDropper, useImageDropper, UploadImageDialog };
