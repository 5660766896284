import { Stack, Typography } from '@mui/material';
import { getFeatureTypeLabel } from './MonsterFeatureEditor.js';
import { MarkdownViewer } from '../common/MarkdownViewer.js';
import Section from '../common/Section.js';
import { diceFormulaToString } from '../../dice.js';

export default function MonsterFeatureViewer({data}) {
    return (
        <Stack spacing={1} mt={1}>
            <Typography variant="subtitle1">Monster {getFeatureTypeLabel(data.type)}</Typography>
            <Stack spacing={0.5}>
                { data.cr && <Section label="CR Adjustment">{data.cr}</Section>}
                { data.limit && <Section label="Limit">{data.limit}</Section> }
                { data.damage && <Section label="Damage (Est.)">{diceFormulaToString(data.damage)}</Section>}
                { data.legendaryActions && <Section label="Legendary Actions Used">{data.legendaryActions ?? 1}</Section>}
            </Stack>
            <MarkdownViewer text={data.description} />
        </Stack>
    )
}