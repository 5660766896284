import { useTheme, useMediaQuery, Box } from '@mui/material';

export default function ColumnLayout({breakpoint, children, ...props}) {
    const theme = useTheme();

    const twoColumns = useMediaQuery(theme.breakpoints.up(breakpoint ?? 'lg'));
    if (twoColumns) {
        return <Box style={{columns: 2, width: '100%'}} {...props}>{children}</Box>;
    }
    else {
        return <Box style={{width: '100%'}} {...props}>{children}</Box>;
    }
}
