import { useState, useCallback, forwardRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';

function getOptionLabelDefault(o) {
    if (typeof o === 'string') {
        return o;
    }
    else if (o?.label) {
        return o.label;
    }
    else {
        return '[invalid]';
    }
}

const SelectBox = forwardRef(({label, options, onChange, fetchOptions, renderOption, noOptionsText, loadingText, getOptionLabel, InputProps, value, sx, ...props}, ref) => {
    const [asyncOptions, setAsyncOptions] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const getOptionLabelActual = getOptionLabel ?? getOptionLabelDefault;
    const actualOptions = asyncOptions ?? (options ?? []);

    const handleOpen = useCallback(async () => {
        setIsOpen(true);
        if (fetchOptions && !asyncOptions && !isLoading) {
            setIsLoading(true);
            
            try {
                const res = await fetchOptions();
                if ((res?.length ?? 0) === 0) {
                    setError(noOptionsText ?? 'No results found.');
                }
                else {
                    setAsyncOptions(res);
                }
            }
            catch (error) {
                setError(error?.message ?? 'Failed to fetch options');
            }

            setIsLoading(false);
        }
    }, [isLoading, asyncOptions, fetchOptions, noOptionsText]);

    const handleClose = useCallback(async () => {
        setIsOpen(false);
    }, []);

    const handleChange = useCallback((e, v) => {
        if (onChange) {
            onChange(e, v);
        }
    }, [onChange]);

    const [inputValue, setInputValue] = useState('');
    const handleInputChange = useCallback((e, v) => {
        setInputValue(v);
    }, []);
    const handleBlur = useCallback((e) => {
        for (let option of asyncOptions ?? (options ?? [])) {
            if (getOptionLabelActual(option) === inputValue) {
                if (value !== option) {
                    if (onChange) {
                        onChange(e, option);
                    }
                }
                break;
            }
        }
    }, [inputValue, options, asyncOptions, onChange, getOptionLabelActual, value]);

    if (!renderOption) {
        renderOption = (props, option) => <li {...props} key={props['data-option-index']}>{props.key}</li>;
    }

    return (
        <Autocomplete 
            open={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            loading={isLoading || Boolean(error)}
            loadingText={error ?? loadingText}
            options={actualOptions} 
            getOptionLabel={getOptionLabelActual}
            onChange={handleChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            onBlur={handleBlur}
            ref={ref}
            value={value ?? null}
            sx={{
                ...(sx ?? {}),
            }}
            {...props}
            renderOption={renderOption}
            renderInput={(params) => 
                <TextField {...params} label={label} InputProps={{...params.InputProps, ...(InputProps ?? [])}} />
            } />
    );
});

export default SelectBox;
export { SelectBox };
