import { forwardRef, useState, useCallback } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { parseDiceFormula, getDiceFormulaMetrics, diceFormulaToString } from '../../dice.js';

const DiceFormulaField = forwardRef(({label, value, onChange, noMetrics, ...props}, ref) => {
    const [inputValue, setInputValue] = useState();
    const [error, setError] = useState(false);

    const handleChange = useCallback((e) => {
        setInputValue(e.target.value);

        const newValue = parseDiceFormula(e.target.value);
        if (newValue) {
            onChange(e, newValue);
            setError(false);
        }
        else {
            setError(true);
        }
    }, [onChange]);

    const metrics = getDiceFormulaMetrics(value);
    const showMetrics = !noMetrics && metrics.max > 0;

    const handleBlur = useCallback(() => {
        setInputValue();
        setError(false);
    }, []);

    const handleFocus = useCallback(() => {
        setInputValue(diceFormulaToString(value ?? {} ));
    }, [value]);

    return (
        <TextField 
            {...props}
            autoComplete="off"
            label={label} 
            value={inputValue ?? diceFormulaToString(value ?? {})} 
            onChange={handleChange} 
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={error}
            ref={ref}
            InputProps={{
                endAdornment: showMetrics && <InputAdornment position="end" sx={{userSelect: 'none', color: 'inherit'}}>[{metrics.min} - {metrics.avg} - {metrics.max}]</InputAdornment>
            }}
        />
    ); 
});

export default DiceFormulaField;
export { DiceFormulaField };
