import { useEffect, useRef, useState, useReducer, useCallback, memo } from 'react';
import { useTheme, Box, Stack, Typography, TextField, Slider, Input, InputAdornment, Button } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useStateBlockArray } from '../../utils.js';
import { landmarkIcons, landmarkIconKeys, getLandmarkIconLabel } from './MapCommon.js';
import PropertiesPanel from '../common/PropertiesPanel.js';
import SelectBox from '../common/SelectBox.js';
import DocumentPicker from '../common/DocumentPicker.js';
import MarkdownField from '../common/MarkdownField.js';
import TagPicker from '../common/TagPicker.js';
import ImagePicker from '../common/ImagePicker.js';

function cameraReducer(state, action) {
    if (action.type === 'zoom') {
        let newZoom = Math.min(5, Math.max(0.2, state.zoom + action.value.zoom));

        const targetX = (action.value.x - state.x) / state.zoom;
        const targetY = (action.value.y - state.y) / state.zoom;
        const newX = action.value.x - targetX * newZoom;
        const newY = action.value.y - targetY * newZoom;

        return { 
            x: newX,
            y: newY,
            zoom: newZoom
        };
    }
    else if (action.type === 'pan') {
        return {
            ...state,
            x: state.x + action.value.x,
            y: state.y + action.value.y
        };
    }
    else if (action.type === 'restore') {
        return action.value;
    }
    return state;
}

function stopContextMenu(e) {
    e.preventDefault();
    return false;
}

const deleteIcon = <DeleteIcon />;

function LandmarkProperties({data, index, update, remove}) {
    const validatedSize = Math.max(25, Math.min(300, Number(data.size ?? 100)));

    return (
        <Stack spacing={2} mt={2}>
            <TextField label="Name" fullWidth autoComplete="off" size="small"
                value={data.name ?? ''}
                onChange={(e) => update(index, {name: e.target.value})}
            />
            <TextField label="Label" fullWidth autoComplete="off" size="small"
                value={data.label ?? ''}
                onChange={(e) => update(index, {label: e.target.value})}
            />
            <SelectBox label="Icon" fullWidth size="small"
                options={landmarkIconKeys}
                getOptionLabel={getLandmarkIconLabel}
                value={data.icon}
                onChange={(e, v) => update(index, {icon: v})}
            />
            <Stack direction="row" width="100%" spacing={2} pl={2} pr={2}>
                <Slider min={25} max={300} step={25} defaultValue={100}
                    value={validatedSize}
                    onChange={(e, v) => update(index, {size: v})}
                />
                <Input size="small" sx={{width: '5em'}}
                    value={data.size ?? 100}
                    onChange={(e) => update(index, {size: e.target.value})}
                    endAdornment={
                        <InputAdornment position="end">
                            <Typography>%</Typography>
                        </InputAdornment>
                    }
                    inputProps={{
                        min: 25,
                        max: 300
                    }}
                />
            </Stack>
            <DocumentPicker label="Link" fullWidth size="small"
                value={data.link ?? null}
                onChange={(e, v) => update(index, {link: v})}
            />
            <MarkdownField label="Description" fullWidth rows={8} size="small"
                value={data.description ?? ''}
                onChange={(v) => update(index, {description: v})}
            /> 
            <Box>
                <Button startIcon={deleteIcon} onClick={() => remove(index)}>Delete Landmark</Button>
            </Box>
        </Stack>
    );
}

function MapProperties({data, update}) {
    const validatedSize = Math.max(25, Math.min(300, Number(data.scale ?? 100)));

    return (
        <Stack spacing={2} mt={2}>
            <TextField autoFocus required label="Title" fullWidth autoComplete="off" value={data.title ?? ''} onChange={(e) => update({title: e.target.value})} />
            <TextField label="Description" fullWidth autoComplete="off" value={data.description ?? ''} onChange={(e) => update({description: e.target.value})} />
            <TagPicker label="Tags" fullWidth category={document.category} value={data.tags} onChange={(e, v) => update({tags: v})} />
            <ImagePicker size="small" data={data} update={update} />
            <Stack direction="row" width="100%" spacing={2} pl={2} pr={2}>
                <Slider min={25} max={300} step={25} defaultValue={100}
                    value={validatedSize}
                    onChange={(e, v) => update({scale: v})}
                />
                <Input size="small" sx={{width: '5em'}}
                    value={data.scale ?? 100}
                    onChange={(e) => update({scale: e.target.value})}
                    endAdornment={
                        <InputAdornment position="end">
                            <Typography>%</Typography>
                        </InputAdornment>
                    }
                    inputProps={{
                        min: 25,
                        max: 300
                    }}
                />
            </Stack>
        </Stack>
    );
}

function Landmark({data, index, selected, scale, onPointerDown, onPointerUp, onPointerMove}) {
    const theme = useTheme();

    const pointerDown = useCallback((e) => {
        onPointerDown?.(e, index);
    }, [onPointerDown, index]);

    const pointerUp = useCallback((e) => {
        onPointerUp?.(e, index);
    }, [onPointerUp, index]);
    
    const pointerMove = useCallback((e) => {
        onPointerMove?.(e, index);
    }, [onPointerMove, index]);
    
    const validatedSize = Math.max(25, Math.min(300, Number(data.size ?? 100)));
    const scaledSize = validatedSize * ((scale ?? 100) / 100);
    const size = `${scaledSize}%`;

    return (
        <Box 
            sx={{position: 'absolute', top: data.y, left: data.x, zIndex: 1200, userSelect: 'none', touchAction: 'none'}}
            onPointerDown={pointerDown}
            onPointerUp={pointerUp}
            onPointerMove={pointerMove}
            onContextMenu={stopContextMenu}
            tabIndex="0"
        >
            <Box 
                sx={{
                    transform: `translate(-50%, -50%) scale(${size})`,
                    filter: 'drop-shadow(6px 6px 5px black)',
                    backgroundColor: selected ? theme.palette.secondary.dark : theme.palette.primary.dark,
                    borderRadius: '8px',
                    padding: 1,
                    '&:hover': {
                        backgroundColor: selected ? theme.palette.secondary.dark : theme.palette.primary.main
                    }
                }}
            >
                <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
                    { data.icon && landmarkIcons[data.icon]?.icon }
                    { data.label && <Typography sx={{whiteSpace: 'nowrap'}}>{data.label}</Typography> }
                    { (!data.icon && !data.label) && <Box sx={{width: '8px', height: '8px'}} />}
                </Stack>
            </Box>
        </Box>
    );
}

const Landmarks = memo(({items, getKey, selectedIndex, scale, pointerDown, pointerUp, pointerMove}) => {
    return (<>
        { items.map((item, index) =>
            <Landmark key={getKey(index)} data={item} index={index} 
                selected={index === selectedIndex}
                scale={scale}
                onPointerDown={pointerDown} 
                onPointerUp={pointerUp} 
                onPointerMove={pointerMove}/>
        )}
    </>);
});

function MapEditor({data, update, documentId}) {
    const storageKey = `map-${documentId}`;
    const [landmarks, addLandmark, updateLandmark, removeLandmarkInner, swapLandmark, getLandmarkKey] = useStateBlockArray('landmarks', data, update);
    const [pointer, setPointer] = useState(null);
    const [isDragging, setDragging] = useState(false);
    const [camera, updateCamera] = useReducer(cameraReducer, { zoom: 1.0, x: 0, y: 0});
    const [panelOpen, setPanelOpen] = useState(true);
    const ref = useRef();
    const theme = useTheme();

    const removeLandmark = useCallback((index) => {
        removeLandmarkInner(index);
        if (data.selectedIndex === index) {
            update({selectedIndex: null});
        }
    }, [update, data.selectedIndex, removeLandmarkInner]);

    const editorRef = useRef();
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, []);

    useEffect(() => {
        try {
            const storedCamera = window.sessionStorage.getItem(storageKey);
            if (storedCamera) {
                updateCamera({type: 'restore', value: JSON.parse(storedCamera)});
            }
        }
        catch (Error) {
        }
    }, [storageKey])

    useEffect(() => {
        window.sessionStorage.setItem(storageKey, JSON.stringify(camera));
    });

    const getLocationFromPointerEvent = useCallback((e) => {
        const rect = ref.current.getBoundingClientRect();
        const pointerX = e.clientX - rect.left;
        const pointerY = e.clientY - rect.top;
        return {x: (pointerX - camera.x) / camera.zoom, y: (pointerY - camera.y) / camera.zoom}
    }, [camera]);

    const wheel = useCallback((e) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        updateCamera({
            type: 'zoom', 
            value: {
                zoom: e.deltaY * -0.001,
                x: e.clientX - rect.left,
                y: e.clientY - rect.top
            }
        });
    }, []);

    const keyDown = useCallback((e) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
            if (data.selectedIndex && landmarks[data.selectedIndex]) {
                removeLandmark(data.selectedIndex);
            }
        }
    }, [landmarks, data.selectedIndex, removeLandmark]);

    const mapPointerDown = useCallback((e) => {
        if (!pointer && (e.button === 0 || e.pointerType === "touch")) {
            e.stopPropagation();
            e.preventDefault();
            ref.current.setPointerCapture(e.pointerId);
            setPointer({id: e.pointerId, x: e.clientX, y: e.clientY});
            ref.current.focus();
        }
        else if (e.button === 2) {
            e.stopPropagation();
            e.preventDefault();
            const coords = getLocationFromPointerEvent(e);
            addLandmark({x: coords.x, y: coords.y, size: 100, icon: 'poi'});
            setPanelOpen(true);
            update({selectedIndex: landmarks.length});
        }
    }, [pointer, addLandmark, getLocationFromPointerEvent, landmarks, update]);

    const mapPointerUp = useCallback((e) => {
        if (e.pointerId === pointer?.id && (e.button === 0 || e.pointerType === "touch")) {
            if (!isDragging) {
                update({selectedIndex: null});
            }
            setPointer(null);
            setDragging(false);
            ref.current.releasePointerCapture(e.pointerId);
        }
    }, [pointer, isDragging, update]);

    const mapPointerMove = useCallback((e) => {
        if (pointer && e.pointerId === pointer.id) {
            setDragging(true);
            let movementX = e.movementX;
            let movementY = e.movementY;
            // workaround for safari ios which does not support movementX/movementY
            if (!movementX && !movementY)
            {
                movementX = e.clientX - pointer.x;
                movementY = e.clientY - pointer.y;
            }
            updateCamera({type: 'pan', value: {x: movementX, y: movementY}});
            setPointer({id: e.pointerId, x: e.clientX, y: e.clientY});
        }
    }, [pointer]);

    const landmarkPointerDown = useCallback((e, index) => {
        if (e.button === 0) {
            ref.current.focus();
            if (data.selectedIndex !== index)
            {
                update({selectedIndex: index});
                setPanelOpen(true);   
            }
            else
            {
                setPointer({id: e.pointerId, x: e.clientX, y: e.clientY});
                e.currentTarget.setPointerCapture(e.pointerId);
            }
            e.stopPropagation();
            e.preventDefault();
        }
        else if (e.button === 2) {
            e.stopPropagation();
            e.preventDefault();
        }
    }, [data.selectedIndex, update]);

    const landmarkPointerUp = useCallback((e, index) => {
        if (e.pointerId === pointer?.id && (e.button === 0 || e.pointerType === "touch")) {
            setPointer(null);
            e.stopPropagation();
            e.currentTarget.releasePointerCapture(e.pointerId);
        }
    }, [pointer]);

    const landmarkPointerMove = useCallback((e, index) => {
        if (pointer && e.pointerId === pointer?.id) {
            e.stopPropagation();
            const location = getLocationFromPointerEvent(e);
            updateLandmark(index, {x: location.x, y: location.y});  
            setPointer({id: e.pointerId, x: e.clientX, y: e.clientY});
        }
    }, [pointer, getLocationFromPointerEvent, updateLandmark]);

    const scale = `${Math.trunc(camera.zoom * 100)}%`;
    const transform = `translate(${camera.x}px, ${camera.y}px) scale(${scale}, ${scale}) `;
    const selectedLandmark = data?.landmarks?.[data.selectedIndex];

    return (<>
        <Box
            ref={ref} 
            sx={{
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%',
                transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                }),
                height: '100%', 
                overflow: 'hidden', 
                userSelect: 'none',
                touchAction: 'none'
            }}
            onWheel={wheel}
            onPointerDown={mapPointerDown}
            onPointerUp={mapPointerUp}
            onPointerMove={mapPointerMove}   
            onContextMenu={stopContextMenu}    
            onKeyDownCapture={keyDown}
            tabIndex="0"
        >
            <Box sx={{transform: transform, transformOrigin: '0px 0px'}} tabIndex={0}>
                { data.image && <img src={data.image} alt={data.altText} style={{position: 'absolute', top: 0, left: 0 }} /> }
                <Landmarks items={landmarks} selectedIndex={data.selectedIndex} scale={data.scale} 
                    getKey={getLandmarkKey}
                    pointerDown={landmarkPointerDown}
                    pointerUp={landmarkPointerUp}
                    pointerMove={landmarkPointerMove}
                />
            </Box>
        </Box>
        <PropertiesPanel label={selectedLandmark ? "Landmark Properties" : "Map Properties"} 
            open={panelOpen}
            onChange={(v) => setPanelOpen(v)}
        >
            { selectedLandmark ?
                <LandmarkProperties 
                    key={data.selectedIndex} 
                    index={data.selectedIndex}
                    data={selectedLandmark}  
                    update={updateLandmark}
                    remove={removeLandmark}   
                /> 
                :
                <MapProperties data={data} update={update} />
            }
        </PropertiesPanel>
    </>);
};

export default MapEditor;
