import { useCallback } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export default function CommonDialog({title, close, children, ...props}) {
    const handleClose = useCallback((_, reason) => {
        if (reason !== "backdropClick")
        {
            close();
        }
    }, [close]);

    return (
        <Dialog maxWidth='sm' fullWidth open={true} onClose={handleClose} {...props}>
            <DialogTitle>
                {title}
                <IconButton onClick={close} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={(e) => e.preventDefault()}>
                {children}
            </form>
        </Dialog>
    );
}
