import { styled, Box, Typography } from '@mui/material';

const SectionContainer = styled('div')({
    breakInside: 'avoid',
    display: 'block',
    margin: 0,
    marginBlockStart: 0,
    marginBlockEnd: 0
});

function Section({label, variant, children, compact}) {
    return (
        <SectionContainer sx={{
            '&:not(:last-child)': {
                marginBlockEnd: compact ? 0 : '0.5em'
            }
        }}>
            <Typography variant={variant ?? 'label1'} component='span'>{label}</Typography>&nbsp;
            <Typography variant='body1' component='span'>{children}</Typography>
        </SectionContainer>
    )
};

export default Section;
