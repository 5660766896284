import { useCallback, Fragment } from 'react';
import { Box, Stack, Typography, Paper, Divider, Table, TableHead, TableBody, TableRow, TableCell, Avatar, capitalize } from "@mui/material";
import { Image } from '../common/Image.js';
import Section from '../common/Section.js';
import PreviewLink from '../common/PreviewLink.js';
import { getDiceFormulaMetrics, diceFormulaToString, makeDiceFormula } from "../../dice.js";
import { 
    getSkillAbilityScore, 
    getAbilityScoreModifier, 
    getSkillLabel, 
    getAbilityScoreLabel,
    getAttackTypeLabel,
    challengeRatingToString,
    challengeRatingToXP
} from '../../dnd5e.js';
import {
    capitalizeFirstLetter,
    formatCount,
    formatIncidents,
    formatList,
    formatSentence
} from '../../text.js';
import { MarkdownViewer } from '../common/MarkdownViewer.js';
import ColumnLayout from '../common/ColumnLayout.js';

const spellLevelNames = [
    'Cantrips',
    '1st Level',
    '2nd Level',
    '3rd Level',
    '4th Level',
    '5th Level',
    '6th Level',
    '7th Level',
    '8th Level',
    '9th Level'
];

function getBasicMultiattackDescription(data) {
    let attacks = [];

    for (let action of data.actions.actions) {
        if (action.type === 'attack' && action.multiattackLimit !== 0) {
            if (action.multiattackLimit !== 0) {
                let actionStr;
                if (action.multiattackLimit) {
                    actionStr = `${action.name} (${formatIncidents(action.multiattackLimit)})`;
                }
                else {
                    actionStr = action.name;
                }
                attacks = [...attacks, actionStr];
            }
        }
    }

    let attacksString = '';
    if (attacks.length > 1)
    {
        attacksString = `, choosing from ${formatList(attacks, 'and')}`;
    }

    return `${capitalizeFirstLetter(data.vitals.noun)} makes ${formatCount(data.actions.attacksPerTurn)} attacks${attacksString}.`;
}

function hasBasicMultiattack(data) {
    return (data.actions.attacksPerTurn ?? 1) > 1 && 
        !data.actions.actions.some((a) => a.name?.localeCompare('Multiattack', undefined, { sensitivity: 'accent'  }) === 0);
}

function getModifierString(modifier) {
    return modifier > 0 ? `+${modifier}` : modifier.toString();
}

function ActionView({action, getAbilityModifier, proficiencyBonus, tokens}) {
    const limit = action.limit ? ` (${action.limit})` : '';

    let label = action.name + limit;

    if (action.type === 'attack') {
        if (action.attackType) {
            let abilityModifier = getAbilityModifier(action.abilityScore);
            let attackBonus = proficiencyBonus + abilityModifier;

            let effectStrings = [];
            for (let effect of (action.effects ?? [])) {
                let damageBonus = 0;
                if (!effect.ignoreAbilityModifier) {
                    damageBonus += abilityModifier;
                }

                const damageFormula = makeDiceFormula(
                    (effect?.baseDamage?.n ?? 1), 
                    (effect?.baseDamage?.m ?? 0),
                    (effect?.baseDamage?.c ?? 0) + damageBonus);
                const damageMetrics = getDiceFormulaMetrics(damageFormula);
                const damageDiceString = diceFormulaToString(damageFormula);
                const damageString = `${Math.trunc(damageMetrics.avg)} (${damageDiceString}) ${effect.damageType ?? ''} damage`;

                if (effect.save && effect.save !== 'none') {
                    let description = `the target must make a DC ${effect.saveDC} ${getAbilityScoreLabel(effect.saveType)} saving throw, taking ${damageString} on a failed save`;
                    if (effect.save === 'half') {
                        description += ', or half as much damage on a successful one';
                    }
                    effectStrings = [...effectStrings, description];
                }
                else {
                    effectStrings = [...effectStrings, damageString];
                }
            }

            return (
                <Section variant="label2" label={label}>
                    <i>{getAttackTypeLabel(action.attackType)} Attack: </i> {getModifierString(attackBonus)} to hit, reach {action.range}, one target. 
                    <i> Hit: </i> {effectStrings.join(', plus ')}. {action.description && <MarkdownViewer text={action.description} tokens={tokens} inline />}                    
                </Section>
            );
        }
    }
    else {
        return (
            <Section variant="label2" label={label}>
                {action.description && <MarkdownViewer text={action.description} tokens={tokens} inline />}
            </Section>
        );
    }
}

function TraitView({trait, tokens}) {
    return (
        <Section variant="label2" label={trait.name}>
            {trait.description && <MarkdownViewer text={trait.description} tokens={tokens} inline />}
        </Section>    
    );
}

function LegendaryActionView({action, tokens}) {
    const costText = action.actionsUsed > 1 ? ` (Costs ${action.actionsUsed} Actions)` : '';
    return (
        <Section variant="label2" label={action.name + costText}>
            {action.description && <MarkdownViewer text={action.description} tokens={tokens} inline />}
        </Section>
    )
}

function LairActionView({action, tokens}) {
    return (
        <Section variant="label2" label={action.name}>
            {action.description && <MarkdownViewer text={action.description} tokens={tokens} inline />}
        </Section>
    )
}

function MonsterStatBlock({data, template, variant}) {
    const { vitals, defenses, savingThrows, skills, spellcasting, traits, actions, legendary, lair, description } = data;
    const savingThrowList = savingThrows.savingThrows;
    const skillList = skills.skills;
    const actionList = actions.actions;
    const traitList = traits.traits;
    const lairActionList = lair.lairActions;
    const legendaryActionList = legendary.legendaryActions;

    const tokens = {
        '@name': data?.vitals?.name,
        '@Name': capitalizeFirstLetter(data?.vitals?.name),
        '@noun': data?.vitals?.noun ?? 'the creature',
        '@Noun': capitalizeFirstLetter(data?.vitals?.noun)
    };

    const getAbilityScore = useCallback((ability) => {
        return vitals.abilityScores?.[ability] ?? 10;
    }, [vitals.abilityScores]);

    const getAbilityModifier = useCallback((ability) => {
        return getAbilityScoreModifier(getAbilityScore(ability));
    }, [getAbilityScore]);

    const getAbilityModifierString = useCallback((ability) => {
        return getModifierString(getAbilityModifier(ability));
    }, [getAbilityModifier]);

    const getSavingThrowBonus = useCallback((ability) => {
        return getAbilityModifier(ability) + 
            ((savingThrowList?.[ability]?.proficient ?? false) ? (vitals.proficiencyBonus ?? 0) : 0) +
            ((savingThrowList?.[ability]?.bonus ?? 0));
    }, [getAbilityModifier, vitals.proficiencyBonus, savingThrowList]);

    const getSkillBonus = useCallback((skill) => {
        return getAbilityModifier(getSkillAbilityScore(skill)) +
        ((skillList?.[skill]?.proficiency ?? 0) * (vitals.proficiencyBonus ?? 0)) +
        ((skillList?.[skill]?.bonus ?? 0));
    }, [getAbilityModifier, vitals.proficiencyBonus, skillList]);

    let subtitle = '';
    if (vitals.size) {
        subtitle += vitals.size + ' ';
    }
    if (vitals.type) {
        subtitle += vitals.type;
        if (vitals.subtype) {
            subtitle += ' (' + vitals.subtype + ')';
        }
    }
    if (vitals.alignment) {
        if (subtitle) {
            subtitle += ', ';
        }
        subtitle += vitals.alignment;
    }
    subtitle = formatSentence(subtitle);

    const noun = vitals.noun ?? `the ${vitals.name.toLowerCase()}`;

    const proficienyBonus = (vitals.proficiencyBonus ?? 0);
    const armorType = defenses.armorType ? ` (${defenses.armorType})` : '';

    const conModifier = getAbilityModifier('con');
    const totalHitPoints = makeDiceFormula(
        (defenses.hitPoints?.n ?? 0), 
        (defenses.hitPoints?.m ?? 0), 
        (defenses.hitPoints?.c ?? 0) + (defenses.hitPoints?.m ? (conModifier * defenses.hitPoints.n) : 0));
    const hitPointsMetrics = getDiceFormulaMetrics(totalHitPoints);
    const speed = (vitals.speed ?? []).join(', ');

    const savingThrowsString = Object.entries(savingThrowList ?? [])
        .filter(([ability, save]) => (save.proficient || save.bonus))
        .map(([ability, save]) => `${ability.toUpperCase()} ${getModifierString(getSavingThrowBonus(ability))}`)
        .join(', ');
    const skillsString = Object.entries(skillList ?? [])
        .filter(([skill, data]) => (data.proficiency || data.bonus))
        .map(([skill, data]) => `${getSkillLabel(skill)} ${getModifierString(getSkillBonus(skill))}`)
        .join(', ');

    const resistances = capitalizeFirstLetter((defenses.resistances ?? []).join(', '));
    const damageImmunities = capitalizeFirstLetter((defenses.damageImmunities ?? []).join(', '));
    const conditionImmunities = (capitalizeFirstLetter(defenses.conditionImmunities ?? []).join(', '));
    const vulnerabilities = capitalizeFirstLetter((defenses.vulnerabilities ?? []).join(', '));
    const senses = capitalizeFirstLetter([...(vitals.senses ?? []), `passive Perception ${10 + getSkillBonus('perception')}`].join(', '));
    const languages = capitalizeFirstLetter((vitals.languages ?? []).join(', '));

    const standardActions = (actionList ?? [])
        .filter((action) => (action.type === 'action' || action.type === 'attack'));

    const bonusActions = (actionList ?? [])
        .filter((action) => (action.type === 'bonusAction'));

    const reactions = (actionList ?? [])
        .filter((action) => (action.type === 'reaction'));

    const basicMultiattack = hasBasicMultiattack(data);

    const isSpellcaster = (spellcasting.spellLevels ?? []).some((spellLevel) => spellLevel.slots || (spellLevel.spells ?? []).length > 0) ||
        (spellcasting.additionalSpells ?? []).length > 0;
    let spellModifier = 0;
    if (spellcasting.spellcastingAbility) {
        spellModifier += getAbilityModifier(spellcasting.spellcastingAbility);
    }
    const spellDC = 8 + spellModifier + proficienyBonus;
    const spellAttackBonus = spellModifier + proficienyBonus;

    return (
        <Box padding={1} component={Paper} elevation={2} width="100%">
            { (variant === "inline") ?
                <Typography variant="subtitle1">{template && 'Template: '}{subtitle}</Typography>
                :
                <Stack direction="row" spacing={2} marginBottom={1} alignItems="center" useFlexGap>
                    { description.thumbnail && <Avatar src={description.thumbnail} description={description.altText} sx={{width: 80, height: 80}} /> }
                    <Stack spacing={0} useFlexGap>
                        <Typography variant="h2">{vitals.name}</Typography>
                        <Typography variant="subtitle1">{template && 'Template: '}{subtitle}</Typography>
                    </Stack>
                </Stack>
            }
            <Divider />
            <ColumnLayout marginTop={1}>
                <Stack spacing={2} useFlexGap>
                    <div style={{breakInside: 'avoid'}}>
                        <Section label="Armor Class" compact>
                            {defenses.armorClass ?? 10}{armorType}
                        </Section>
                        <Section label="Hit Points" compact>
                         {Math.floor(hitPointsMetrics.avg)} ({diceFormulaToString(totalHitPoints)})
                        </Section>
                        { speed && 
                            <Section label="Speed" compact>{capitalizeFirstLetter(speed)}</Section>
                        }
                    </div>

                    <Table size="small" sx={{breakInside: 'avoid'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">STR</TableCell>
                                <TableCell align="center">DEX</TableCell>
                                <TableCell align="center">CON</TableCell>
                                <TableCell align="center">INT</TableCell>
                                <TableCell align="center">WIS</TableCell>
                                <TableCell align="center">CHA</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{getAbilityScore('str')} ({getAbilityModifierString('str')})</TableCell>
                                <TableCell align="center">{getAbilityScore('dex')} ({getAbilityModifierString('dex')})</TableCell>
                                <TableCell align="center">{getAbilityScore('con')} ({getAbilityModifierString('con')})</TableCell>
                                <TableCell align="center">{getAbilityScore('int')} ({getAbilityModifierString('int')})</TableCell>
                                <TableCell align="center">{getAbilityScore('wis')} ({getAbilityModifierString('wis')})</TableCell>
                                <TableCell align="center">{getAbilityScore('cha')} ({getAbilityModifierString('cha')})</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <div style={{breakInside: 'avoid'}}>
                        { savingThrowsString && <Section label="Saving Throws" compact>{capitalizeFirstLetter(savingThrowsString)}</Section> }
                        { skillsString && <Section label="Skills"  compact>{capitalizeFirstLetter(skillsString)}</Section> }
                        { resistances && <Section label="Damage Resistances"  compact>{capitalizeFirstLetter(resistances)}</Section> }
                        { damageImmunities && <Section label="Damage Immunities"  compact>{capitalizeFirstLetter(damageImmunities)}</Section> }
                        { conditionImmunities && <Section label="Condition Immunities" compact>{capitalizeFirstLetter(conditionImmunities)}</Section> }
                        { vulnerabilities && <Section label="Damage Vulnerabilities" compact>{capitalizeFirstLetter(vulnerabilities)}</Section> }
                        { senses && <Section label="Senses" compact>{capitalizeFirstLetter(senses)}</Section> }
                        { languages && <Section label="Languages" compact>{capitalizeFirstLetter(languages)}</Section>}
                        <Section label="Challenge" compact>
                            {`${challengeRatingToString(vitals.challengeRating)} (${challengeRatingToXP(vitals.challengeRating).toLocaleString()} XP)`}
                        </Section>
                        <Section label="Proficiency Bonus" compact>{(vitals.proficiencyBonus ?? 2).toString()}</Section>
                    </div>

                    <Divider />

                    { (isSpellcaster || legendary.legendaryResistances || traitList.length > 0) && 
                        <div>
                            { (legendary.legendaryResistances ?? 0) > 0 && 
                                <Section variant="label2" label={`Legendary Resistance (${legendary.legendaryResistances}/Day)`}>
                                    If {noun} fails a saving throw, it can choose to succeed instead.
                                </Section>
                            }
                            { isSpellcaster &&
                                <>
                                    <Section variant="label2" label="Spellcasting">
                                        {`${capitalizeFirstLetter(noun)}`}'s spellcasting ability is {getAbilityScoreLabel(spellcasting.spellcastingAbility)} (spell 
                                        save DC {spellDC}, {getModifierString(spellAttackBonus)} to hit with spell attacks). It can cast the following spells:
                                    </Section>
                                    <Stack spacing={0} useFlexGap>
                                        <ul>
                                            { spellcasting.additionalSpells.map((data, index) => 
                                                data.spells?.length > 0 &&
                                                <Typography component="li" key={`additionalSpell_${index}`}>
                                                    {data.limit}:&nbsp;
                                                    {(data.spells ?? []).map((spell, index) => 
                                                        <Fragment key={index}>
                                                            {typeof spell === 'string' ? <i>{spell}</i> : <PreviewLink href={`/document/${spell.id}`}>{spell.label}</PreviewLink> }
                                                            {index < (data.spells.length - 1) && ', '} 
                                                        </Fragment>
                                                    )}
                                                </Typography>
                                            )}
                                            { spellcasting.spellLevels.map((spellLevel, index) => 
                                                spellLevel.spells?.length > 0 &&
                                                    <Stack direction="row" spacing={1} key={index} useFlexGap>
                                                        <Typography component="li" key={`spellLevel_${index}`}>
                                                            {spellLevelNames[index]}{index > 0 && <> ({spellLevel.slots ?? 0} slots)</>}:&nbsp; 
                                                            {(spellLevel.spells ?? []).map((spell, index) => 
                                                                <Fragment key={index}>
                                                                    {typeof spell === 'string' ? <i>{spell}</i> : <PreviewLink href={`/document/${spell.id}`}>{spell.label}</PreviewLink> }
                                                                    {index < (spellLevel.spells.length - 1) && ', '} 
                                                                </Fragment>
                                                            )}
                                                        </Typography>
                                                    </Stack>                                            
                                            )}
                                        </ul>
                                    </Stack>
                                </>
                            }
                            { traitList.map((trait, index) => 
                                <TraitView key={index} trait={trait} tokens={tokens} />
                            )}
                        </div>
                    }

                    { (standardActions.length > 0) && 
                        <div>
                            <Typography variant="h3">Actions</Typography>
                            { basicMultiattack && 
                                <Section variant="label2" label="Multiattack">{getBasicMultiattackDescription(data)}</Section>
                            }
                            { standardActions.map((action, index) =>
                                <ActionView key={index} action={action} tokens={tokens} getAbilityModifier={getAbilityModifier} proficiencyBonus={vitals.proficiencyBonus ?? 0} />
                            )}
                        </div>
                    }
                    { (bonusActions.length > 0) && 
                        <div>
                            <Typography variant="h3">Bonus Actions</Typography>
                            { bonusActions.map((action, index) =>
                                <ActionView key={index} action={action} tokens={tokens} getAbilityModifier={getAbilityModifier} proficiencyBonus={vitals.proficiencyBonus ?? 0} />
                            )}
                        </div>
                    }               
                    { (reactions.length > 0) &&
                        <Stack spacing={1} useFlexGap>
                            <Typography variant="h3">Reactions</Typography>
                            { reactions.map((action, index) =>
                                <ActionView key={index} action={action} tokens={tokens} getAbilityModifier={getAbilityModifier} proficiencyBonus={vitals.proficiencyBonus ?? 0} />
                            )}
                        </Stack>
                    }
                    { (legendaryActionList.length > 0 && legendary.legendaryActionUses > 0) &&
                        <div>
                            <Typography variant="h3">Legendary Actions</Typography>
                            <Typography>
                                {capitalizeFirstLetter(noun)} can take {legendary.legendaryActionUses ?? 0} legendary actions, choosing from the options below. Only one legendary action option can be used
                                at a time and only at the end of another creature's turn. This creature regains spent legendary actions at the start of its turn.
                            </Typography>
                            { legendaryActionList.map((action, index) =>
                                <LegendaryActionView key={index} action={action} tokens={tokens} getAbilityModifier={getAbilityModifier} proficiencyBonus={vitals.proficiencyBonus ?? 0} />
                            )}
                        </div>
                    }                
                    { (lairActionList.length > 0) &&
                        <div>
                            <Typography variant="h3">Lair Actions</Typography>
                            <Typography>
                                When in its lair, {noun} can take one of the following lair actions on initiative count {lair.lairActionInitiative ?? 20} (losing initiative ties). {lair.lairActionRules}
                            </Typography>
                            { lairActionList.map((action, index) =>
                                <LairActionView key={`lairAction_${index}`} action={action} tokens={tokens} getAbilityModifier={getAbilityModifier} proficiencyBonus={vitals.proficiencyBonus ?? 0} />
                            )}
                        </div>
                    }
                </Stack>
            </ColumnLayout>
        </Box>
    );   
}

function MonsterViewer({data, template}) {
    const imageOnly = data?.description?.image && !data?.description?.appearance && !data?.description?.behavior && !data?.lair?.lair && !data.description?.lore;

    const tokens = {
        '@name': data?.vitals?.name,
        '@Name': capitalizeFirstLetter(data?.vitals?.name),
        '@noun': data?.vitals?.noun ?? 'the creature',
        '@Noun': capitalizeFirstLetter(data?.vitals?.noun)
    };

    return (
        <Stack spacing={2}>
            <MonsterStatBlock data={data} template={template} variant="inline" />
            <ColumnLayout>
                <Stack spacing={2}>
                    { (!imageOnly && data.description.image) &&
                        <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                            <Image src={data.description.image} alt={data.description.altText} sx={{ maxWidth: '95%', objectFit: 'cover' }} />
                        </Box>
                    }
                    { data.description.appearance &&
                        <div>
                            <Typography variant="h3">Appearance</Typography>
                            <MarkdownViewer text={data.description.appearance} tokens={tokens} />
                        </div>
                    }
                    { data.description.behavior &&
                        <div>
                            <Typography variant="h3">Behavior</Typography>
                            <MarkdownViewer text={data.description.behavior} tokens={tokens} />
                        </div>
                    }
                    { data.lair.lair &&
                        <div>
                            <Typography variant="h3">Lair</Typography>
                            <MarkdownViewer text={data.lair.lair} tokens={tokens} />
                        </div>
                    }
                    { data.description.lore &&
                        <div>
                            <Typography variant="h3">Lore</Typography>
                            <MarkdownViewer text={data.description.lore} tokens={tokens} />
                        </div>
                    }
                </Stack>
            </ColumnLayout>
            { (imageOnly && data.description.image) && 
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <Image src={data.description.image} alt={data.description.altText} sx={{ maxWidth: '95%', objectFit: 'cover' }} />
                </Box>
            }
        </Stack>
    );
}

export default MonsterViewer;
export { MonsterStatBlock };
