const Map = {
    name: 'Map',
    plural: 'Maps',
    noFooter: true,

    init: () => Map.deserialize({}),
    
    deserialize: (document) => ({
        title: document.title ?? null,
        tags: document.tags ?? [],
        description: document.description ?? null,
        thumbnail: document.thumbnail ?? null,
        altText: document.altText ?? null,
        image: document.data?.image ?? null,
        scale: document.data?.scale ?? null,
        landmarks: document.data?.landmarks ?? []
    }),

    serialize: (data) => ({
        title: data.title,
        description: data.description,
        tags: data.tags,
        thumbnail: data.thumbnail,
        altText: data.altText,
        data: {
            image: data.image,
            scale: data.scale,
            landmarks: data.landmarks
        }
    }),

    validate: (data) => {
        let errors = [];
        if (!data.title) {
            errors.push('A title is required.');
        }
        return errors;
    },
}

export default Map;


