import { useState, useEffect, useCallback, createElement, useReducer } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { useMediaQuery, useTheme, LinearProgress, Typography, Alert, TextField, InputAdornment, Stack, Link, IconButton, Accordion, AccordionSummary, AccordionDetails, Pagination, Menu, MenuItem } from '@mui/material';
import { Search as SearchIcon, ExpandMore as ExpandIcon, Sort as SortIcon, RestartAlt as ResetIcon } from '@mui/icons-material';
import MultiRepositoryPicker from '../common/MultiRepositoryPicker.js';
import MultiColumnList from '../common/MultiColumnList.js';
import { TagPicker } from '../common/TagPicker.js';
import { DocumentButton } from './DocumentButton.js';
import { documentCategories } from '../../documents.js';
import { listDocuments } from '../../api.js';
import { useSetDocumentContext, useTitleBar } from '../../globals.js';
import { useStateBlock } from '../../utils.js';


function queryStatePostprocess(state, action) {
    return { ...state, ...action, key: (state.key ?? 0) + 1 };
}

function resultsReducer(state, action) {
    if (action.key > state.key) {
        return action;
    }
    else {
        return state;
    }
}

const expandIcon = <ExpandIcon />;
const defaultResultsPerPage = 20;

function SortButton({update, category}) {
    const popupState = usePopupState({ variant: 'menu' });

    const criteria = [
        { name: 'title', label: 'Title (A-Z)', order: 1 },
        { name: 'title', label: 'Title (Z-A)', order: -1 },
        ...(category.sort ?? []),
        { name: 'lastModified', label: 'Date Modified (Newer First)', order: -1 },
        { name: 'lastModified', label: 'Date Modified (Older First)', order: 1}
    ];

    const criteriaClicked = useCallback((c) => {
        update({sort: c.name, order: c.order});
    }, [update]);

    return (
        <>
            <IconButton {...bindTrigger(popupState)}>
                <SortIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
                { criteria.map((c, i) => 
                    <MenuItem onClick={() => { criteriaClicked(c); popupState.close(); }} key={i}>{c.label}</MenuItem>
                )}
            </Menu>
        </>
    );
}

function BrowsePage() {
    const [isLoading, setIsLoading] = useState(false);
    const [filtersExpanded, setFiltersExpanded] = useState(false);
    const [query, updateQuery] = useStateBlock({ filters: {}, start: 0, key: 0, sort: 'title' }, queryStatePostprocess);
    const [{results, total, key: lastFetchKey}, updateResults] = useReducer(resultsReducer, {results: [], total: 0, key: 0});
    const [error, setError] = useState();
    const { category: categoryName } = useParams();
    const { setTitle } = useTitleBar();
    const setDocumentContext = useSetDocumentContext();
    const theme = useTheme();
    const wideScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const filters = query.filters;
    const updateFilters = useCallback((fields) => {
        let newFilters = {...filters, ...fields};
        newFilters = Object.fromEntries(Object.entries({...filters, ...fields}).filter(([k, v]) => v != null && (!Array.isArray(v) || v.length > 0) ));
        updateQuery({ filters: newFilters, count: 0 });
    }, [filters, updateQuery]);
    const setFilters = useCallback((value) => {
        updateQuery({ filters: value, count: 0 });
    }, [updateQuery]);

    const category = documentCategories[categoryName];
    const resultsPerPage = category?.resultsPerPage ?? defaultResultsPerPage;

    const numPages = Math.ceil(total / resultsPerPage);
    const currentPage = (query.start / resultsPerPage) + 1;
    const setCurrentPage = useCallback((v) => {
        updateQuery({start: (v - 1) * resultsPerPage})
    }, [updateQuery, resultsPerPage]);

    const fetchDocumentList = useCallback(async (query, category) => {
        setIsLoading(true);

        const res = await listDocuments({...query.filters, category: category}, query.sort, query.order, query.start, resultsPerPage);
        if (res.result === 'success') {
            updateResults({key: query.key, results: res.documents ?? [], total: res.total ?? 0});
            setError();
        }
        else {
            setError('Failed to retrieve results. Please try again.');
        }

        setIsLoading(false);       
    }, [resultsPerPage]);

    // Update title bar
    useEffect(() => {
        if (category) {
            setTitle(category.plural);
            setDocumentContext.setCategory(categoryName);
        }
        else {
            setError('Invalid category');
        }
    }, [setDocumentContext, category, categoryName, setTitle]);

    // Refresh results list
    useEffect(() => {
        if (category) {
            const storageKey = `/browse/${categoryName}/query`;

            // on the very first render, load the saved query or initialize a default one
            if (query.key === 0) {
                let restoredQuery = false;
                const savedString = window.sessionStorage.getItem(storageKey);
                if (savedString) {
                    try {
                        const savedQuery = JSON.parse(savedString);
                        updateQuery({
                            filters: savedQuery.filters ?? {},
                            start: savedQuery.start ?? 0,
                            sort: savedQuery.sort ?? 'title',
                            order: savedQuery.order ?? 1
                        });
                        restoredQuery = true;

                        if (savedQuery.filters && Object.keys(savedQuery.filters)?.some((k) => k !== 'text') > 0) {
                            setFiltersExpanded(true);
                        }
                    }
                    catch (Error) {
                        // clear out garbage
                        window.sessionStorage.setItem(storageKey, '');
                    }
                }                

                if (!restoredQuery) {
                    updateQuery({
                        filters: {},
                        start: 0,
                        sort: 'title',
                        order: 1
                    });
                }
            }
            // on subsequent renders, save the current query
            else {
                window.sessionStorage.setItem(storageKey, JSON.stringify(query));

                // dispatch the query as needed
                if (!isLoading && !error) {
                    if (lastFetchKey < query.key) {
                        fetchDocumentList(query, categoryName);
                    }
                    else {
                        // fix a particular broken case we shouldn't be able to get into (but happens a lot in development)
                        if (query.start > 0 && query.start >= total) {
                            updateQuery({start: 0});
                        }
                    }
                }
            }           
        }     
    });
    
    const foundNothing = !isLoading && results && results.length === 0;

    let categoryFilters;
    if (category?.filters) {
        const props = {
            data: filters,
            update: updateFilters
        };
        categoryFilters = createElement(category.filters, props, null);
    }

    const hasAnyFilters = Object.keys(filters).length > 0;

    return (
        <Stack spacing={2} sx={{width: '100%'}}>
            <Accordion elevation={0} disableGutters expanded={filtersExpanded} onChange={(e, v) => setFiltersExpanded(v)}>
                <AccordionSummary expandIcon={expandIcon}>
                    <Stack direction="row" spacing={2} alignItems="center" sx={{width: '100%', mr: 2, mt: 1}}>
                        <TextField label="Search" fullWidth autoComplete="off"
                            value={filters.text ?? ''} 
                            onChange={(e) => updateFilters({text: e.target.value})} 
                            onClick={(e) => e.stopPropagation()}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                            }}
                        />
                        { hasAnyFilters &&
                            <IconButton onClick={(e) => { setFilters({}); e.stopPropagation(); }}>
                                <ResetIcon />
                            </IconButton>
                        }
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <TagPicker fullWidth category={categoryName} value={filters.tags ?? []} onChange={(e, v) => updateFilters({tags: v})} />
                            <MultiRepositoryPicker label="Sources" value={filters.repositoryId} onChange={(e, v) => updateFilters({repositoryId: v})} />
                        </Stack>
                        { categoryFilters }
                    </Stack>
                </AccordionDetails>
            </Accordion>
            { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
            <Stack direction="row" spacing={2} sx={{display: 'flex', width: '100%'}} alignItems="center">
                <SortButton update={updateQuery} category={category} />
                <Pagination count={numPages} page={currentPage ?? 1} onChange={(e, v) => setCurrentPage(v)} siblingCount={8} />
                { isLoading && <LinearProgress sx={{flexGrow: 1}} /> }
            </Stack>
            {
                category.renderResults ?
                    category.renderResults(results) :
                    <MultiColumnList spacing={1} itemWidth={400} items={results} render={(item, props) => <DocumentButton doc={item} {...props} />} />
            }
            { foundNothing && <Typography>No results found. Consider adding more <Link component={RouterLink} to="/sources">sources</Link>.</Typography>}
        </Stack>
    );
}

export { BrowsePage };