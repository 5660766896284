import { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, TextField, Typography, Link, Alert } from '@mui/material';
import SubmitButton from '../common/SubmitButton.js';
import CommonDialog from '../common/CommonDialog.js';
import { useStateBlock } from '../../utils.js';
import { useAuth } from '../../globals.js';

function LoginDialog({close, register, refresh}) {
    const [data, update] = useStateBlock({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const { login } = useAuth();

    const submit = useCallback(async () => {
        setIsLoading(true);
        const res = await login(data.email, data.password);
        if (res.result === 'success') {
            close();
            refresh();
        }
        else {
            if (res.reason === 'already_logged_in') {
                setError('Already logged in.');
                refresh();
            }
            else if (res.reason === 'user_not_found') {
                setError('No account is associated with that e-mail address.');
            }
            else if (res.reason === 'invalid_password') {
                setError('Invalid password.');
            }
            else {
                setError('An unknown error occurred.');
            }
        }
        setIsLoading(false);
    }, [close, refresh, data, login]);

    const canSubmit = (data.email && data.password);

    return (
        <CommonDialog close={close} title="Login">
            <DialogContent>
                <Stack spacing={2} mt={2}>
                    <Typography><Link onClick={register}>Register a new account</Link></Typography>
                    <TextField label="E-mail Address" fullWidth required
                        name="email" id="email" type="email"
                        value={data.email ?? ''}
                        onChange={(e) => update({email: e.target.value})}
                    />
                    <TextField label="Password" fullWidth required type="password"
                        value={data.password ?? ''}
                        onChange={(e) => update({password: e.target.value})}
                    />
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                </Stack>
            </DialogContent>
            <DialogActions>
                <SubmitButton onClick={submit} loading={isLoading} disabled={!canSubmit}>Login</SubmitButton>
            </DialogActions>
        </CommonDialog>
    );
}

function RegisterDialog({close, login, refresh}) {
    const [data, update] = useStateBlock({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const { register } = useAuth();

    const submit = useCallback(async () => {
        setIsLoading(true);
        const res = await register(data.email, data.password, data.accessCode);
        if (res.result === 'success') {
            refresh();
            close();
        }
        else {
            if (res.reason === 'already_logged_in') {
                setError('Already logged in.');
                refresh();
            }
            else if (res.reason === 'invalid_access_code') {
                setError('Invalid access code.');
            }
            else if (res.reason === 'user_exists') {
                setError('E-mail is already registered.');
            }
            else if (res.reason === 'password_too_weak') {
                setError('Password is too weak.');
            }
            else {
                setError('An unknown error occurred.');
            }
        }
        setIsLoading(false);
    }, [data, close, refresh, register]);

    const canSubmit = (data.email && data.password && (data.password === data.confirmPassword) && data.accessCode);
    const passwordMismatch = (data.password && data.confirmPassword && (data.password !== data.confirmPassword));

    return (
        <CommonDialog close={close} title="Register">
            <DialogContent>
                <Stack spacing={2} mt={2}>
                    <Typography><Link onClick={login}>Use an existing account</Link></Typography>
                    <TextField label="E-mail Address" fullWidth required
                        name="email" id="email" type="email"
                        value={data.email ?? ''}
                        onChange={(e) => update({email: e.target.value})}
                    />
                    <TextField label="Password" fullWidth required type="password"
                        value={data.password ?? ''}
                        onChange={(e) => update({password: e.target.value})}
                    />
                    <TextField label="Confirm Password" fullWidth required type="password"
                        value={data.confirmPassword ?? ''}
                        onChange={(e) => update({confirmPassword: e.target.value})}
                        error={passwordMismatch}
                    />
                    <TextField label="Access Code" fullWidth required
                        value={data.accessCode ?? ''}
                        onChange={(e) => update({accessCode: e.target.value})}
                    />
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                </Stack>
            </DialogContent>
            <DialogActions>
                <SubmitButton onClick={submit} loading={isLoading} disabled={!canSubmit}>Register</SubmitButton>
            </DialogActions>
        </CommonDialog>
    );
}

export default function AuthDialog({close, refresh}) {
    const [mode, setMode] = useState('login');

    const register = useCallback(() => setMode('register'), []);
    const login = useCallback(() => setMode('login'), []);

    if (mode === 'login') {
        return <LoginDialog close={close} register={register} refresh={refresh} />;
    }
    else if (mode === 'register') {
        return <RegisterDialog close={close} login={login} refresh={refresh} />;
    }
}

