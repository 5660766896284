import { useEffect, useCallback, useState, memo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Favorite as FavoriteIcon } from '@mui/icons-material';
import { useUserCache, useTitleBar } from "../../globals.js";
import { favoriteDocument } from '../../api';

function FavoriteButton({documentId}) {
    const { localUser, refreshUser } = useUserCache();
    const [isLoading, setIsLoading] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    const toggle = useCallback(async () => {
        if (!isLoading && documentId) {
            let toggle = true;
            if (localUser?.favorites?.includes(documentId)) {
                toggle = false;
            }
    
            setIsLoading(true);
            const res = await favoriteDocument(documentId, toggle);
            if (res.result === 'success') {
                console.log('refreshing local user');
                refreshUser(localUser.id);
            }
            setIsLoading(false);
        }
    }, [documentId, localUser, refreshUser, isLoading]);

    useEffect(() => {
        if (localUser?.favorites?.includes(documentId)) {
            setIsFavorite(true);
        }
        else {
            setIsFavorite(false);
        }
    }, [localUser, documentId]);

    return (
        <Tooltip title={isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}>
            <IconButton onClick={toggle}>
                { isFavorite ?
                    <FavoriteIcon sx={{color: 'red'}} /> 
                    :
                    <FavoriteIcon />
                }
            </IconButton>
        </Tooltip>
    );
}

const FavoriteDocument = memo(({documentId}) => {
    const { addWidget, removeWidget } = useTitleBar();

    useEffect(() => {
        if (documentId) {
            addWidget(<FavoriteButton key="favorite-document" documentId={documentId} />);
            return () => { removeWidget('favorite-document'); }      
        }
    }, [documentId, addWidget, removeWidget]);
});

export default FavoriteDocument;
