import { createContext, useContext, useState, useEffect, useCallback, useMemo, Fragment } from 'react';
import { getUserStatus, loginUser, logoutUser, registerUser } from '../../api.js';
import AuthDialog from './AuthDialog.js';

const AuthContext = createContext(null);

/** Returns an AuthContext which provides access to the authentication service. */
function useAuth() {
    return useContext(AuthContext);
}

function useRequireAuth() {
    return useContext(AuthContext).require;
}

/** Components which provides a valid AuthContext so children can access the authentication service. */
function AuthProvider(props) {
    const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [isReady, setIsReady] = useState(false);
    const [renderKey, setRenderKey] = useState(0);

    const openAuthDialog = useCallback(() => {
        setIsAuthDialogOpen(true);
    }, []);

    const closeAuthDialog = useCallback(() => {
        setIsAuthDialogOpen(false);
    }, []);

    const refresh = useCallback(async () => {
        let rerender = false;

        const response = await getUserStatus();
        if (response.result === 'success') {
            if (response.loggedIn) {
                if (userId !== response.userId) {
                    setUserId(response.userId);
                    setIsAuthDialogOpen(false);
                    rerender = true;
                }
            }
            else {
                if (userId) {
                    setUserId();
                    rerender = true;
                }
            }
        }
        else {
            console.log('AuthProvider: failed to get status');
        }

        if (!isReady) {
            setIsReady(true);
            rerender = true;
        }
        
        if (rerender) {
            setRenderKey(renderKey + 1);
        }
    }, [renderKey, userId, isReady]);

    const logout = useCallback(async () => {
        const res = await logoutUser();
        if (res.result === 'success') {
            setUserId();
            setRenderKey((renderKey) => renderKey + 1);
        }
        return res;
    }, []);

    const login = useCallback(async (email, password) => {
        const res = await loginUser(email, password);
        if (res.result === 'success') {
            setUserId(res.userId);
            setRenderKey((renderKey) => renderKey + 1);
        }
        return res;
    }, []);

    const register = useCallback(async (email, password, accessCode) => {
        const res = await registerUser(email, password, accessCode);
        if (res.result === 'success') {
            setUserId(res.userId);
            setRenderKey((renderKey) => renderKey + 1);
        }
        return res;
    }, []);

    const requireAuth = useCallback(() => {
        if (!userId) {
            if (isReady) {
                openAuthDialog();
            }
            return false;
        }
        return true;
    }, [userId, isReady, openAuthDialog]);

    // force a refresh of the auth status on the first time we render
    useEffect(() => { 
        refresh();
    }, []);

    const context = useMemo(() => ({
        require: requireAuth,
        login: login,
        logout: logout,
        register: register,
        refresh: refresh,
        isLoggedIn: Boolean(userId),
        userId: userId,
    }), [userId, requireAuth, refresh, login, logout, register]);

    return (
        <AuthContext.Provider value={context}>
            { isAuthDialogOpen && <AuthDialog close={closeAuthDialog} refresh={refresh} /> }
            <Fragment key={renderKey}>
                { props.children }
            </Fragment>
        </AuthContext.Provider>
    );
}

export default AuthProvider;
export { useAuth, useRequireAuth };
