import { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Typography, Switch, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, Alert,
    List, ListItem, ListItemAvatar, ListItemText, Avatar, ListItemButton, IconButton, FormControlLabel, Tooltip, Link } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Close as CloseIcon, Folder as SourceIcon } from '@mui/icons-material';
import ImagePicker from '../common/ImagePicker.js';
import SubmitButton from '../common/SubmitButton.js';
import CommonDialog from '../common/CommonDialog.js';
import { listRepositories, subscribeToRepository, unsubscribeFromRepository, createRepository, updateRepository } from '../../api.js';
import { useTitleBar, useAuth } from '../../globals.js';
import { useStateBlock } from '../../utils.js';

function ConfirmDeleteDialog({open, repoId, title, refresh, onClose}) {
    const [confirmText, setConfirmText] = useState('');
    const isDisabled = (confirmText !== 'DELETE');

    async function handleDelete() {
        onClose();
    }

    async function handleCancel() {
        onClose();
    }

    return (
        <CommonDialog close={onClose} title="Delete Repository">
            <DialogContent>
                <DialogContentText>Are you sure you want to delete {title}? This is irreversible. To proceed, type DELETE in the box below.</DialogContentText>
                <TextField autoFocus variant="standard" fullWidth value={confirmText} onChange={(e) => {setConfirmText(e.target.value.toUpperCase())}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleDelete} disabled={isDisabled}>Delete</Button>
            </DialogActions>
        </CommonDialog>
    );
}

const RepositoryListItem = styled(ListItem)({
    '&:hover': {
        backgroundImage: 'linear-gradient(150deg, rgba(144,144,159,0.05) 0%, rgba(255,255,255,0.05) 100%)'
    }
});

function Repository({repo, details, refresh}) {
    const [isLoading, setIsLoading] = useState(false);
    const { isLoggedIn } = useAuth();

    const toggle = useCallback(async () => {
        setIsLoading(true);
        if (repo.subscribed) {
            const res = await unsubscribeFromRepository(repo.id);
            if (res.result === 'success') {
                refresh();
            }
        }
        else {
            const res = await subscribeToRepository(repo.id);
            if (res.result === 'success') {
                refresh();
            }
        }
        setIsLoading(false);
    }, [repo, refresh]);

    let secondaryAction;
    if (isLoading) {
        secondaryAction = <CircularProgress size={20} />;
    }
    else {
        secondaryAction = (
            <Tooltip title="Toggle whether this repository's documents will appear while browsing and in search results">
                <Switch edge="end" checked={repo.subscribed} onChange={toggle} disabled={!isLoggedIn} />
            </Tooltip>
        );
    }

    return (
        <RepositoryListItem 
            secondaryAction={secondaryAction}
            disablePadding
        >
            <ListItemButton onClick={() => details(repo)}>
                <ListItemAvatar>
                    <Avatar src={repo.thumbnail} alt={repo.altText}>
                        <SourceIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary={repo.title} 
                    primaryTypographyProps={{variant: 'h3_noborder'}}
                    secondary={repo.description}
                    secondaryTypographyProps={{variant: 'subtitle2'}}
                />
            </ListItemButton>
        </RepositoryListItem>
    )
}

function RepositoryDetailsDialog({repo, close, refresh}) {
    const [anyChanges, setAnyChanges] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [data, update] = useStateBlock(repo, (state) => { setAnyChanges(true); return state; } );
    const isEditable = repo.role === 'owner';

    const submit = useCallback(async () => {
        setIsLoading(true);
        const params = {
            title: data.title ?? null,
            description: data.description ?? null,
            enableSharing: data.enableSharing ?? false,
            thumbnail: data.thumbnail ?? null,
            altText: data.altText ?? null,
            license: data.license ?? null
        };
        const res = await updateRepository(data.id, params);
        if (res.result === 'success') {
            close();
            refresh();
        }
        else {
            setError('Failed to update repository.');
        }
        setIsLoading(false);
    }, [data, close, refresh]);

    return (
        <CommonDialog close={close} title="Repository Details">
            <DialogContent>
                <Stack spacing={2} mt={2}>
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                    <TextField label="Title" fullWidth
                        disabled={!isEditable}
                        value={data.title ?? ''}
                        onChange={(e) => update({title: e.target.value})}
                    />
                    <TextField label="Description" fullWidth
                        disabled={!isEditable}
                        value={data.description ?? ''}
                        onChange={(e) => update({description: e.target.value})}
                    />
                    <TextField label="License" fullWidth
                        disabled={!isEditable}
                        value={data.license ?? ''}
                        onChange={(e) => update({license: e.target.value})}
                    />
                    <ImagePicker size="small" noImage data={data} update={update} disabled={!isEditable} />
                    <FormControlLabel
                        control={
                            <Switch 
                                disabled={!isEditable}
                                checked={data.enableSharing ?? false} 
                                onChange={(e) => update({enableSharing: e.target.checked})} 
                            />
                        }
                        label="Allow documents to be viewed by anyone with the URL"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
                { (anyChanges && isEditable) && 
                    <SubmitButton onClick={submit} loading={isLoading}>Save</SubmitButton>
                }
            </DialogActions>
        </CommonDialog>
    );
}

function CreateRepositoryDialog({close, refresh}) {
    const [error, setError] = useState();
    const [title, setTitle] = useState();
    const [isLoading, setIsLoading] = useState(false);

    async function handleSubmit() {
        setIsLoading(true);
        const response = await createRepository(title);
        if (response.result === 'success') {
            setError();
            refresh();
            close();
        } 
        else {
            setError(response.reason);
        }
    }

    function handleTitleChanged(event) {
        setTitle(event.target.value);
    }

    return (
        <CommonDialog close={close} title="Create Repository">
            <DialogContent>
                <DialogContentText>
                    Create a new repository to store information about your campaign or to group content together.
                </DialogContentText>
                <TextField autoFocus margin="dense" id="title" label="Title" fullWidth variant="standard" value={title} onChange={handleTitleChanged} />
                { error && <Alert severity="error" onClose={() => setError()}>An error occured. Please try again.</Alert> }
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <SubmitButton onClick={handleSubmit} loading={isLoading}>Create</SubmitButton>
            </DialogActions>
        </CommonDialog>
    )
}

function SourcesPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
    const [detailsRepo, setDetailsRepo] = useState();
    const [myRepoList, setMyRepoList] = useState();
    const [sharedRepoList, setSharedRepoList] = useState();
    const [error, setError] = useState();
    const { setTitle } = useTitleBar();
    const { isLoggedIn, require: requireAuth } = useAuth();

    const refresh = useCallback(async () => {
        setIsLoading(true);
        const response = await listRepositories();
        if (response.result === 'success') {
            setMyRepoList(response.repositories.filter((r) => r.role === 'owner'));
            setSharedRepoList(response.repositories.filter((r) => r.role !== 'owner'));
        } else {
            setError('Failed to fetch sources.');
        }
        setIsLoading(false);
    }, []);

    const details = useCallback(async (repo) => {
        setDetailsRepo(repo);
        setIsDetailsDialogOpen(true);
    }, []);

    const create = useCallback(() => {
        if (requireAuth()) {
            setIsCreateDialogOpen(true);
        }
    }, [requireAuth]);

    useEffect(() => {
        setTitle('Manage Sources');
    }, [setTitle]);

    useEffect(() => {
        if (!myRepoList && !isLoading) {
            refresh();
        }
    }, [myRepoList, isLoading, refresh, isLoggedIn]);

    return (
        <Stack sx={{width: '100%', mt: 2}} spacing={2} >
            { isCreateDialogOpen && 
                <CreateRepositoryDialog close={() => {setIsCreateDialogOpen(false)}} refresh={refresh} /> 
            }
            { isDetailsDialogOpen && 
                <RepositoryDetailsDialog repo={detailsRepo} close={() => {setIsDetailsDialogOpen(false)}} refresh={refresh} /> 
            }
            { error &&  <Alert severity="error" onClose={() => setError()}>{error}</Alert>}
            <Typography variant="h2">My Sources</Typography>
            <Box>
                <Button variant="outlined" onClick={create}>Create Repository</Button>
            </Box>
            <List>
                { myRepoList && myRepoList.map((repo) =>
                    <Repository key={repo.id} repo={repo} details={details} refresh={refresh} />
                )}
            </List>
            { ((sharedRepoList?.length ?? 0) > 0) &&
                <>
                    <Typography variant="h2">Other Sources</Typography>
                    <List>
                        { sharedRepoList.map((repo) =>
                            <Repository key={repo.id} repo={repo} details={details} refresh={refresh} />
                        )}
                    </List>
                </>
            }
        </Stack>
    );
}

export { SourcesPage };
