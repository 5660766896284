import { Stack } from '@mui/material';
import { MultiComboBox } from '../common/MultiComboBox.js';
import { SelectBox } from '../common/SelectBox.js'
import { creatureTypeSuggestions, challengeRatingSuggestions } from '../../suggestions.js';
import { challengeRatingToString } from '../../dnd5e.js';

function MonsterFilters({data, update}) {
    
    return (
        <Stack direction="row" spacing={1}>
            <MultiComboBox label="Type" fullWidth
                value={data?.['data.type'] ?? []}
                options={creatureTypeSuggestions}
                onChange={(e, v) => update({'data.type': v})} />
            <SelectBox label="Min CR" fullWidth
                options={challengeRatingSuggestions}
                getOptionLabel={challengeRatingToString}
                value={data?.['challengeRating:min'] ?? null}
                onChange={(e, v) => update({'challengeRating:min': v})}
            />
            <SelectBox label="Max CR" fullWidth
                options={challengeRatingSuggestions}
                getOptionLabel={challengeRatingToString}
                value={data?.['challengeRating:max'] ?? null}
                onChange={(e, v) => update({'challengeRating:max': v})}
            />
        </Stack>
    );
}

export { MonsterFilters }
