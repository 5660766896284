import { useCallback } from 'react';
import { MultiComboBox } from './MultiComboBox.js';
import { getTagsInUse } from '../../api.js';

export default function TagPicker({value, onChange, category, suggestions, label, ...props}) {
    const fetchOptions = useCallback(async () => {
        const res = await getTagsInUse(category);
        if (res.result === 'success') {
            return [...new Set([...res.tags, ...(suggestions ?? [])])].sort();
        }
        else {
            return [];
        }
    }, [category, suggestions]);

    const handleChange = useCallback((e, v) => {
        if (onChange) {
            onChange(e, cleanTags(v))
        }
    }, [onChange]);

    return (
        <MultiComboBox label={label ?? "Tags"} {...props}
            fetchOptions={fetchOptions}
            options={suggestions}
            value={value ?? []}
            onChange={handleChange}
        />
    );
} 

function cleanTags(tags) {
    return [...new Set(tags)]
        .filter((tag) => Boolean(tag))
        .map((tag) => tag.toLowerCase());
}

export { TagPicker, cleanTags };
