import { createTheme, responsiveFontSizes } from "@mui/material";
import { deepPurple, purple, orange, grey } from "@mui/material/colors";

const theme = createTheme({ 
    palette: { 
        mode: 'dark',
        primary: {
            main: deepPurple[200]
        },
        secondary: {
            main: orange[400]
        }
    },
    typography: {
        fontFamily: [
            'Noto Sans'
        ].join(','),
        h1: {
            fontWeight: 300,
            fontSize: 28
        },
        h2: {
            fontSize: 42,
            fontWeight: 300,
            color: 'white',
            breakAfter: 'avoid'
        },
        h3: {
            fontSize: 20,
            fontWeight: 700,
            color: orange[400],
            borderStyle: 'solid',
            borderWidth: '0 0 2px 0',
            borderImage: 'linear-gradient(90deg, rgba(255,167,38,1) 0%, rgba(255,167,38,1) 66%, rgba(255,167,38,0) 100%) 1;',
            breakAfter: 'avoid',
            '&:not(:first-child) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */': {
                marginBlockStart: '1em'
            },
            '&:not(:last-child)': {
                marginBlockEnd: '0.2em'
            }
        },
        h3_noborder: {
            fontSize: 20,
            fontWeight: 700,
            color: orange[400],
        },
        h4: {
            fontSize: 18,
            fontWeight: 700,
            color: deepPurple[200],
            breakAfter: 'avoid',    
            '&:not(:first-child) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */': {
                marginBlockStart: '0.5em'
            },
            marginBlockEnd: 0,     
        },
        h5: {
            fontSize: 16,
            fontWeight: 700,
            color: deepPurple[200],
            breakAfter: 'avoid',
        },
        h6: {
            fontSize: 16,
            lineHeight: 1.8,
            fontWeight: 600,
            fontStyle: 'italic',
            color: deepPurple[200],
            breakAfter: 'avoid'
        },
        h7: {
            fontSize: 14,
            lineHeight: 1.5,
            fontWeight: 400,
            color: deepPurple[200],
            breakAfter: 'avoid'               
        },
        body1: {
            color: grey[300],
            '& strong': {
                color: deepPurple[100],
                fontWeight: 600
            }
        },
        body2: {
            color: grey[300]
        },
        subtitle1: {
            fontSize: 20,
            fontStyle: 'italic',
            fontWeight: 500,
            color: grey[200]
        },
        subtitle2: {
            fontSize: 16,
            fontStyle: 'italic',
            color: grey[300]
        },
        label1: {
            fontWeight: 600,
            color: deepPurple[100]
        },
        label2: {
            fontWeight: 600,
            color: deepPurple[100],
            fontStyle: 'italic',
            '&:after': {
                content: '"."'
            }
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: "#6b6b6b #2b2b2b",
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                      backgroundColor: "#2b2b2b",
                      width: '12px'
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                      borderRadius: 8,
                      backgroundColor: "#6b6b6b",
                      minHeight: 24,
                      border: "3px solid #2b2b2b",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                      backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                      backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#959595",
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                      backgroundColor: "#2b2b2b",
                    },
                    minHeight: '100vh',
                    background: 'rgb(2,0,36)',
                    backgroundImage: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(10,10,28,1) 9%, rgba(6,6,52,1) 58%, rgba(23,6,47,1) 100%)',
                    color: grey[300],
                },
                blockquote: {
                    margin: 0,
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                    '&:not(:last-child)': {
                        marginBlockEnd: '0.5em'
                    },
                    display: 'block',
                    paddingLeft: '0.5em',
                    paddingTop: '0.25em',
                    paddingRight: '0.5em',
                    paddingBottom: '0.25em',
                    border: '2px solid rgba(255,255,255,0.2)',
                    backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.15) 100%)',
                    borderRadius: '8px',
                    '& .MuiTypography-body1': {
                        color: orange[100]
                    },
                    '& .MuiTypography-body2': {
                        color: orange[100]
                    }
                },
                p: {
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                    '&:not(:last-child)': {
                        marginBlockEnd: '1em'
                    }
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h3_noborder: 'h3'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                elevation0: {
                    backgroundColor: 'transparent'
                    //backgroundColor: 'white'
                },
                elevation1: {
                    backgroundColor: 'rgba(49,52,61,0.1)',
                    //backgroundColor: 'purple',
                    backgroundImage: 'none'
                },
                elevation2: {
                    backgroundColor: 'rgba(49,52,61,0.25)',
                    //backgroundColor: 'blue',
                    backgroundImage: 'none',
                },
                elevation3: {
                    backgroundColor: 'rgba(36,36,69,0.66)',
                    backgroundImage: 'none',
                    //backgroundColor: 'green'
                },
                elevation4: {
                    backgroundColor: 'rgba(44,44,82,1.0)',
                    backgroundImage: 'none',
                    //backgroundColor: 'pink'
                },
                elevation5: {
                    backgroundColor: 'rgba(53,53,94,1.0)',
                    backgroundImage: 'none',
                    //backgroundColor: 'red'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'rgb(19,15,79)',
                    backgroundImage: 'linear-gradient(90deg, rgba(25,25,68,1) 0%, rgba(31,10,74,1) 58%, rgba(24,12,69,1) 100%)',
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paperAnchorLeft: {
                    background: 'rgb(19,15,79)',
                    backgroundImage: 'linear-gradient(90deg, rgba(11,39,57,1) 0%, rgba(25,25,68,1) 100%)',
                    boxShadow: 'none',
                    border: 'none'
                },
                paperAnchorRight: {
                    backgroundColor: 'rgba(44,44,82,1.0)',
                    backgroundImage: 'none',
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    '&:not(:last-child)': {
                        marginBlockEnd: '1.5em'
                    }                   
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                sizeSmall: {
                    padding: '0.2em'
                },
                sizeMedium: {
                    padding: '0.5em'
                },
                head: {
                    fontWeight: 600,
                    color: deepPurple[100]
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: orange[100],
                }
            }
        },
        MuiDialog: {
            defaultProps: {
                PaperProps: {
                    sx: {
                        backgroundColor: 'rgb(36,36,69)',
                        backgroundImage: 'linear-gradient(150deg, rgba(36,36,69,1) 0%, rgba(39,35,71,1) 100%)'                     
                    },
                    elevation: 5
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    backgroundColor: 'rgb(36,36,69)',
                    backgroundImage: 'linear-gradient(150deg, rgba(39,39,72,1) 0%, rgba(42,42,75,1) 100%)'           
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                    fontStyle: 'normal',
                    lineHeight: 1.5,
                    fontWeight: 700,
                    color: orange[400], 
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer'
                }
            }
        }
    },
});

export default theme;
