import { SvgIcon } from '@mui/material';
import { ReactComponent as CaveSvg } from './icons/cave-entrance.svg';
import { ReactComponent as VillageSvg } from './icons/village.svg';
import { ReactComponent as TempleSvg } from './icons/temple.svg';
import { ReactComponent as MonsterSvg } from './icons/monster.svg';
import { ReactComponent as RuinsSvg } from './icons/ruins.svg';
import { ReactComponent as BridgeSvg } from './icons/bridge.svg';
import { ReactComponent as ObeliskSvg } from './icons/obelisk.svg';
import { ReactComponent as TrapSvg } from './icons/trap.svg';
import { ReactComponent as TreasureSvg } from './icons/treasure-chest.svg';
import { ReactComponent as BattleSvg } from './icons/battle.svg';
import { ReactComponent as MountainSvg } from './icons/mountain.svg';
import { ReactComponent as AnchorSvg } from './icons/anchor.svg';
import { ReactComponent as IslandSvg } from './icons/island.svg';
import { ReactComponent as ShipwreckSvg } from './icons/shipwreck.svg';
import { ReactComponent as CastleSvg } from './icons/castle.svg';
import { ReactComponent as PortalSvg } from './icons/magic-gate.svg';
import { ReactComponent as TentSvg } from './icons/tent.svg';
import { ReactComponent as CitySvg } from './icons/city.svg';
import { ReactComponent as ForestSvg } from './icons/forest.svg';
import { ReactComponent as TavernSvg } from './icons/tavern.svg';
import { ReactComponent as HouseSvg } from './icons/house.svg';
import { ReactComponent as ShopSvg } from './icons/shop.svg';
import { ReactComponent as DragonHeadSvg } from './icons/dragon-head.svg';
import { ReactComponent as DragonHeadOutlinedSvg } from './icons/dragon-head-outline.svg';
import { ReactComponent as ClawSvg } from './icons/claw.svg';
import { ReactComponent as CrownSvg } from './icons/crown.svg';
import { ReactComponent as RiverSvg } from './icons/river.svg';
import { ReactComponent as RoadSvg } from './icons/road.svg';
import { ReactComponent as TowerSvg } from './icons/tower.svg';

function makeIcon(svg) {
    return (props) => <SvgIcon component={svg} inheritViewBox {...props} />;
}

export const CaveIcon = makeIcon(CaveSvg);
export const VillageIcon = makeIcon(VillageSvg);
export const TempleIcon = makeIcon(TempleSvg);
export const MonsterIcon = makeIcon(MonsterSvg);
export const RuinsIcon = makeIcon(RuinsSvg);
export const BridgeIcon = makeIcon(BridgeSvg);
export const ObeliskIcon = makeIcon(ObeliskSvg);
export const TrapIcon = makeIcon(TrapSvg);
export const TreasureIcon = makeIcon(TreasureSvg);
export const BattleIcon = makeIcon(BattleSvg);
export const MountainIcon = makeIcon(MountainSvg);
export const AnchorIcon = makeIcon(AnchorSvg);
export const IslandIcon = makeIcon(IslandSvg);
export const ShipwreckIcon = makeIcon(ShipwreckSvg);
export const CastleIcon = makeIcon(CastleSvg);
export const PortalIcon = makeIcon(PortalSvg);
export const TentIcon = makeIcon(TentSvg);
export const CityIcon = makeIcon(CitySvg);
export const ForestIcon = makeIcon(ForestSvg);
export const TavernIcon = makeIcon(TavernSvg);
export const HouseIcon = makeIcon(HouseSvg);
export const ShopIcon =  makeIcon(ShopSvg);
export const CrownIcon = makeIcon(CrownSvg);
export const RiverIcon = makeIcon(RiverSvg);
export const RoadIcon = makeIcon(RoadSvg);
export const TowerIcon = makeIcon(TowerSvg);

export const DragonHeadIcon = makeIcon(DragonHeadSvg);
export const DragonHeadOutlinedIcon = makeIcon(DragonHeadOutlinedSvg);
export const ClawIcon = makeIcon(ClawSvg);
