const Image = {
    name: 'Image',
    plural: 'Images',

    init: () => Image.deserialize({}),
    
    deserialize: (document) => ({
        url: document.description ?? null,
        description: document.altText ?? null,
        thumbnail: document.thumbnail ?? null,
        tags: document.tags ?? null,
        size: document.data?.size ?? 0,
        contentType: document.data?.contentType ?? null
    }),

    serialize: (data) => ({
        altText: data.description,
        tags: data.tags,
    }),

    validate: (data) => {
        let errors = [];
        if (!data.description) {
            errors.push('A description is required.');
        }
        return errors;
    }
}

export default Image;


