import { Typography } from '@mui/material';
import { createContext, useContext, useState, useCallback } from 'react';

const ErrorContext = createContext(null);

/** Returns a function for submitting an application-level fatal error. */
function useError() {
    return useContext(ErrorContext);
}

/** Components which provides a valid ErrorContext so children can bubble up application-level errors. */
function ErrorHandler(props) {
    const [errorMessage, setErrorMessage] = useState();
    const hasError = (errorMessage ? true : false);

    let report = useCallback((message) => {
        console.error(message);
        console.trace();
        setErrorMessage(message);
    }, [setErrorMessage]);

    if (hasError) {
        return <>
                <Typography variant="h1">An error has occured</Typography>
                <Typography variant="h2">Please refresh the page and try again.</Typography>
                <Typography variant="h3">Error message:</Typography>
                <Typography><pre>{errorMessage}</pre></Typography>
        </>;
    }
    else {
        return (
            <ErrorContext.Provider value={report}>
                {props.children}
            </ErrorContext.Provider>
        );
    }
}

export default ErrorHandler;
export { useError };
