import { Stack, FormControlLabel, Switch } from '@mui/material';
import { MultiComboBox } from '../common/MultiComboBox.js';
import { itemTypeSuggestions } from '../../suggestions.js';
import { itemRarityList, getItemRarityLabel } from '../../dnd5e.js';

function ItemFilters({data, update}) {
    
    return (
        <Stack direction="row" spacing={1}>
            <MultiComboBox label="Type" fullWidth
                value={data?.['data.type'] ?? []}
                options={itemTypeSuggestions}
                onChange={(e, v) => update({'data.type': v})}
            />
            <MultiComboBox label="Rarity" fullWidth
                value={data?.['rarity'] ?? []}
                options={itemRarityList}
                getOptionLabel={getItemRarityLabel}
                onChange={(e, v) => update({'rarity': v})} />
            <FormControlLabel 
                sx={{whiteSpace: 'nowrap'}}
                label="Requires Attunement"
                control={
                    <Switch checked={data?.['attunement'] ?? false}
                        onChange={(e) => update({'attunement': e.target.checked ? true : undefined})}
                    />
                }
            />
        </Stack>
    );
}

export default ItemFilters;

