import { getFeatureTypeLabel } from '../components/doc/MonsterFeatureEditor.js';

function getShortDescription(data) {
    return getFeatureTypeLabel(data.type);
}

function isAction(data) {
    return data.type === 'action' || data.type === 'bonusAction' || data.type === 'reaction'
};

function isLegendaryAction(data) {
    return data.type === 'legendaryAction';
}

const MonsterFeature = {
    name: 'Monster Feature',
    plural: 'Monster Features',

    init: () => MonsterFeature.deserialize({}),
    
    deserialize: (document) => ({
        name: document.title ?? null,
        tags: document.tags ?? [],
        type: document.data?.featureType ?? null,
        cr: document.data?.cr ?? null,
        description: document.data?.text ?? null,
        limit: document.data?.limit ?? null,
        damage: document.data?.damage ?? null,
        legendaryActions: document.data?.legendaryActions ?? null
    }),

    serialize: (data) => ({
        title: data.name,
        description: getShortDescription(data),
        tags: data.tags,
        data: {
            cr: data.cr,
            text: data.description,
            limit: isAction(data) ? data.limit : null,
            featureType: data.type,
            damage: isAction(data) ? data.damage : null,
            legendaryActions: isLegendaryAction(data) ? data.legendaryActions : null,
        }
    }),

    validate: (data) => {
        let errors = [];
        if (!data.name) {
            errors.push('Name is required.');
        }
        if (!data.type) {
            errors.push('Type is required.');
        }
        return errors;
    },
}

export default MonsterFeature;


