import { useState, useCallback, useEffect, forwardRef, useMemo, memo, useImperativeHandle } from 'react';
import { usePopupState, bindPopper, bindHover } from 'material-ui-popup-state/hooks';
import { Editor, createEditor, Node, Transforms, Text, Descendant } from 'slate';
import { Slate, Editable, withReact, useFocused } from 'slate-react';
import { withHistory } from 'slate-history';
import { Box, ButtonGroup, Button, Paper, Tooltip, DialogContent, DialogActions, TextField, Alert, Stack, Popper, Typography, IconButton } from '@mui/material';
import { orange } from '@mui/material/colors';
import { InsertLink as InsertLinkIcon, InsertPhoto as InsertPhotoIcon, Assistant as GenerateTextIcon, KeyboardArrowDown as ExpandMoreIcon, KeyboardArrowUp as ExpandLessIcon, ExpandLess } from '@mui/icons-material';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
import DocumentPicker from './DocumentPicker.js';
import CommonDialog from './CommonDialog.js';
import SubmitButton from './SubmitButton.js';
import PreviewLink from './PreviewLink.js';
import Image from './Image.js';
import { generateText } from '../../api.js';
import { useImageDropper } from '../../globals.js'; 

const markdownParser = unified().use(remarkParse).use(remarkGfm);

function InsertLinkDialog({open, close, insertLink, linkText: initialLinkText}) {
    const [linkText, setLinkText] = useState(initialLinkText ? initialLinkText : '');
    const [target, setTarget] = useState(null);

    const canSubmit = linkText && target;

    const submit = useCallback(() => {
        let url;
        if (typeof target === 'string') {
            url = target;
        }
        else {
            url = `/document/${target.id}`;
        }
        insertLink(linkText, url);
        close();
    }, [insertLink, close, linkText, target]);

    return (
        <CommonDialog close={close} title="Insert Link">
            <DialogContent>
                <Stack spacing={2} sx={{mt: 1}}>
                    <TextField label="Link Text" fullWidth autoComplete="off"
                        value={linkText} 
                        onChange={(e) => setLinkText(e.target.value)}
                    />
                    <DocumentPicker label="Link Target" fullWidth freeSolo autoFocus
                        value={target}
                        onChange={(e, v) => setTarget(v)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <Button onClick={submit} disabled={!canSubmit}>Insert Link</Button>
            </DialogActions>
        </CommonDialog>
    );
}

const generationContext = "You are a helpful AI assisting a Dungeon Master preparing a tabletop role-playing campaign. You may use markdown to format your responses. Limit your responses to one or two paragraphs.";

function GenerateTextDialog({open, onClose, getPrompt, insertText}) {
    const [prompt, setPrompt] = useState(getPrompt ? getPrompt() : '');
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [error, setError] = useState();

    const handleCancelClick = useCallback(() => {
        if (result) {
            setResult();
        }
        else {
            onClose();
        }
    }, [onClose, result]);

    const handleAcceptClick = useCallback(() => {
        insertText(result);
        onClose();
    }, [result, insertText, onClose]);

    const handleGenerateClick = useCallback(async () => {
        setIsLoading(true);
        setResult();
        const res = await generateText(generationContext, prompt);
        if (res.result === 'success') {
            setError();
            setResult(res.message);
        }
        else {
            setError('An error occured while querying the service. Please try again.');
            setResult();
        }
        setIsLoading(false);
    }, [prompt]);

    return (
        <CommonDialog close={onClose} title="Generate Text">
            <DialogContent>
                <Stack spacing={2} marginTop={1}>
                    { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                    <TextField label="Prompt" multiline fullWidth rows={4} 
                        value={prompt} 
                        onChange={(e) => setPrompt(e.target.value)} 
                        disabled={isLoading}    
                    />
                    { result && 
                        <TextField label="Result" multiline rows={8} fullWidth 
                            value={result} 
                            onChange={(e) => setResult(e.target.value)} 
                        /> 
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick}>Cancel</Button>
                { result && <Button onClick={handleGenerateClick} disabled={isLoading}>Regenerate</Button> }
                { result && <Button onClick={handleAcceptClick}>Accept</Button> }
                { !result && <SubmitButton onClick={handleGenerateClick} loading={isLoading}>Generate</SubmitButton> }
            </DialogActions>
        </CommonDialog>
    );
}

function nodesToString(nodes) {
    return nodes.map(n => Node.string(n)).join('\n');
}

function Leaf({attributes, children, leaf}) {
    if (leaf.render) {
        return leaf.render({...attributes, children: children});
    }
    return (
        <span {...attributes}>{children}</span>
    );
}

const expandMoreIcon = <ExpandMoreIcon fontSize="small" />;
const expandLessIcon = <ExpandLessIcon fontSize="small" />;

function CollapsableImage({children, src, alt, ...props}) {
    const [isExpanded, setExpanded] = useState(true);

    const toggleExpanded = useCallback(() => {
        setExpanded((expanded) => !expanded);
    }, []);

    return (
        <span  {...props}>
            <span style={{display: 'block', backgroundColor: 'rgba(255,255,255,0.1)'}}>
                {children}
                <IconButton onClick={toggleExpanded} sx={{userSelect: 'none'}} contentEditable={false}>
                    { isExpanded ? expandLessIcon : expandMoreIcon }
                </IconButton>
            </span>
            { isExpanded &&
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}} contentEditable={false}>
                    <Image src={src} alt={alt} sx={{display: 'block', maxWidth: '95%', objectFit: 'cover'}} />
                </Box>
            }
        </span> 
    )
}

function getDecorations(node, path, decorations) {
    let render;

    if (node.type === 'heading') {
        render = ({children, ...props}) => <Typography component="span" variant={`h${node.depth  + 1}`} {...props} sx={{lineHeight: '2em'}}>{children}</Typography>;
    }
    else if (node.type === 'link') {
        render = ({children, ...props}) => <PreviewLink href={node.url} {...props} sx={{backgroundColor: 'rgba(255,255,255,0.1)'}}>{children}</PreviewLink>;
    }
    else if (node.type === 'emphasis') {
        render = ({children, ...props}) => <span><em {...props}>{children}</em></span>;
    }
    else if (node.type === 'strong') {
        render = ({children, ...props}) => <span><strong {...props}>{children}</strong></span>;
    }
    else if (node.type === 'image') {
        render = ({children, ...props}) => <CollapsableImage children={children} src={node.url} alt={node.alt} {...props} />;
    }
    else if (node.type === 'blockquote') {
        render = ({children, ...props}) => <span><em {...props}>{children}</em></span>
    }

    if (render) {
        decorations.push({render: render, anchor: { path, offset: node.position.start.offset }, focus: { path, offset: node.position.end.offset }});
    }

    if (node.children) {
        for (const child of node.children) {
            getDecorations(child, path, decorations);
        }
    }
}

const MarkdownToolbar = memo(({insertLink, insertImage, generateText}) => {
    const isFocused = useFocused();
    const [isHovered, setHovered] = useState(false);

    return (<>
        { (isFocused || isHovered) &&
            <Box component={Paper} elevation={4} 
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
                sx={{ position: 'sticky', top: 0, zIndex: 1050 }}
            >
                <ButtonGroup variant="filled">
                    <Tooltip title="Insert Link">
                        <Button onClick={insertLink}>
                            <InsertLinkIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Insert Image">
                        <Button onClick={insertImage}>
                            <InsertPhotoIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="AI Text Generation">
                        <Button onClick={generateText}>
                            <GenerateTextIcon />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </Box>
        }
    </>);
});

const MarkdownEditorInner = memo(forwardRef(({initialValue, onChange, getGenerationPrompt, placeholder, autoFocus, editableId}, ref) => {
    const [editor] = useState(() => withReact(withHistory(createEditor())));
    const [isInsertLinkDialogOpen, setInsertLinkDialogOpen] = useState(false);
    const [linkText, setLinkText] = useState('');
    const [isGenerateTextDialogOpen, setGenerateTextDialogOpen] = useState(false);
    const [toolbarHasFocus, setToolbarFocus] = useState(false);
    const imageDropper = useImageDropper();

    useImperativeHandle(ref, () => {});

    const renderLeaf = useCallback(props => <Leaf {...props} />, []);

    const decorate = useCallback(([node, path]) => {
        const ranges = [];

        const root = markdownParser.parse(node.text);
        getDecorations(root, path, ranges);

        return ranges;
    }, []);

    const insertText = useCallback((text) => {
        if (!editor.selection) {
            Transforms.select(editor, Editor.end(editor, []));
        }
        editor.insertText(text);
    }, [editor]);

    const insertImage = useCallback((url, altText) => {
        insertText(`![${altText}](${url})`);
    }, [insertText]);

    const insertLink = useCallback((text, url) => {
        insertText(`[${text}](${url})`);
    }, [insertText]);    

    const handleChange = useCallback((v) => {
        if (v !== initialValue) {
            if (onChange) {
                onChange(nodesToString(v));
            }
        }
    }, [onChange, initialValue]);

    const handleCloseInsertLinkDialog = useCallback(() => {
        setInsertLinkDialogOpen(false);
    }, []);

    const handleInsertLinkButtonClick = useCallback(() => {
        setInsertLinkDialogOpen(true);

        if (editor.selection) {
            const selectedText = Editor.string(editor, editor.selection);
            setLinkText(selectedText);
        }
        else {
            setLinkText('');
        }
    }, [editor]);

    const handleInsertImageButtonClick = useCallback(async () => {
        const res = await imageDropper();
        if (res) {
            insertImage(res.url, res.altText);
        }
    }, [imageDropper, insertImage]);

    const handlePaste = useCallback(async (e) => {
        for (let index in e.clipboardData.items) {
            const item = e.clipboardData.items[index];
            if (item.kind === 'file' &&
                item.type.startsWith('image/')) {
                const res = await imageDropper(item.getAsFile());
                if (res) {
                    insertImage(res.url, res.altText);
                }
            }
        }
    }, []);

    const handleGenerateTextButtonClick = useCallback(() => {
        setGenerateTextDialogOpen(true);
    }, []);

    const handleCloseGenerateTextDialog = useCallback(() => {
        setGenerateTextDialogOpen(false);
    }, []);

    useEffect(() => {
        if (autoFocus) {
            Transforms.select(editor, { offset: 0, path: [0, 0] });
        }
    }, [])

    return <>
        { isInsertLinkDialogOpen && <InsertLinkDialog open={isInsertLinkDialogOpen} close={handleCloseInsertLinkDialog} insertLink={insertLink} linkText={linkText} /> }
        { isGenerateTextDialogOpen && 
            <GenerateTextDialog 
                open={isGenerateTextDialogOpen} 
                onClose={handleCloseGenerateTextDialog} 
                insertText={insertText} 
                getPrompt={getGenerationPrompt} />
        }
        <Slate editor={editor} onChange={handleChange} initialValue={initialValue}>
            <MarkdownToolbar insertLink={handleInsertLinkButtonClick}
                insertImage={handleInsertImageButtonClick}
                generateText={handleGenerateTextButtonClick}
            />
            <Editable id={editableId} onPaste={handlePaste} placeholder={placeholder}
                renderLeaf={renderLeaf}
                decorate={decorate}
                style={{
                    border: 0,
                    padding: '0.25em', 
                    fontFamily: 'Noto Sans Mono,ui-monospace,SFMono-Regular,"SF Mono",Menlo,Consolas,"Liberation Mono",monospace', 
                    color: orange[100],
                    overflowY: 'auto',
                    height: '100%',
                    minHeight: '100%',
                    flexGrow: 1,
                    margin: 4,
                }} 
            />
        </Slate>
    </>;
}));

const MarkdownEditor = forwardRef(({value, onChange, onChangeOuter, ...props}, ref) => {
    const initialNodes = useMemo(() => 
        [
            {
                type: 'paragraph', 
                children: [
                    {text: (typeof value === 'string' ? value : '')}
                ]
            }
        ], 
    []);

    // the reason we do this is so that we don't cause an unnecessary rerender whenever 'initialValue' changes (as it will on every keystroke),
    // since it's not actually used as the Slate editor is uncontrolled.
    //
    // if you run into performance issues, ensure that your onChange function is properly memoized
    //
    // onChangeAlt is only there to workaround an issue that arises from being wrapped within a MUI InputBase; it allows
    // us to bypass the input base's onChange handler.
    return <MarkdownEditorInner initialValue={initialNodes} onChange={onChangeOuter ?? onChange} {...props} ref={ref} />;
});


export { MarkdownEditor };
