import { useCallback } from 'react';
import { Stack, Box, TextField, Skeleton } from '@mui/material';
import { useImageDropper } from '../../globals.js';

function ImagePicker({data, update, noThumbnail, noImage, size, disabled, ...props}) {
    const openImageDropper = useImageDropper();

    const thumbnailClicked = useCallback(async () => {
        if (!disabled) {
            const res = await openImageDropper();
            if (res) {
                update({image: res.url, thumbnail: res.thumbnail, altText: res.altText})
            }
        }
    }, [openImageDropper, update, disabled]);

    const previewUrl = (data?.thumbnail ? data?.thumbnail : data?.url) ?? '';
    const isPreviewUrlValid = previewUrl && (previewUrl.length > 0);
    const imageField = props.fields?.image ?? 'image';
    const thumbnailField = props.fields?.thumbnail ?? 'thumbnail';
    const altTextField = props.fields?.altText ?? 'altText';

    return (
        <>
            <Stack direction="row" spacing={1} marginTop={1} sx={{ width: '100%', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', width: 151, minHeight: 100, alignItems: 'center' }} onClick={thumbnailClicked} >
                    { isPreviewUrlValid ? 
                        <img src={previewUrl} alt={data.altText} style={{ width: 128, height: 128 }} /> 
                        : 
                        <Skeleton variant="circular" width={128} height={128} animation={false} /> 
                    }
                </Box>
                <Stack spacing={1} sx={{ width: '100%' }}>
                    { (!noImage) && 
                        <TextField label="Image" size={size} autoComplete="off" disabled={disabled}
                            value={data?.image ?? ''} 
                            onChange={(e) => update && update({[imageField]: e.target.value})} 
                        /> 
                    }
                    { (!noThumbnail) && 
                        <TextField label="Thumbnail" size={size} autoComplete="off" disabled={disabled}
                            value={data?.thumbnail ?? ''} 
                            onChange={(e) => update && update({[thumbnailField]: e.target.value})}
                        /> 
                    }
                    <TextField label="Alt Text" size={size} autoComplete="off" disabled={disabled}
                        value={data?.altText ?? ''} 
                        onChange={(e) => update && update({[altTextField]: e.target.value})} />     
                </Stack>                    
            </Stack>
        </>
    );
}

export default ImagePicker;
