import { useState } from 'react';
import { Stack, TextField, Box, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Paper} from '@mui/material';
import { ExpandLess as ExpandIcon, Article as PropertiesIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { MarkdownEditor } from '../common/MarkdownEditor.js';
import { TagPicker } from '../common/TagPicker.js';
import { NumberField } from '../common/NumberField.js';
import PropertiesPanel from '../common/PropertiesPanel.js';

const propertiesIcon = <PropertiesIcon />;

function SessionEditor({data, update, documentId}) {
    const [tab, setTab] = useState(0);

    return (
        <>
            <Stack spacing={0} sx={{ display: 'flex', flexGrow: 1 }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                    <Tab label="Session" />
                    <Tab label="Recap" />
                    <Tab label="Planning" />
                    <Tab label="Notes" />
                </Tabs>
                <Box sx={{width: '100%', pl: 1, pr: 1, mt: 1}}>
                    { tab === 0 &&
                        <Stack spacing={2} mt={2} width="100%">
                            <TextField label="Session Name" autoFocus fullWidth value={data.name ?? ''} onChange={(e) => update({name: e.target.value})} />
                            <NumberField label="Session Number" fullWidth value={data.number} onChange={(e, v) => update({number: v})} />
                            <DatePicker label="Date" fullWidth value={data.date} onChange={(v) => update({date: v})} />
                            <TagPicker label="Tags" value={data.tags} onChange={(e, v) => update({tags: v})} />    
                        </Stack>
                    }
                    { tab === 1 && 
                        <MarkdownEditor value={data.recap} onChange={(v) => update({recap: v})} placeholder="Write some text here!" autoFocus />
                    }
                    { tab === 2 &&
                        <MarkdownEditor value={data.planning} onChange={(v) => update({planning: v})} placeholder="Write some text here!" autoFocus />
                    }
                    { tab === 3 &&
                        <MarkdownEditor value={data.notes} onChange={(v) => update({notes: v})} placeholder="Write some text here!" autoFocus />            
                    }
                </Box>
            </Stack>
        </>
    )
}

export default SessionEditor;

