import { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Paper, Typography, Stack, Divider, LinearProgress, Avatar, Alert, Card, CardContent, CardHeader, CardActionArea } from '@mui/material';
import { Image } from '../common/Image.js';
import { MarkdownViewer } from '../common/MarkdownViewer.js';
import Section from '../common/Section.js';
import ColumnLayout from '../common/ColumnLayout.js';
import { MonsterStatBlock } from './MonsterViewer.js';
import { getDocument } from '../../api.js';
import { formatSentence } from '../../text.js';
import { getDocumentType } from '../../documents.js';
import { capitalizeFirstLetter } from '../../text.js';

function RelationshipViewer({data}) {
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [thumbnailAltText, setThumbnailAltText] = useState();
    const [error, setError] = useState();

    const fetchDocument = useCallback(async (id) => {
        const res = await getDocument(id);
        if (res.result === 'success') {
            setName(res.title);
            setThumbnail(res.thumbnail);
            setThumbnailAltText(res.thumbnailAltText);
            setError();
        }
        else {
            setError('Failed to retrieve character.');
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (data.characterId) {
            fetchDocument(data.characterId);
        }
        else {
            setName(data.name);
            setIsLoading(false);
        }
    }, []);

    const header = (<CardHeader 
        title={
            <Stack direction="row" spacing={2}>
                { thumbnail && <Avatar src={thumbnail} alt={thumbnailAltText} /> }
                <Typography variant="h4">{name}</Typography>
            </Stack>
        }
    />);

    return (
        <Card width="100%">
            { isLoading && <LinearProgress />}
            { !isLoading && 
                <>
                    { data.characterId ?
                        <CardActionArea component={data.characterId && RouterLink} to={`/document/${data.characterId}`}>
                            {header}
                        </CardActionArea> :
                        header
                    }
                    <CardContent>
                        { error && <Alert severity="error" onClose={() => setError()}>{error}</Alert> }
                        <Typography>{data.description}</Typography>
                    </CardContent>
                </>
            }
        </Card>
    );
}

function CharacterViewer({data}) {
    const [statBlock, setStatBlock] = useState();
    const [error, setError] = useState();
    const { vitals, appearance, personality, relationships, biography, notes } = data;

    const fetchStatBlock = useCallback(async (id) => {
        const res = await getDocument(id);
        if (res.result === 'success') {
            setStatBlock(getDocumentType(res.type).deserialize(res));
            setError();
        }
        else {
            setError('Failed to retrieve stat block.');
        }
    }, []);

    const hasStatBlock = Boolean(vitals?.statBlock?.id);
    const hasPersonalityData = (personality.text || personality.bond || personality.flaw || personality.ideal);
    const hasRelationships = (relationships?.length ?? 0) > 0;

    useEffect(() => {
        if (hasStatBlock && !statBlock) {
            fetchStatBlock(data.vitals.statBlock.id);
        }
    }, [statBlock, hasStatBlock, data.vitals?.statBlock?.id, fetchStatBlock]);
    
    return (
        <Stack spacing={2} padding={1} component={Paper} elevation={2}>
            <ColumnLayout>
                <Stack spacing={1} sx={{width: '100%'}}>
                    {vitals.epithet && <Typography variant="subtitle1">{formatSentence(vitals.epithet)}</Typography>}
                    <div>
                        { vitals.species && <Section label="Species" compact>{capitalizeFirstLetter(vitals.species)}</Section> }
                        { vitals.pronouns && <Section label="Pronouns" compact>{capitalizeFirstLetter(vitals.pronouns)}</Section> }
                        { vitals.age && <Section label="Age" compact>{capitalizeFirstLetter(vitals.age)}</Section> }
                        { vitals.alignment && <Section label="Alignment" compact>{capitalizeFirstLetter(vitals.alignment)}</Section> }
                    </div>
                    { data.appearance.image &&
                        <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                            <Image src={data.appearance.image} alt={data.appearance.altText} sx={{maxWidth: '95%', objectFit: 'cover', display: 'block'}} />
                        </Box>
                    }
                    { appearance.text && 
                        <div>
                            <Typography variant="h3">Appearance</Typography>
                            <MarkdownViewer text={appearance.text} />
                        </div>
                    }
                    { hasPersonalityData && 
                        <div>
                            <Typography variant="h3">Personality</Typography>
                            { personality.ideal &&
                                <Section variant="label2" label="Ideal" compact>{personality.ideal}</Section>
                            }
                            { personality.bond &&
                                <Section variant="label2" label="Bond" compact>{personality.bond}</Section>
                            }
                            { personality.flaw &&
                                <Section variant="label2" label="Flaw" compact>{personality.flaw}</Section>
                            }
                            <MarkdownViewer text={personality.text} />
                        </div>
                    }
                    { biography.text && 
                        <div spacing={1}>
                            <Typography variant="h3">Biography</Typography>
                            <MarkdownViewer text={biography.text} />
                        </div>
                    }
                    { notes.text &&
                        <div spacing={1}>
                            <Typography variant="h3">Notes</Typography>
                            <MarkdownViewer text={notes.text} />
                        </div>                   
                    }
                </Stack>    
            </ColumnLayout>   
            { hasRelationships && 
                <>
                    <Divider />
                    <Typography variant="h3">Relationships</Typography>
                    <ColumnLayout>
                        {relationships.map((r, i) => 
                            <RelationshipViewer key={i} data={r} />
                        )}
                    </ColumnLayout>
                </>
            }            
            { hasStatBlock &&
                <>
                    <Divider />
                    { error && <Alert severity="error">{error}</Alert> }
                    { (!error && !statBlock) && <LinearProgress /> }
                    { statBlock && 
                        <MonsterStatBlock data={statBlock} />
                    }
                </>
            }
        </Stack>
    );
}

export default CharacterViewer;

